/* eslint-disable no-console */
// import { Box } from "@mui/system";
import { useHistory, useParams } from "react-router-dom";

import { timesheetApi } from "@api";
// import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import { appRouteEnums } from "@enums";
import { selectAuth, useAppSelector } from "@redux";
import { converUrlToGetId, formatDate, useFormWithSchema, useShowErrorFromApi } from "@utils";
import queryString from "query-string";
import { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { AppAlert, AppInput, AppModal, AppSelect, CircularLoader, CustomFormProvider, TimeSheetUserInfo } from "../../..";
import { Controller } from "react-hook-form";
import { useTimeSheetContext } from "../../context";
import { StyledNewTimeSheetForm } from "../../styles";
import { TimeSheetHalfDayFormSchemaType, timeSheetHalfDayFormSchema } from "../../validation";
import { FormSheetHalfDay } from "../../widget/form-sheet-half-day";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const optionOthers = [
  {
    name: "その他",
    id: 0,
  },
];

export function TimeSheetHalfDay() {
  const [nameGroup, setNameGroup] = useState<string>("");
  const [idTimeSheetItem, setIdTimeSheetItem] = useState<number | null>(null);
  // const [idSpecialForm, setIdSpecialForm] = useState<number | null>(null);
  const [dateUpdate, setDateUpdate] = useState("");
  const [editLoading, setEditLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [optionAbsenceTypes, setOptionAbsenceTypes] = useState<any>();
  const [idAbsenceItem, setIdAbsenceItem] = useState<any>();
  const history = useHistory();
  const auth = useAppSelector(selectAuth);
  const { userInfo } = auth;
  const defaultDatePicker = queryString.parse(window.location.search)?.date;
  const [value, setValue] = useState<any>();
  const [idItemType, setIdItemType] = useState<any>();
  const [radioItemType, setRadioItemType] = useState<any>();
  const parsed = queryString.parse(location.search);

  const changeValue = Number(value);
  const params: any = useParams();

  useEffect(() => {
    setRadioItemType(idItemType);
  }, [idItemType]);
  const { employees, loading } = useTimeSheetContext();
  const showErrorFromApi = useShowErrorFromApi();

  const fetchListGroupsByUser = async (id: number) => {
    try {
      const result = await timesheetApi.listGroupsByUser(id);
      setNameGroup(result?.data?.data[0].name);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!userInfo?.id) return;
    fetchListGroupsByUser(userInfo?.id);
    fetchListAbsenceTypes();
  }, []);

  const handleRadioItemTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioItemType((event.target as HTMLInputElement).value);
  };
  const fetchListAbsenceTypes = async () => {
    try {
      const result = await timesheetApi.getListAbsenceType();
      setOptionAbsenceTypes([...result.data.data, ...optionOthers]);
    } catch (err) { }
  };

  const idSelectType = converUrlToGetId(window.location.href);

  const formCreate = useFormWithSchema({
    schema: timeSheetHalfDayFormSchema,
    options: {
      defaultValues: {
        alcohol_check: true,
        check_date: defaultDatePicker ? (defaultDatePicker as string) : formatDate({ date: new Date(), format: "YYYY-MM-DD" }),
        device_check: "TANITAアルコールチェッカーEA-100",
        // special_content: "有給",
      },
    },
  });
  const formUpdate = useFormWithSchema({
    schema: timeSheetHalfDayFormSchema,
  });

  const {
    control: formCreateControl,
    reset: resetCreate,
    formState: { isSubmitting },
  } = formCreate;
  const {
    control: formUpdateControl,
    reset,
    formState: { isSubmitting: isSubmittingFormUpdate, errors },
  } = formUpdate;
  console.log(errors);
  // const control = params?.id ? formUpdateControl : formCreateControl;

  // const form: any = params?.id ? formUpdate : formCreate;
  const control = parsed?.copy || params?.id ? formUpdateControl : formCreateControl;

  const form: any = parsed?.copy || params?.id ? formUpdate : formCreate;

  useEffect(() => {
    if (employees.length === 0) return;
    const getLastestInfo = async () => {
      try {
        setEditLoading(true);
        const res = await timesheetApi.getLatestInfo();
        const {
          alcohol_default_check,
          inspector_default_check,
          driver_default_check,
          vehicle_default_check,
          device_default_check,
          start_time_check_alcohol,
          end_time_check_alcohol,
          start_capacity_alcohol,
          end_capacity_alcohol,
          driver_id,
          inspector_id,
          vehicle_id,
          device_check,
        } = res.data.data.timesheetItems[0];
        resetCreate({
          device_check: device_default_check === 0 ? "TANITAアルコールチェッカーEA-100" : device_check,
          driver_id: driver_default_check === 0 ? "" : driver_id ? employees.find((employee) => employee.value === driver_id)?.value : "",
          inspector_id: inspector_default_check === 0 ? "" : inspector_id ? employees.find((employee) => employee.value === inspector_id)?.value : "",
          vehicle_id: vehicle_default_check === 0 ? "" : vehicle_id ? vehicle_id : "",
          list_constructions: [],

          start_time_check_alcohol: alcohol_default_check === 0 ? undefined : start_time_check_alcohol,
          end_time_check_alcohol: alcohol_default_check === 0 ? undefined : end_time_check_alcohol,
          start_capacity_alcohol: alcohol_default_check === 0 ? "" : start_capacity_alcohol,
          end_capacity_alcohol: alcohol_default_check === 0 ? "" : end_capacity_alcohol,
          alcohol_default_check: alcohol_default_check === 0 ? false : true,
          inspector_default_check: inspector_default_check === 0 ? false : true,
          driver_default_check: driver_default_check === 0 ? false : true,
          vehicle_default_check: vehicle_default_check === 0 ? false : true,
          device_default_check: device_default_check === 0 ? false : true,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setEditLoading(false);
      }
    };
    getLastestInfo();
  }, [employees, resetCreate]);

  const handleCreateTimeSheetNightShift = async (values: TimeSheetHalfDayFormSchemaType) => {
    const body = {
      // date: defaultDatePicker as string,
      date: values?.check_date,
      type: idSelectType,
      data: [
        {
          list_constructions: values.list_constructions,
          item_type: radioItemType,
          alcohol_check: values.alcohol_check ? "1" : "0",
          check_date: values.check_date,
          inspector_id: values.inspector_id,
          driver_id: values.driver_id,
          vehicle_id: values.vehicle_id,
          device_check: values.device_check,
          start_time_check_alcohol: values.start_time_check_alcohol,
          end_time_check_alcohol: values.end_time_check_alcohol,
          start_capacity_alcohol: values.start_capacity_alcohol,
          end_capacity_alcohol: values.end_capacity_alcohol,
          alcohol_default_check: values.alcohol_default_check ? "1" : "0",
          inspector_default_check: values.inspector_default_check ? "1" : "0",
          driver_default_check: values.driver_default_check ? "1" : "0",
          vehicle_default_check: values.vehicle_default_check ? "1" : "0",
          device_default_check: values.device_default_check ? "1" : "0",
        },
        // {
        //     item_type: radioItemType.toString(),
        //     check_date: values.check_date_special_form,
        //     special_content: values.special_content,
        //     special_reason: values.special_reason,
        //     special_phone_number: values.special_phone_number,
        //     special_memo: values.special_memo,
        // },
        {
          absence_type_id: values.absence_type_id,
          memo: values.outsite_memo || "",
        },
      ],
    };
    delete body?.data[0]?.absence_type_id;

    try {
      await timesheetApi.addMultipleConstructionSite(body);
      setIsSuccess(true);
      setSuccessMessage("正常に 作成した");
    } catch (error) {
      const err = error as AxiosError<any>;
      showErrorFromApi(err);
    }
  };
  const handleUpdateTimeSheetNightShift = async (values: TimeSheetHalfDayFormSchemaType) => {
    const body = parsed?.copy
      ? {
        type: idSelectType,
        //   date: defaultDatePicker as string,
        date: values?.check_date,
        data: [
          {
            item_type: radioItemType,
            list_constructions: values.list_constructions,
            check_date: defaultDatePicker as string,
            inspector_id: values.inspector_id.toString(),
            driver_id: values.driver_id.toString(),
            vehicle_id: values.vehicle_id.toString(),
            device_check: values.device_check,
            alcohol_check: values.alcohol_check ? "1" : "0",
            start_time_check_alcohol: values.start_time_check_alcohol,
            end_time_check_alcohol: values.end_time_check_alcohol,
            start_capacity_alcohol: values.start_capacity_alcohol,
            end_capacity_alcohol: values.end_capacity_alcohol,
            alcohol_default_check: values.alcohol_default_check ? "1" : "0",
            inspector_default_check: values.inspector_default_check ? "1" : "0",
            driver_default_check: values.driver_default_check ? "1" : "0",
            vehicle_default_check: values.vehicle_default_check ? "1" : "0",
            device_default_check: values.device_default_check ? "1" : "0",
          },
          //   {
          //       item_type: radioItemType,
          //       check_date: values.check_date_special_form,
          //       special_content: values.special_content,
          //       special_reason: values.special_reason,
          //       special_phone_number: values.special_phone_number,
          //       special_memo: values.special_memo,
          //   },
          {
            absence_type_id: changeValue.toString(),
            memo: values.outsite_memo || "",
            // id: idAbsenceItem,
          },
        ],
      }
      : {
        type: idSelectType,
        id: params?.id,
        date: dateUpdate,
        data: [
          {
            item_type: radioItemType,
            id: idTimeSheetItem,
            list_constructions: values.list_constructions,
            check_date: values.check_date,
            inspector_id: values.inspector_id,
            driver_id: values.driver_id,
            vehicle_id: values.vehicle_id,
            device_check: values.device_check,
            alcohol_check: values.alcohol_check ? "1" : "0",
            start_time_check_alcohol: values.start_time_check_alcohol,
            end_time_check_alcohol: values.end_time_check_alcohol,
            start_capacity_alcohol: values.start_capacity_alcohol,
            end_capacity_alcohol: values.end_capacity_alcohol,
            alcohol_default_check: values.alcohol_default_check ? "1" : "0",
            inspector_default_check: values.inspector_default_check ? "1" : "0",
            driver_default_check: values.driver_default_check ? "1" : "0",
            vehicle_default_check: values.vehicle_default_check ? "1" : "0",
            device_default_check: values.device_default_check ? "1" : "0",
          },
          //   {
          //       id: idSpecialForm,
          //       item_type: radioItemType,
          //       check_date: values.check_date_special_form,
          //       special_content: values.special_content,
          //       special_reason: values.special_reason,
          //       special_phone_number: values.special_phone_number,
          //       special_memo: values.special_memo,
          //   },
          {
            absence_type_id: changeValue,
            memo: values.outsite_memo || "",
            id: idAbsenceItem,
          },
        ],
      };
    try {
      // await timesheetApi.updateTimesheetDetail(body, params?.id);
      parsed?.copy ? await timesheetApi.addMultipleConstructionSite(body) : await timesheetApi.updateTimesheetDetail(body, params?.id);
      setIsSuccess(true);
      setSuccessMessage("正常に更新");
    } catch (error: any) {
      const err = error as AxiosError<any>;
      showErrorFromApi(err);
    }
  };

  // const onSubmit = params?.id ? handleUpdateTimeSheetNightShift : handleCreateTimeSheetNightShift;
  const onSubmit = parsed?.copy || params?.id ? handleUpdateTimeSheetNightShift : handleCreateTimeSheetNightShift;

  useEffect(() => {
    // if (!params.id || employees.length === 0) return;
    if (employees.length === 0) return;

    const nightShiftDetails = async () => {
      setEditLoading(true);
      try {
        // const res = await timesheetApi.viewTimesheetDetail(params.id);
        // const res = await timesheetApi.viewTimesheetDetail(parsed?.copy ? parsed?.copy : params.id);
        const res = (parsed?.copy || params?.id) && (await timesheetApi.viewTimesheetDetail(parsed?.copy ? parsed?.copy : params.id));

        setIdItemType(res.data.items[0].item_type);

        const {
          alcohol_check,
          check_date,
          device_check,
          driver,
          inspector,
          vehicle,
          TimesheetItemConstruction,
          id,
          date,
          start_time_check_alcohol,
          end_time_check_alcohol,
          start_capacity_alcohol,
          end_capacity_alcohol,
          alcohol_default_check,
          inspector_default_check,
          driver_default_check,
          vehicle_default_check,
          device_default_check,
        } = res.data.items[0];
        // const { special_content, special_reason, special_phone_number, special_memo } =
        //     res.data.items[1];
        // const check_date_special_form = res.data.items[1]?.check_date;
        // const idSpecForm = res.data.items[1]?.id;
        const absence_type_id = res.data.items[1].absence_type === null ? "0" : res.data.items[1].absence_type?.id;
        const idAbsence = res.data.items[1].id;
        const halfDayValuesForm: TimeSheetHalfDayFormSchemaType = {
          alcohol_check: alcohol_check === 0 ? false : true,
          check_date: parsed?.copy ? parsed?.date : check_date,
          // check_date,
          device_check,
          driver_id: driver ? employees.find((employee) => employee.value === driver?.id)?.value : "",
          inspector_id: inspector ? employees.find((employee) => employee.value === inspector?.id)?.value : "",
          vehicle_id: vehicle?.id ? vehicle?.id : "",
          start_time_check_alcohol: start_time_check_alcohol,
          end_time_check_alcohol: end_time_check_alcohol,
          start_capacity_alcohol: start_capacity_alcohol.toString(),
          end_capacity_alcohol: end_capacity_alcohol.toString(),
          list_constructions: TimesheetItemConstruction.map((construction) => ({
            break_time: construction.break_time,
            memo: construction.memo || "",
            construction_id: construction.construction_id,
            listConstruction: construction.construction,
            start_at: construction.start_at,
            stop_at: construction.stop_at,
            weather_id: construction.weather_id.toString(),
            contractors: construction.contractor_timesheet_item.map((contractorItem) => ({
              contractor_id: contractorItem.contractor_id,
              number_of_people: Number(contractorItem.number_of_people),
              work_type_1: contractorItem.work_types.filter((workType) => workType.type === 1).map((workType) => String(workType.id)),
              work_type_2: contractorItem.work_types.filter((workType) => workType.type === 2).map((workType) => String(workType.id)),
              work_type_3: contractorItem.work_types.filter((workType) => workType.type === 3).map((workType) => String(workType.id)),
            })),
            livelet_employee: construction?.employee_timesheet_item?.map((liveletEmployee) => ({
              employee_id: liveletEmployee.user_id,
              employee_name: liveletEmployee.user_id === 0 ? liveletEmployee.user_name : `${liveletEmployee.user.last_name} ${liveletEmployee.user.first_name}`,
              employee_memo: liveletEmployee.memo,
            })),
          })),
          absence_type_id: absence_type_id,
          outsite_memo: res.data.items[1].absence_type?.name ? res.data.items[1].absence_type?.name : res.data.items[1]?.memo,
          check_day_or_night: res.data.items[0]?.item_type.toString(),
          // item_type: 1,
          // check_date_special_form: check_date_special_form,
          // special_content: special_content,
          // special_reason: special_reason,
          // special_phone_number: special_phone_number,
          // special_memo: special_memo,
          alcohol_default_check: alcohol_default_check === 0 ? false : true,
          inspector_default_check: inspector_default_check === 0 ? false : true,
          driver_default_check: driver_default_check === 0 ? false : true,
          vehicle_default_check: vehicle_default_check === 0 ? false : true,
          device_default_check: device_default_check === 0 ? false : true,
        };
        console.log(halfDayValuesForm);
        setIdAbsenceItem(idAbsence);
        // setIdSpecialForm(idSpecForm);
        setDateUpdate(date);
        setIdTimeSheetItem(id);
        reset(halfDayValuesForm);
      } catch (error) {
        console.log(error);
      } finally {
        setEditLoading(false);
      }
    };
    void nightShiftDetails();
    // }, [employees, reset, params?.id]);
  }, [employees, reset, params?.id, parsed?.copy]);

  const handleCloseModal = () => {
    setIsSuccess(false);
    history.push(appRouteEnums.PATH_TIMESHEET);
  };

  return (
    <StyledNewTimeSheetForm>
      {/* <AppTopBar /> */}
      <CircularLoader loading={loading || editLoading || isSubmitting || isSubmittingFormUpdate} />
      <AppModal open={isSuccess} handleClose={handleCloseModal}>
        <AppAlert icon={<img src={hot_yellow_frame} alt="icon" />} text={successMessage} handleClose={handleCloseModal} />
      </AppModal>
      <div className="detail">
        <div className="detail__top">
          {/* <p className="detail__top-icon" onClick={() => history.goBack()}>
            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
          </p> */}
          <p className="detail__top-title">日報</p>
        </div>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
        <div className="detail__content">
          <div className="detail__contentTop">
            <TimeSheetUserInfo last_name={userInfo?.last_name} first_name={userInfo?.first_name} type={idSelectType} />
            <p className="detail__name">{userInfo?.last_name} {userInfo?.first_name}</p>
          </div>

          {/* <div className="boxWrapper"> */}
          {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              > */}
          {/* <p className="label">グループ</p>

            <p className="main">{nameGroup && nameGroup}</p> */}
          {/* </Box> */}
          {/* </div> */}
          <div className="boxWrapper">
            <div className="detail__group">
              <p className="label">グループ</p>

              <p className="main">{nameGroup && nameGroup}</p>
            </div>

            <CustomFormProvider form={form} onSubmit={onSubmit}>

              <div className="radio" style={{ marginLeft: "8px" }}>
                <Controller
                  name="check_day_or_night"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={String(value)}
                          onChange={(e) => {
                            handleRadioItemTypeChange(e);
                            onChange(e.target.value);
                          }}
                        >
                          <FormControlLabel value={0} control={<Radio />} label="午前" />
                          <FormControlLabel value={1} control={<Radio />} label="午後" />
                        </RadioGroup>
                        <p style={{ color: "rgb(255, 89, 123)" }}>{error?.message}</p>
                      </>
                    );
                  }}
                />
              </div>
              <div className="detail__contentWithBorder">
                <div className="detail__contentChangeSize">
                  <label className="label">理由</label>
                  {/* <div className="input select"> */}
                  {/* <div className="input select"> */}
                  {optionAbsenceTypes?.length && (
                    <Controller
                      name="absence_type_id"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        setValue(value);
                        return (
                          <AppSelect
                            error={error?.message}
                            options={
                              optionAbsenceTypes?.length &&
                              optionAbsenceTypes?.map((item) => {
                                return {
                                  label: item.name,
                                  value: item.id.toString(),
                                };
                              })
                            }
                            value={String(value)}
                            handleChange={(value, action) => {
                              onChange(String(value));
                              if (action === "clear") {
                                onChange("");
                              }
                            }}
                            isClearable
                            placeholder="ドライバーを選択"
                          />
                        );
                      }}
                    />
                  )}
                  {/* </div> */}

                  {changeValue === 0 ? (
                    <div className="input select" style={{ marginTop: "10px !important" }}>
                      <Controller name="outsite_memo" control={control} render={({ field: { onChange, value }, fieldState: { error } }) => <AppInput value={value} error={error} handleChange={onChange} />} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
              <FormSheetHalfDay />
            </CustomFormProvider>
          </div>
        </div>
        {/* </Box> */}
      </div >
    </StyledNewTimeSheetForm >
  );
}
