import styled from "styled-components";

import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledTimeSheetDetail = styled.div`
    .userInfo {
    display:flex;
    gap:1rem;
    align-items:center;
    }
    .sub {
    display:flex;
    gap:1rem;
    align-items:center;
    }
    .startTime {
    display:flex;
    gap:1rem;

    }
    .detail {
        margin-top:70px;
        min-height: 100vh;
        // background-image: url(${small_bg_dot});
        // background-repeat: no-repeat;
        background-color: ${(p) => p.theme.colors.white02};
        text-align: left;
        padding: 1rem 1rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 2rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 2rem;
        }

        &__top {
            display: flex;
            align-items:center;
            flex-direction: column;

            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                flex-direction: row;
            }
            &-title {
                font-size: 3.2rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                color:${(p) => p.theme.colors.black01};
            }
            &-status {
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding-top: 1rem;
                    padding-left: 2rem;
                }
            }
            &-checked {
                padding: 0.4rem 1rem;
                background-color:${(p) => p.theme.colors.green07};
                display: flex;
                align-items:center;
                width: 6rem;
                border-radius:50px;
                .text {
                    padding-right: 0.5rem;
                    color: ${(p) => p.theme.colors.white00};
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-unChecked {
                padding: 0.4rem 1rem;
                background-color: ${(p) => p.theme.colors.red05};
                display: flex;
                align-items:center;
                border-radius:50px;
                width: 6rem;
                .text {
                    padding-right: 0.5rem;
                    color: ${(p) => p.theme.colors.white00};
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-pending {
                padding: 0.4rem 1rem;
                background-color: #e9e9e9;
                width: 6rem;
                border-radius:50px;
            }
        }

        &__content {
            width: 100%;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                // width: 60rem;
            }

            .boxWrapper {
                color:${(p) => p.theme.colors.black01};
                padding: 2rem 1.5rem;
                border-radius: 6px;
                background-color: ${(p) => p.theme.colors.white00};
                // margin: 1.5rem 0;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            }
            .label {
                display: block;
                padding: 1rem 0;
                color: ${(p) => p.theme.colors.black01};
            }
            .date {
                display: grid;
                grid-column-gap: 1rem;
                grid-template-columns: auto auto;
            }
            .main {
                font-size: 1.6rem;
                color: ${(p) => p.theme.colors.black01};
            }
            .rowContent-top {
                padding:1rem 0 1rem 0;

            }
            .rowContent {
                display:flex;
                justify-content:space-between;
                align-items:center;
                // margin: 1.5rem 0;
                padding:1rem 0 1rem 0;
                border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};
            }
            .rowContent-last {
                display:flex;
                justify-content:space-between;
                align-items:center;
                // margin: 1.5rem 0;
            }
            .row{
                display:flex;
                justify-content:space-between;
                .main{
                    font-size:1.8rem;
                }
            }
            .time {
                display: grid;
                grid-column-gap: 1rem;
                grid-template-columns: auto auto;
                .startTime,
                .endTime {
                    display: flex;
                }
                &__icon {
                    display: flex;
                    padding-right: 1rem;
                }
            }
            .MuiFormGroup-root {
                display: flex;
                flex-direction: row;
                .MuiTypography-root {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .Mui-checked {
                    color: #43cdec;
                }
                .MuiSvgIcon-root {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }
`;
