import { updateSchedule } from '@redux';
import { theme } from '@utils';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type WeeklyScheduleRowProps = {
  user: any; // Adjust this type based on your user data structure.
  datesInMonth: any[]; // Adjust this type as necessary.
  onEditClick: (userId: number, dayNumber: number, newValue: boolean) => void;
  onChangeScheduleInput: (userId: number, dayNumber: number, contentOne: string | null, contentTwo: string | null) => void;
  onUndoClick: (userId: number, dayNumber: number, originalContentOne: string, originalContentTwo: string) => void;
  onSaveClick: (userId: number, dayNumber: number, contentOne: string, contentTwo: string) => Promise<void>;
  onDrop: (draggedUserId: number, targetUserId: number) => void;
  editing: any;
  scheduleData: any;
  month: string;
  holidays: any[];
};

type dayScheduleType = {
  contentOne: string;
  contentTwo: string;
};

const SaveIcon = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M17,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V7L17,3z M12,19c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4 S14.2,19,12,19z M15,9H5V5h10V9z" />
  </svg>
);

const DraggableIcon = () => (
  <svg width="18" height="27" viewBox="0 0 24 36" fill="#E4E4E4" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="2" fill="#E4E4E4" />
    <circle cx="18" cy="6" r="2" fill="#E4E4E4" />
    <circle cx="6" cy="18" r="2" fill="#E4E4E4" />
    <circle cx="18" cy="18" r="2" fill="#E4E4E4" />
    <circle cx="6" cy="30" r="2" fill="#E4E4E4" />
    <circle cx="18" cy="30" r="2" fill="#E4E4E4" />
  </svg>
);

const WeeklyScheduleRow = ({
  user,
  datesInMonth,
  onEditClick,
  onChangeScheduleInput,
  onUndoClick,
  onSaveClick,
  onDrop,
  editing,
  scheduleData,
  month,
  holidays,
}: WeeklyScheduleRowProps) => {
  const dispatch = useDispatch();
  const initialContent: dayScheduleType[] = [];
  datesInMonth.map((date) => {
    const dayOriginalContent = scheduleData?.[date.dayNumber];
    const originalContentOne = dayOriginalContent?.contentOne || '';
    const originalContentTwo = dayOriginalContent?.contentTwo || '';
    initialContent[date.dayNumber] = { contentOne: originalContentOne, contentTwo: originalContentTwo };
  });

  const [updating, setUpdating] = useState<number[]>([]);
  const [undoContent, setUndoContent] = useState<dayScheduleType[]>(initialContent);

  const handleDragStart = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.dataTransfer.setData('userId', String(user.id));
    }, []
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    }, []
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const draggedUserId = Number(event.dataTransfer.getData('userId'));
      onDrop(draggedUserId, user.id);
    }, [onDrop]
  );

  const handleEditClick = useCallback((dayNumber: number) => {
    onEditClick(user.id, dayNumber, true);
  }, [onEditClick, user.id]);

  const handleChangeScheduleInput = useCallback(
    (dayNumber: number, contentOne: string | null, contentTwo: string | null) => {
      onChangeScheduleInput(user.id, dayNumber, contentOne, contentTwo);
    },
    [onChangeScheduleInput, user.id]
  );

  const handleUndoClick = useCallback(
    (dayNumber: number, originalContentOne: string, originalContentTwo: string) => {
      onUndoClick(user.id, dayNumber, originalContentOne, originalContentTwo);
      onEditClick(user.id, dayNumber, false);
    },
    [onUndoClick, onEditClick, user.id]
  );

  const handleSaveClick = useCallback(
    async (dayNumber: number, contentOne: string, contentTwo: string) => {
      onEditClick(user.id, dayNumber, false);
      setUpdating((prev) => [...prev, dayNumber]);
      await onSaveClick(user.id, dayNumber, contentOne, contentTwo);
      dispatch(updateSchedule({ userId: user.id, date: `${month}-${String(dayNumber).padStart(2, '0')}`, contentOne: contentOne, contentTwo: contentTwo }));
      setUpdating((prev) => prev.filter((item) => item !== dayNumber));
      setUndoContent((prev) => ({ ...prev, [dayNumber]: { contentOne, contentTwo } }));
    },
    [onSaveClick, onEditClick]
  );

  return (
    <StyledRowContainer>
      <StyledRow onDragOver={handleDragOver} onDrop={handleDrop}>
        <StyledStickyUserElement draggable onDragStart={handleDragStart}>
          <div style={{ display: "grid", gridTemplateColumns: "36px 1fr 2rem", alignItems: "center", width: "100%", cursor: "grab" }}>
            <DraggableIcon />
            <span>{user.name}</span>
            <div style={{ width: "2rem", display: "flex", flexDirection: "column" }}>
              <span style={{ height: "4rem" }}>AM</span>
              <span style={{ height: "4rem" }}>PM</span>
            </div>
          </div>
        </StyledStickyUserElement>

        {datesInMonth.map((date) => {
          const currentDate = `${month}-${String(date.dayNumber).padStart(2, '0')}`;
          const holiday = holidays.find(h => h.date === currentDate);
          const dayColor =
            date.dayInWeek === 'sat'
              ? '#e6e6ff'
              : date.dayInWeek === 'sun'
                ? '#ffe6e6'
                : holiday
                  ? '#ffd8d8'
                  : theme.colors.white00;

          const isEditing = editing?.[user.id]?.[date.dayNumber] ?? false;

          const liveSchedule = scheduleData?.[date.dayNumber] || {};
          const liveContentOne = liveSchedule?.contentOne || '';
          const liveContentTwo = liveSchedule?.contentTwo || '';

          const uploading = updating.includes(date.dayNumber);
          const style = uploading ? { background: '#fff' } : { background: dayColor };

          return (
            <StyledUserElement
              key={date.dayNumber}
              style={style}
              onClick={() => handleEditClick(date.dayNumber)}
            >
              <StyledTextArea
                style={{ background: isEditing ? theme.colors.lightgray09 : 'transparent' }}
                value={liveContentOne}
                onChange={(event) =>
                  handleChangeScheduleInput(date.dayNumber, event.target.value, null)
                }
                disabled={uploading}
              />
              <StyledTextArea
                style={{ background: isEditing ? theme.colors.lightgray09 : 'transparent' }}
                value={liveContentTwo}
                onChange={(event) =>
                  handleChangeScheduleInput(date.dayNumber, null, event.target.value)
                }
                disabled={uploading}
              />
              <StyledIconContainer>
                {isEditing && (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        if (uploading) return;
                        handleSaveClick(
                          date.dayNumber,
                          liveContentOne,
                          liveContentTwo
                        );
                      }}
                    >
                      <SaveIcon />
                    </div>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                        if (uploading) return;
                        handleUndoClick(
                          date.dayNumber,
                          undoContent[date.dayNumber].contentOne,
                          undoContent[date.dayNumber].contentTwo,
                        );
                      }}
                    >
                    </div>
                  </div>
                )}
              </StyledIconContainer>
            </StyledUserElement>
          );
        })}
      </StyledRow>
    </StyledRowContainer>
  );
};

export default React.memo(WeeklyScheduleRow);

const StyledRowContainer = styled.div`
    max-width: 100%;
`;

const StyledRow = styled.div`
    display: inline-flex;
`;

const StyledUserElement = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 10rem;
    background-color: ${theme.colors.white00};
    margin: 0.1rem;
`;

const StyledStickyUserElement = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 10rem;
    background-color: ${theme.colors.white00};
    border-left: 2px solid ${theme.colors.lightgray09};
    box-sizing: border-box;
    border-radius: 0.5rem;
    margin: 0.1rem;
    position: sticky;
    left: 0;
    z-index: 5;
    // box-shadow: rgba(33,35,38,0.25) 5px 0px 4px -2px;
`;

const StyledTextArea = styled.textarea`
    border: rgba(128,128,128,0.2) 1px solid;
    background-color: transparent;
    padding: 0.5rem;
    border-radius: 0.5rem;
`;

const StyledIconContainer = styled.div`
    width: 100%;
    height: 2rem;
    text-align: end;
    padding: 0 1rem;
`;
