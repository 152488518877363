import styled from "styled-components";
import small_inventory_bg from "@assets/Background/small_inventory_bg.png";
import inventory_bg from "@assets/Background/inventory_bg.png";
import IframeResizer from "iframe-resizer-react";
interface IStyleCategory {
    activeCollapse?: boolean;
}

export const StyledDocument = styled.div`
    // background-image: url(${small_inventory_bg});
    // background-repeat: no-repeat;
    background-color: ${(p) => p.theme.colors.white02};
    min-height: 100vh;
    // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
    //     background-image: url(${inventory_bg});
    // }
    .wrapperDocument {
        display: flex;
        justify-content: center;
    }
    .document {
        padding: 1rem 1rem;
        min-height: 80vh;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 2rem;
            // width: 608px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 2rem;
            // width: 1224px;
            // max-width: 1224px;
        }
            margin-top:7rem;
            padding-bottom:12rem;
        &__top {
            text-align: left;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-bottom: 2rem;
            }
            &-searchForm {
                height: 100%;
                display: flex;
                align-items: end;
                .MuiIconButton-root {
                    padding: 0rem 1rem;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                        padding: 0rem 2rem;
                    }
                }
            }
        }
        &__top-title {
         @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-bottom: 2rem;
            }

        }
        &__content {
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                border-top: 1px solid ${(p) => p.theme.colors.lightgray09};
            }
            .category {
                padding: 2rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    min-height: 77vh;
                    // border-right: 1px solid #d4d4d4;
                    padding-left: 0;
                }
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    // padding-left: 2rem;
                }
            }
        }
    }
`;
export const StyledDocumentCategory = styled.div<IStyleCategory>`
    .categoryItem {
        padding: 1.6rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        .collapse {
            &__header {
                display: flex;
                justify-content: space-between;
                text-align: left;
                cursor: pointer;
                &-title {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                    font-size: 1.6rem;
                    color: ${(p) => (p.activeCollapse ? p.theme.colors.orange : p.theme.colors.black01)};
                    position: relative;
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    line-height: 1.2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-color: transparent;
                    }
                }
            }
            &__content {
                padding-top: 1.2rem;
            }
            &__subText {
                text-align: left;
                font-size: 1.6rem;
                padding: 0.5rem 1.6rem;
                display: block;
                display: -webkit-box;
                max-width: 100%;
                height: 40px;
                margin-bottom: 1rem;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                cursor: pointer;
                &:hover {
                    color: ${(p) => p.theme.colors.orange};
                }
            }
        }
    }
`;
export const StyledListItemDocument = styled.div`
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding-left: 2.5rem;
    }
    .wrapperLoading {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .documentList {
        &__title {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            font-size: 1.6rem;
            padding: 2.5rem 0;
            text-align: left;
        }
        .error {
            min-height: 77vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;
export const StyledDocumentItem = styled.div`
    position: relative;
    z-index: 1;
    height: 9rem;
    padding: 1.6rem;
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 6px;
    text-align: left;
    transition: 250ms;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content:space-between;

    &:hover {
        background-color: ${(p) => p.theme.colors.blue06};
    }
    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        align-items:center;
    }
    .wrapper {
        // height: 9rem;
        // padding: 1.6rem;
        // background-color: ${(p) => p.theme.colors.white};
        // border-radius: 1.6rem;
        // text-align: left;
        // transition: 250ms;
        // cursor: pointer;

        // &:hover {
        //     background-color: ${(p) => p.theme.colors.blue06};
        // }
    }
    .title {
        display: flex;
        justify-content: space-between;
        &__left {
            display: flex;
            flex-direction: column;
        }
        &__date {
            font-size: 1.6rem;
            color: ${(p) => p.theme.colors.black01};
        }
        &__main {
            font-size: 1.6rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            padding-top: 1rem;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .content {
        &__text {
            // padding-top: 1.8rem;
            font-size: 1.2rem;
            color: ${(p) => p.theme.colors.black01};
            display: block;
            display: -webkit-box;
            max-width: 100%;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    }
    .download__file {
        // padding:1rem;
        // position: absolute;
        // right: 1rem;
        // top: 1rem;
        z-index: 99;
    }
`;
export const StyledPDF = styled.div`
    height: 90%;
    transform: translate(0px, 50px);
    .failePDF {
        font-size: 3.6rem;
        color: white;
    }
    .react-pdf__Document {
        width: 40vh;
        height: 80vh;
        overflow: auto;
        background-color: #FCF8E8;
    }
    .react-pdf__message {
        font-size: 2rem;
        color: black;
        text-align: center;
        height: 100%;
    }
    .react-pdf__Page {
        display: flex;
        justify-content: center;
        padding-left: 25rem;
        .react-pdf__message--no-data {
            display: none;
        }
    }
    .thumb-container {
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        background: #404041;
        padding: 10px 0px;
        height: 100vh;
        width: 200px;
        overflow-y: scroll;
    }
`;
export const StyledIframe = styled(IframeResizer)`
    width: 100%;
    height: 90vh;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        width: 60vh;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        width: 150vh;
    }
`;

export const DocumentBorder = styled.span`
  display: block;
  margin-left:2rem;
  border-bottom: 1px solid ${(p) => p.theme.colors.lightgray09};
  width: 100%;
  @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
  border-bottom: none;
  }
`;
