import { Box, Typography } from "@mui/material";
import React from "react";
import { AppModal } from "../Common";

interface IDeleteTypeConfirmationModalProps {
    open: boolean;
    handleClose: () => void;
    message?: React.ReactElement | string;
    action?: React.ReactElement;
}

export default function DeleteTypeConfirmationModal({ open, handleClose, message, action }: IDeleteTypeConfirmationModalProps) {
    return (
        <AppModal open={open} handleClose={handleClose}>
            <Box
                sx={{
                    width: "90vw",
                    maxWidth: 500,
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    p: 4,
                }}
            >
                <Typography sx={{ fontSize: "18px !important" }}>{message || "細別が設定されている場合はそれらもすべて削除されます。よろしいですか？"}</Typography>

                {action ? action : null}
            </Box>
        </AppModal>
    );
}
