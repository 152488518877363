import red_dot from "@assets/Icons/Notification_Icons/red_dot.svg";
import unread_status from "@assets/Icons/Notification_Icons/unread-status.svg";
// import trash_icon from "@assets/Images/trash_icon.png";

import { NotificationTypeEnums, ApproveTypeEnums } from "@enums";
import TrashcanIcon from "@assets/trashcanIcon.png";
import { StyledNotificationItem } from "./styles";

interface IProps {
  data?: any;
  isSelected: boolean;
  handleOnSelectNoti: (values: any) => void;
  handleDeleteNoti: () => void;
  handleToggleSelect: () => void;
}
export const NotificationItem = (props: IProps) => {
  const { data, handleOnSelectNoti, handleDeleteNoti, handleToggleSelect, isSelected } = props;
  return (
    <StyledNotificationItem>
      <div 
        className="noti__item"
        onClick={() =>
          handleOnSelectNoti({
            type: data?.type,
            id: data?.id,
            status: data?.status,
            otID: data?.type?.otID,
            tsID: data?.type?.tsID,
            timesheetType: data?.type?.timesheet_type,
          })
        }
      >
        <div className="noti__item-content">
          <div style={{ display: "flex" }}>
            <div className="noti__item-checkbox">
              <input
                type="checkbox"
                checked={isSelected}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleSelect();
                }}
              />
            </div>

            {(data?.type?.type === NotificationTypeEnums.TYPE_REQUEST_OT || 
              data?.type?.type === NotificationTypeEnums.TYPE_REQUEST_TIMESHEET) && 
              data?.type?.status === ApproveTypeEnums.PENDING && (
              <div className="noti__item-approval"><span>未</span></div>
            )}

            <div>
              <p className="noti__item-title">
                {data.title}
                <span style={{ marginRight: "7px" }}></span>
                {data?.type?.typeOfOvertime?.toString() === "0" && "(予定届)"}
                {data?.type?.typeOfOvertime?.toString() === "1" && "(本届)"}
              </p>
              <p className="noti__item-description">{data.content}</p>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="noti__item-status">
              {!data.status ? (
                <span>
                  <img src={red_dot} alt="icon" />
                </span>
              ) : (
                ""
              )}
            </div>

            <img
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteNoti();
              }}
              src={TrashcanIcon}
              className="noti__item-deleteIcon"
            />
          </div>
        </div>
      </div>
    </StyledNotificationItem>
  );
};
