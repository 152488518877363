import React from "react";
import moment from "moment";

// import { DownLoadIcon } from "@assets";
import { StyledDocumentItem } from ".";
import { CircularLoader } from "..";
import axios from "axios";
import { linkDocument } from "@utils";
import { technicalDocumentType } from "src/types";
import DownloadIcon from "@assets/downloadIcon.png";

interface IProps {
  technicalDocument: technicalDocumentType;
  handleOpenPDF: (value: any) => void;
  handleDownloadPDF: (url: any) => void;
  handleClosePDF: () => void;
}
export const DocumentItemComponent = (props: IProps) => {
  // props
  const { technicalDocument, handleOpenPDF, handleClosePDF } = props;
  // component state
  const [downloadProcess, setDownloadProcess] = React.useState<boolean>(false);
  // download file
  const handleDownloadPdf = (url: any, fileName: string) => {
    const urlSplit = url.split(".");
    const typeFile = urlSplit[urlSplit.length - 1];
    handleClosePDF();
    setDownloadProcess(true);
    axios({
      url: linkDocument(url),
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        setDownloadProcess(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.${typeFile}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setDownloadProcess(false);
      });
  };
  return (
    <StyledDocumentItem>
      <div className="wrapper" onClick={() => handleOpenPDF(technicalDocument.file?.path)}>
        <div className="title">
          <div className="title__left">
            <span className="title__date">
              {moment(technicalDocument.created_at).format("YYYY-MM-DD")}
            </span>
            <span className="title__main">{technicalDocument?.title}</span>
          </div>
        </div>

        <div className="content">
          <p className="content__text">{technicalDocument.des}</p>
        </div>
      </div>
      <div className="download__file">
        {downloadProcess ? (
          <CircularLoader loading={downloadProcess} type="fullContent" size={15} />
        ) : (
          <button
            onClick={() => handleDownloadPdf(technicalDocument.file.path, technicalDocument?.title)}
            disabled={downloadProcess}
            style={{ all: "unset" }}
          >
            <img src={DownloadIcon} alt="download-icon" className="downloadIcon" />
          </button>
        )}
      </div>
    </StyledDocumentItem>
  );
};
