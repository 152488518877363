import { appRouteEnums } from "@enums";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import clsx from "clsx";
import { Formik } from "formik";

import { AppSelect, CircularLoader, WideButton } from "..";
import { StyledNewTimeSheet } from "./styles";
import { getDateParamOnUrl, selectTimeSheetTypeSchema, timesheetType } from "@utils";
import { timesheetApi } from "@api";
import { LABEL_TIME_SHEET_TYPE } from "@configs";

export const NewTimeSheet = () => {
  // component state
  const [radioValue, setRadioValue] = React.useState<any>(0);
  const [lastestTimesheets, setLastestTimesheets] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [date, setDate] = React.useState(new Date());
  // hooks
  const history = useHistory();
  const fetchLastestTimesheet = async () => {
    setLoading(true);
    try {
      const response = await timesheetApi.listLastestTimesheet();
      setLoading(false);
      setLastestTimesheets(response.data.data);
    } catch (err: any) {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    fetchLastestTimesheet();
  }, []);
  // events
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };
  const handleBtnCoppy = () => {
    history.push(
      `${appRouteEnums.PATH_TIMESHEET}/new/${radioValue.split("/")[0]}?copy=${radioValue.split("/")[1]
      }&date=${getDateParamOnUrl(window.location.href)}`
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value);
    setDate(newDate);
  };

  const noTimesheets = (
    <div>
      <p style={{ color: "red" }}>該当の日付のデータがありません。</p>
    </div>
  );

  const checkTimesheet = lastestTimesheets?.length > 0
    ? lastestTimesheets.filter((ts) => {
      return ts.date === date.toISOString().split("T")[0];
    }) : null;

  const matchTimesheet = Array.isArray(checkTimesheet) && checkTimesheet.length > 0 ? checkTimesheet.map((item) => {
    return (
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={radioValue}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          key={item.id}
          value={`${item.type}/${item.id}`}
          className={clsx(
            radioValue === `${item.type}/${item.id}`
              ? "active"
              : "",
            "radioForm"
          )}
          control={<Radio />}
          label={
            <Box sx={{ paddingLeft: "1rem" }}>
              <p className="date">{item.date}</p>
              <p className="text">

                <span>{item?.title}</span>
              </p>
            </Box>
          }
        />
      </RadioGroup>
    );

  }) : noTimesheets;



  return (
    <StyledNewTimeSheet>
      {/* <AppTopBar /> */}
      <div className="detail">
        <div className="detail__top">
          {/* <p
            className="detail__top-icon"
            onClick={() => history.push(appRouteEnums.PATH_TIMESHEET)}
          >
            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
          </p> */}
          <p className="detail__top-title">日報</p>
        </div>
        <div className="content">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Formik
                initialValues={{ selectValue: "" }}
                onSubmit={(values) => {
                  history.push(
                    `${appRouteEnums.PATH_TIMESHEET_NEW}/${values.selectValue}?date=${getDateParamOnUrl(window.location.href)}`
                  );
                }}
                validationSchema={selectTimeSheetTypeSchema}
              >
                {({ handleSubmit, values, handleChange }) => {
                  return (
                    <>
                      <div className="select">
                        <p className="subTitle">新しく作る</p>
                        <label className="label">{LABEL_TIME_SHEET_TYPE}</label>
                        <AppSelect
                          options={timesheetType}
                          placeholder="タイムシートタイプを選択"
                          value={values.selectValue}
                          handleChange={handleChange("selectValue")}
                          name="selectValue"
                        />
                      </div>
                      <div className="btn">
                        <WideButton onClick={() => handleSubmit()}>次へ</WideButton>
                        {/* <AppButton onClick={handleSubmit} text="次へ" /> */}
                      </div>
                    </>
                  );
                }}
              </Formik>
            </Grid>
            {/* <Grid item xs={12} md={1}>
              <div className="divider">
                <span className="divider__text">ま た</span>
              </div>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <div className="radio">
                <p className="subTitle">日付変更してコピーする</p>
                <label className="label">
                  コピーする対象の日付を選択してください
                </label>
                <input
                  type="date"
                  value={date.toISOString().split("T")[0]}
                  onChange={handleChange}
                  className="inputMonth"
                />
                {loading ? (
                  <CircularLoader loading={loading} type="fullContent" />
                ) : (
                  matchTimesheet
                  // <RadioGroup
                  //   aria-labelledby="demo-controlled-radio-buttons-group"
                  //   name="controlled-radio-buttons-group"
                  //   value={radioValue}
                  //   onChange={handleRadioChange}
                  // >
                  //   {lastestTimesheets?.length
                  //     ? lastestTimesheets.map((item) => (
                  //       <FormControlLabel
                  //         key={item.id}
                  //         value={`${item.type}/${item.id}`}
                  //         className={clsx(
                  //           radioValue === `${item.type}/${item.id}`
                  //             ? "active"
                  //             : "",
                  //           "radioForm"
                  //         )}
                  //         control={<Radio />}
                  //         label={
                  //           <Box sx={{ paddingLeft: "1rem" }}>
                  //             <p className="date">{item.date}</p>
                  //             <p className="text">

                  //               <span>{item?.title}</span>
                  //             </p>
                  //           </Box>
                  //         }
                  //       />
                  //     ))
                  //     : ""}
                  // </RadioGroup>
                )}

                <div className="btn">
                  {!radioValue ? (
                    <WideButton disabled={true}>Copy</WideButton>

                  ) : (
                    <WideButton onClick={() => handleBtnCoppy()} disabled={false}>Copy</WideButton>

                  )
                  }
                  {/* <AppButton
                    text="Copy"
                    icon={<CoppyIcon />}
                    disabled={!radioValue ? true : false}
                    onClick={handleBtnCoppy}
                  /> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </StyledNewTimeSheet>
  );
};
