import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// import queryString from "query-string";

import {
  InstrumentDetailInfoComponent,
  InstrumentDetialHistoryComponent,
  StyledInstrumetDetailView,
} from ".";
import { CircularLoader, AppModal, AppAlert } from "..";
// import arrow_left from "@assets/Icons/arrow_left.svg";
import { appRouteEnums } from "@enums";
import { instrumentApi } from "@api";
import {
  useAppSelector,
  selectInstrument,
  setSelectedDateInstrument,
  updateSnackBar,
  setInstrumentsCategory,
  setIdConstuctionEmployeeSearch,
} from "@redux";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import { Grid } from "@mui/material";
import moment from "moment";
const queryString = require("query-string");

export const InstrumentDetailView = () => {
  const { id } = useParams<{ id: any }>();
  const parsed = queryString.parse(location.search);
  // redux state
  const instrument = useAppSelector(selectInstrument);
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  // component state
  const [productDetail, setProductDetail] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const resId = query.get("resId");
  // what: fetch api get detail function
  const fetchProductApi = async () => {
    setLoading(true);
    try {
      const response = await instrumentApi.viewInstrumentDetail({
        id,
      });
      setLoading(false);
      setProductDetail(response.data.data);
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.status === 404 || err?.response?.status === 500) {
        history.push(appRouteEnums.PATH_INSTRUMENTS);
      }
    }
  };
  const fetchHistoryProduct = async () => {
    setLoading(true);
    try {
      const response = await instrumentApi.instrumentDetailBorrowing({
        id,
        itemId: parsed.itemId,
      });
      setLoading(false);
      setProductDetail(response.data.data);
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.status === 404 || err?.response?.status === 500) {
        history.push(appRouteEnums.PATH_VEHICLES);
      }
    }
  };


  // what: fetch api borrow item
  const fetchBorrowApi = async (values: any) => {
    setLoading(true);
    let res;

    try {
      if (resId) {
        res = await instrumentApi.updateBorrowItem(
          {
            from: moment(values.startDate).format("YYYY-MM-DD"),
            to: moment(values.endDate).format("YYYY-MM-DD"),
            quantity: values.quantity,
            construction_id: values.construction_id,
            comment: values.comment,
          },
          resId
        );
      } else {
        res = await instrumentApi.instrumentBorrowItem(
          {
            from: values.startDate,
            to: values.endDate,
            quantity: values.quantity,
            construction_id: values.construction_id,
            comment: values.comment,
          },
          values.instrumentItemId
        );
      }
    } catch (error: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response?.data?.data,
        })
      );
    }

    setLoading(false);
    setShowModal(true);
    setMessage(res.data.message);
    dispatch(setIdConstuctionEmployeeSearch(values.construction_id));
  };


  // events
  const handleOnSubmit = (values: any) => {
    fetchBorrowApi(values);
  };
  React.useEffect(() => {
    if (parsed.itemId) {
      fetchHistoryProduct();
    } else {
      fetchProductApi();
    }
    return () => {
      // Do some cleanup
    };
  }, []);
  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(
      setInstrumentsCategory({
        category: "",
        subCategory: "",
      })
    );
    history.push(appRouteEnums.PATH_INSTRUMENTS);
  };
  // redirect instrument page when page is reloaded
  React.useEffect(() => {
    if (!instrument?.selectedDate) {
      dispatch(
        setInstrumentsCategory({
          category: "",
          subCategory: "",
        })
      );
      history.push(appRouteEnums.PATH_INSTRUMENTS);
    }
  }, []);
  // reset select daterange when unmounted
  React.useEffect(() => {
    return () => {
      dispatch(setSelectedDateInstrument(""));
    };
  }, []);
  return (
    <StyledInstrumetDetailView>
      {/* <AppTopBar /> */}
      <CircularLoader loading={loading} />
      <AppModal open={showModal} handleClose={handleCloseModal}>
        <AppAlert
          icon={<img src={hot_yellow_frame} alt="icon" />}
          text={message}
          handleClose={handleCloseModal}
        />
      </AppModal>
      {productDetail ? (
        <div className="InstrumentDetail">
          {/* <div className="InstrumentDetail"> */}
          <div className="InstrumentDetail__top">
            {/* <span
                className="InstrumentDetail__back"
                onClick={() => {
                  dispatch(
                    setInstrumentsCategory({
                      category: "",
                      subCategory: "",
                    })
                  );
                  history.push(appRouteEnums.PATH_INSTRUMENTS);
                }}
              >
                <img src={arrow_left} alt="<" />
              </span> */}
            <div className="InstrumentDetail__top-title">
              <p className="main">測定機器</p>
              {/* <p className="main">{productDetail?.title}</p>
                <p className="subTitle">{productDetail?.category}</p> */}
            </div>
          </div>
          <div className="InstrumentsDetail__content">
            <Grid container spacing={2} style={{ width: "100%" }}>
              <Grid item xs={12} md={12} style={{ maxWidth: "100%" }}>
                <InstrumentDetailInfoComponent
                  selectedDate={instrument?.selectedDate}
                  product={productDetail}
                  handleOnSubmit={handleOnSubmit}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ maxWidth: "100%" }}>
                <InstrumentDetialHistoryComponent
                  historyList={productDetail?.history}
                />
              </Grid>
            </Grid>
          </div>
          {/* </div> */}
        </div>
      ) : (
        <div></div>
      )}
    </StyledInstrumetDetailView>
  );
};
