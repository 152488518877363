import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  StyledVehicleDetailView,
  VehicleDetailInfoComponent,
  VehicleDetialHistoryComponent,
} from ".";
import { AppTopBar, CircularLoader, AppModal, AppAlert } from "..";
import arrow_left from "@assets/Icons/arrow_left.svg";
import { appRouteEnums } from "@enums";
import { instrumentApi, vehicleApi } from "@api";
import {
  useAppSelector,
  updateSnackBar,
  setSelectedDateVehicle,
  setVehiclesCategory,
  setIdConstuctionEmployeeSearch,
  selectVehicle,
} from "@redux";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import { Grid } from "@mui/material";
import moment from "moment";
const queryString = require("query-string");

export const VehicleDetailView = () => {
  const { id } = useParams<{ id: any }>();
  const parsed = queryString.parse(location.search);
  // redux state
  const vehicle = useAppSelector(selectVehicle);
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  // component state
  const [productDetail, setProductDetail] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const resId = query.get("resId");

  // what: fetch api get detail function
  const fetchProductApi = async () => {
    setLoading(true);
    try {
      const response = await vehicleApi.viewVehicleDetail({
        id,
      });
      setLoading(false);
      setProductDetail(response.data.data);
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.status === 404 || err?.response?.status === 500) {
        history.push(appRouteEnums.PATH_VEHICLES);
      }
    }
  };
  const fetchHistoryProduct = async () => {
    setLoading(true);
    try {
      const response = await vehicleApi.vehicleDetailBorrowing({
        id,
        itemId: parsed.itemId,
      });
      setLoading(false);
      setProductDetail(response.data.data);
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.status === 404 || err?.response?.status === 500) {
        history.push(appRouteEnums.PATH_VEHICLES);
      }
    }
  };
  const [showModal, setShowModal] = React.useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(
      setVehiclesCategory({
        category: "",
        subCategory: "",
      })
    );
    history.push(appRouteEnums.PATH_VEHICLES);
  };
  // what: fetch api borrow item
  const fetchBorrowApi = async (values: any) => {
    setLoading(true);

    if (!resId) {
      try {
        const res = await vehicleApi.vehicleBorrowItem(
          {
            from: values.startDate,
            to: values.endDate,
            quantity: values.quantity,
            construction_id: values.construction_id,
            comment: values.comment,
          },
          values.vehicleItemId
        );
        setLoading(false);
        setShowModal(true);
        setMessage(res.data.message);
        dispatch(setIdConstuctionEmployeeSearch(values.construction_id));
      } catch (error: any) {
        setLoading(false);
        dispatch(
          updateSnackBar({
            messageType: "error",
            message: error.response?.data?.data,
          })
        );
      }
    } else {
      try {
        const res = await instrumentApi.updateBorrowItem(
          {
            from: moment(values.startDate).format("YYYY-MM-DD"),
            to: moment(values.endDate).format("YYYY-MM-DD"),
            quantity: values.quantity,
            construction_id: values.construction_id,
            comment: values.comment,
          },
          resId
        );
        setLoading(false);
        setShowModal(true);
        setMessage(res.data.message);
        dispatch(setIdConstuctionEmployeeSearch(values.construction_id));
      } catch (error: any) {
        setLoading(false);
        dispatch(
          updateSnackBar({
            messageType: "error",
            message: error.response?.data?.data,
          })
        );
      }

    }
  };
  // events
  const handleOnSubmit = (values: any) => {
    fetchBorrowApi(values);
  };
  React.useEffect(() => {
    if (parsed.itemId) {
      fetchHistoryProduct();
    } else {
      fetchProductApi();
    }
    return () => {
      // Do some cleanup
    };
  }, []);
  React.useEffect(() => {
    if (!vehicle?.selectedDate) {
      history.push(appRouteEnums.PATH_VEHICLES);
      dispatch(
        setVehiclesCategory({
          category: "",
          subCategory: "",
        })
      );
    }
  }, []);
  React.useEffect(() => {
    return () => {
      dispatch(setSelectedDateVehicle(""));
    };
  }, []);
  return (
    <StyledVehicleDetailView>
      {/* <AppTopBar /> */}
      <CircularLoader loading={loading} />
      <AppModal open={showModal} handleClose={handleCloseModal}>
        <AppAlert
          icon={<img src={hot_yellow_frame} alt="icon" />}
          text={message}
          handleClose={handleCloseModal}
        />
      </AppModal>
      {productDetail ? (
        <div className="VehicleDetail">
          {/* <div className="VehicleDetail"> */}
          <div className="VehicleDetail__top">
            {/* <span
                className="VehicleDetail__back"
                onClick={() => {
                  dispatch(
                    setVehiclesCategory({
                      category: "",
                      subCategory: "",
                    })
                  );
                  history.push(appRouteEnums.PATH_VEHICLES);
                }}
              >
                <img src={arrow_left} alt="<" />
              </span> */}
            <div className="VehicleDetail__top-title">
              <p className="main">車両</p>
              {/* <p className="main">{productDetail?.title}</p>
                <p className="subTitle">{productDetail?.category}</p> */}
            </div>
          </div>
          <div className="VehiclesDetail__content">
            <Grid container spacing={2} style={{ width: "100%" }}>
              <Grid item xs={12} md={12} style={{ maxWidth: "100%" }}>
                <VehicleDetailInfoComponent
                  product={productDetail}
                  selectedDate={vehicle?.selectedDate}
                  handleOnSubmit={handleOnSubmit}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ maxWidth: "100%" }}>
                <VehicleDetialHistoryComponent
                  historyList={productDetail?.history}
                />
              </Grid>

            </Grid>
          </div>
          {/* </div> */}
        </div>
      ) : (
        <div></div>
      )}
    </StyledVehicleDetailView>
  );
};
