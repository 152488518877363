import styled from "styled-components";
import bg_profile from "@assets/Images/bg_profile.png";

export const StyledProfileView = styled.div`
    .profile {

        background-color:${(p) => p.theme.colors.white02};
        // background-repeat: no-repeat;
        // background-size: cover;
        height: 100vh;
        padding:1rem;
        &__logo {
            text-align: left;
        }
        &__topbar {
            background-color: ${(p) => p.theme.colors.white};
            border-bottom: 2px solid ${(p) => p.theme.colors.gray};
        }
        &__card {
        padding:1rem;
        margin-top:8rem;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            margin-top:7rem;
        }
        padding-bottom:14rem;
        border-radius:6px;
        background-color:${(p) => p.theme.colors.white00};
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        }
        &__left{


        }

        &__avatar {
        border-radius:50%;
        object-fit:contain;
        width:15rem;
        height:15rem;
        }

        &__icons {
        object-fit:contain;
        width:3rem;
        height:3rem;
        }

        &__left-photo {
        display:flex;
        gap:2rem;
        margin-bottom:1.5rem;
        }

        &__photo-container {
        position:relative;
        display:inline-block;

        }

        &__changebutton {
        position:absolute;
        bottom:0;
        left:0;
        }

        &__name-container {
        text-align:left;
        display:flex;
        flex-direction:column;
        justify-content:end;
        }

        &__details {
        padding:1rem 0rem;
        display:flex;
        align-items:center;
        justify-content:space-between;
        border-top:1px solid ${(p) => p.theme.colors.gray};
        }

        &__small-items {
          display:flex;
          align-items:center;
        }

        &__details-last {
        padding:1rem 0rem;
        display:flex;
        align-items:center;
        justify-content:space-between;
        border-top:1px solid ${(p) => p.theme.colors.gray};

        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display:block;
            justigy-content:none;
        }
        }

        &__small-items-last {
          display:flex;
          align-items:center;

        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display:none;
        }
        }

        &__logout {
        padding:1rem 0rem;
        border-top:0.5px solid ${(p) => p.theme.colors.gray};
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            border-bottom:0.5px solid ${(p) => p.theme.colors.gray};
            border-top:none;
        }
        }


        &__content {
            padding: 4rem 0;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                position: relative;
                // margin: 0 10rem;
                z-index: 1;
                &:after {
                    content: "";
                    z-index: -1;
                    top: 12rem;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 85%;
                    background-color: ${(p) => p.theme.colors.white00};;
                    color: ${(p) => p.theme.colors.gray};
                    border-radius: 6px;
                }
            }

        }
        &__error {
            color: ${(p) => p.theme.colors.error};
        }
        &__userInfo {
            display: flex;
            justify-content: center;
            flex-direction: column;

            &-imageWrapper {
                height: 16rem;
                width: 16rem;
                max-width: 16rem;
                min-height: 16rem;
                border-radius: 100px;
                border-radius: 100%;
                background-color: ${(p) => p.theme.colors.white};
                .image {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }
            }
            &-edit {
                color: ${(p) => p.theme.colors.secondary};
                font-size: 1.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                text-decoration: underline;
                padding-top: 1.8rem;
                &:hover {
                    cursor: pointer;
                }
            }
            &-name {
                font-size: 3rem;
                font-weight: bold;
                // font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                color: ${(p) => p.theme.colors.black01};
                // padding: 0 2rem;
                word-break: break-all;
                display: flex;
                // display: -webkit-box;
                max-width: 100%;
                // height: 80px;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                .firstName {
                  padding-right: 0.5rem;
                  }
                .lastName {
                  padding-left: 1rem;
                }
                @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    .firstName, .lastName {
                        font-size:2.5rem;
                    }
                }
            }
            &-id {
                // font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                color: ${(p) => p.theme.colors.black01};
                font-size: 1.5rem;
                font-weight:bold;
            }
        }
        &__items {
            padding: 0 1.6rem;
            padding-top: 2.5rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                padding: 0 10rem;
                padding-top: 2.5rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 0 10rem;
                padding-top: 2.5rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                padding: 0 15rem;
                padding-top: 2.5rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakSuperBig}) {
                padding: 0 35rem;
                padding-top: 2.5rem;
            }
        }
        &__inputUpload {
            display: none;
        }
        .item__date {
            margin-right: 1rem !important;
        }
        &__item {
            text-align: left;
            display: flex;
            padding: 1rem;
            border-radius: 1.6rem;
            margin-bottom: 1.6rem;
            background-color: ${(p) => p.theme.colors.white};
            .MuiIconButton-root {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: ${(p) => p.theme.colors.primary};
                    opacity: 0.1;
                    border-radius: 1.2rem;
                }
            }

            &-text {
                display: flex;
                align-items: center;
                padding-left: 0.5rem;
                font-size: 1.6rem;
                color: ${(p) => p.theme.colors.black01};
                word-break: break-all;
            }
            .email {
                display: block;
                display: -webkit-box;
                max-width: 100%;
                padding-top: 0.8rem;
                height: 45px;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-listCertis {
            }
            &-certi {
                padding: 1rem 1.3rem;
                background-color: ${(p) => p.theme.colors.gray};
                font-size: 1.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                margin: 0 0 1rem 1rem;
                border-radius: 1.2rem;
                color: ${(p) => p.theme.colors.darkBlack};
                display: block;
                display: -webkit-box;
                height: 6rem;
                max-width: 100%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 1rem;
            }
        }
        &__btn {
            text-align: left;
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            border-radius: 1.6rem;
            margin-top: 2rem;
            background-color: ${(p) => p.theme.colors.white};
            transition: 250ms;
            cursor: pointer;
            &:hover {
                background-color: ${(p) => p.theme.colors.darkBlack};
            }
            .MuiIconButton-root {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: ${(p) => p.theme.colors.secondary};
                    opacity: 0.1;
                    border-radius: 1.2rem;
                }
            }
            &-text {
                width: 90%;
                display: flex;
                align-items: center;
                padding-left: 1.1rem;
                font-size: 1.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                color: ${(p) => p.theme.colors.secondary};
            }
            &-next {
                display: flex;
                align-items: center;
            }
        }
        &__btnLogout {
            display: flex;
            justify-content: center;
            padding-top: 7rem;
            padding-bottom: 5rem;
            .btn {
                cursor: pointer;
            }
            .text {
                font-size: 1.4rem;
                color: ${(p) => p.theme.colors.gray1};
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                padding-left: 0.8rem;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
`;

export const LogoutButton = styled.div`
padding: 1rem 3rem;
border-radius: 50px;
font-weight: bold;
transition: all 0.3s;
width:20rem;
text-align : center;
font-size : 12px;
cursor: pointer;
background-color: ${(p)=>p.theme.colors.crimson};
color: ${(p)=>p.theme.colors.white00};

 @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width:100%;
        }

&:hover {
  background-color: ${(p)=>p.theme.colors.red05};
}
&:focus {
  outline: 2px solid ${(p)=>p.theme.colors.lightgray09};
}

`;

export const PassChangeButton = styled.button<{ disabled?: boolean }>`
padding: 1rem 3rem;
border-radius: 50px;
font-weight: bold;
transition: all 0.3s;
width:20rem;
text-align : center;
font-size : 12px;
cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
height:4rem;
background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray03 : theme.colors.orange)};
color: ${(p)=>p.theme.colors.white00};
opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
border:none;

@media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width:100%;
        }

&:hover {
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray03 : theme.colors.yellow08)};
  }
&:focus {
    outline: ${({ theme, disabled }) => (disabled ? "none" : `2px solid ${theme.colors.lightgray09}`)};
  }
`;

export const GrayButton = styled.button`
padding: 1rem 3rem;
border-radius: 50px;
font-weight: bold;
transition: all 0.3s;
width:20rem;
text-align : center;
font-size : 12px;
cursor: pointer;
height:4rem;
background-color: ${(p)=>p.theme.colors.gray03};
color: ${(p)=>p.theme.colors.white00};
border:none;

@media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width:100%;
        }

&:hover {
  background-color: ${(p)=>p.theme.colors.black01};
}
&:focus {
  outline: 2px solid ${(p)=>p.theme.colors.lightgray09};
}
`;
