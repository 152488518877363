export const responsiveText = (text: string) => {
    const temp = text.trim().split("");
    const arrayLength = (temp.length / 2).toFixed();
    return temp.length > 10
        ? `<>
            ${temp.slice(0, parseInt(arrayLength)).join("")}
            <br />
        </>`
        : `<>{temp.toString()}</>`;
};
// WHAT: if text length more than 4 characters, display 4 charaters and three dots
export const ellipsisText = (text: any, showNumber: number) => {
    return text && text.length > showNumber ? `${text.substring(0, showNumber)}...` : text;
};
export const linkImage = (text: any) => {
    return text;
};

export const linkAvatar = (text: string, attempt = 1): string => {
    if (attempt === 0) { return text; }
    if (!text) { return text; }

    let baseUrl = process.env.REACT_APP_S3_BUCKET_URL;

    if (attempt === 2) {
        baseUrl = "https://livelet-production.s3.ap-northeast-3.amazonaws.com";
    } else if (attempt === 3) {
        baseUrl = "https://livelet-stage.s3.ap-northeast-3.amazonaws.com";
    }

    if (!baseUrl) { return text; }

    // Strip any trailing slash from the base URL
    const normalizedBaseUrl = baseUrl.replace(/\/+$/, "");

    // Use a URL regex to detect if the string contains a domain
    const urlRegex = /^(https?:\/\/)?([^/]+)(\/.+)?$/;
    const match = text.match(urlRegex);

    if (match && match[3]) {
        const path = match[3] || ''; // Extract the path from the input (if any)
        // Strip any leading slash from the path
        const normalizedPath = path.replace(/^\/+/, "");
        return `${normalizedBaseUrl}/${normalizedPath}`;
    }

    // If it's just a filename, prepend the base URL directly
    const normalizedText = text.replace(/^\/+/, ""); // Strip leading slash
    return `${normalizedBaseUrl}/${normalizedText}`;
};

export const linkProductImage = (text: any) => {
    return `${process.env.REACT_APP_S3_BUCKET_URL}${text}`;
};

// config link pdf to sever storage
export const linkDocument = (text: any) => {
    return text;
    // return text;
};

export const isLinkExel = (urlString: any)=>{
    const splitString =  urlString.split(".");
    return splitString[splitString.length-1]==="xlsx"?true:false;
};

// convert url
export const converUrlToGetId = (urlString: any) =>{
    const splitString = urlString.split("/");
    if (splitString.includes('edit')) {
        return splitString[splitString.length - 2];
    }
    return splitString[splitString.length-1].split("?")[0];
};
// get date params on url 
// timesheet/new?2022-09-15 = > 2022-09-15 
export const getDateParamOnUrl = (url: string) => {
    const tempArr = url.split("?");
    return tempArr.length>1 ? tempArr[tempArr.length - 1] : null;
};