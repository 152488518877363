import { theme } from "@utils";
import styled from "styled-components";

type IStyledAppButton = {
    color?: string;
};

export const StyledAppButton = styled.div`
    width: 100%;
    .btn {
        &__wrapper {
            width: 100%;
            .MuiButton-root {
                width: 100%;
                text-align: center;
                padding: 10px;
                font-size: 16px;
                background-color: ${(p) => p.theme.colors.darkBlack};
                color: ${(p) => p.theme.colors.gray6};
                border-radius: ${(p) => p.theme.borderRadius.base};
                border: 1px solid transparent;
                cursor: pointer;
            }
        }
        &__text{
            padding-right: 0.5rem;
            /* text-transform: capitalize; */
        }
    }
    .Mui-disabled {
        /* color: rgba(0, 0, 0, 0.26) !important; */
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        background-color: #929292 !important;
    }
`;

export const StyledAppIconButton = styled.div<IStyledAppButton>`
    .MuiIconButton-root {
        color:${theme.colors.white00};
        border-radius: 100%;
        padding: 12px;
        background-color: ${(p) =>
        p.color === "primary" ? p.theme.colors.orange : "transparent"};
        &:hover {
            background-color: ${(p) =>
        p.color === "primary" ? p.theme.colors.orange : "transparent"};
        }
        // border: 1px solid ${(p) => p.theme.colors.borderButtonIcon};
    }
    .button-text {
        font-size: 24px;
        font-weight: bold;
        margin-left: 4px;
        color: ${(p) => p.theme.colors.white00};
    }
`;
