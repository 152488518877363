import styled from "styled-components";

export const StyledChangePassword = styled.div`
    height: 100vh;
    width:100vw;
    background-color: ${(p) => p.theme.colors.white02};
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    .forgot {

      &__container{
        margin:0 auto;
        width:50rem;

       @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        width: 100%;
        max-width: calc(100% - 1rem);
        box-sizing: border-box;
      }


      }

      &__logo{
      width:10rem;
      height:10rem;
      object-fit:contain;
      margin-bottom:1rem;
    }

      &__backIconContainer {
        margin:2rem 0;
        display: flex;
        justify-content: flex-start;

      }

      &__titleContainer {
       color: ${(p) => p.theme.colors.black01};
       display: flex;
       justify-content: flex-start;

      }

      &__content {
        background-color: ${(p) => p.theme.colors.white00};
        border-radius:6px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        margin:0 auto;
        width:50rem;
        padding:3rem 6rem;

        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding:3rem 1.5rem;
        width: 100%;
        max-width: calc(100% - 2rem);
        box-sizing: border-box;
      }

      }

      &__input {

      }

      &__btn {
        margin-top:2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }



    }

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
           padding:1rem;
        }

    .pageTitle {
    text-align:left;

    }

    .title-text{
    padding-bottom:20px;
    font-size: 40px;
        // font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
        color: ${(p) => p.theme.colors.black01};
      @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
      font-size: 32px;
    }
    }

    .formContainer {
    border-radius:6px;
    padding:2rem;
    background-color: ${(p) => p.theme.colors.white00};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);


    }

    .submitContainer {

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
      width:100%;
    }
    }

    .submit-button {
    all: unset;
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: end;

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
       justify-content: center;
    }

    }


    .forgotPassword__wrapper {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        text-align: left;
        padding: 40px 15px 0 15px; /*top right bottom left */
        background-color: ${(p) => p.theme.colors.white};
        // max-height: 100%;
        border-radius: 6px;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width: 400px;
            margin-top: 20rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            width: 808px;
            max-width: 808px;
            margin-top: 0;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            padding: 0px 15px 0 15px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            padding: 40px 15px 0 15px;
        }
    }
    .backToPrevious {
        &__text {
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            color: ${(p) => p.theme.colors.black};
        }
    }
    .fillForm {
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 20px 180px 40px 180px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            padding: 0px 180px 10px 180px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            padding: 20px 180px 80px 180px;
        }
        &__title {
            width: 56%;
            position: relative;
            z-index: 0;
            display: inline-block;
            margin-top: 20px;
            @media (min-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                width: 98%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
                margin-top: 0px;
                width: 99%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
                width: 87%;
                margin-top: 40px;
            }
            .text {
                font-size: 32px;
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                color: ${(p) => p.theme.colors.black};
            }
            .line {
                position: absolute;
                width: 100%;
                z-index: -1;
                bottom: -8px;

                &__icon {
                    width: 100%;
                    height: 10px;
                }
            }
        }
        &__input {
            margin: 1.5rem;
            text-align:left;
        }
        &__btn {
            margin-top: 35px;
        }
    }
    .forgotPassword__title {
        width: 56%;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width: 100%;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            width: 90%;
        }
    }
`;
