import React from "react";
// import { useHistory } from "react-router-dom";

import { menuTitleEnums } from "@enums";
import { TitleMenuComponent } from "..";
// import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { StyledAlcohol } from ".";
import AlcoholCheckerTable from "./AlcoholCheckerTable";
import "../../App.css";

export const AlcoholCheck = () => {
  // hooks
  // const history = useHistory();
  // component state
  const [date, setDate] = React.useState(new Date());
  // events
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value); // Convert string to Date
    setDate(newDate);
  };

  return (
    <StyledAlcohol>
      {/* <AppTopBar /> */}
      {/* <CircularLoader loading={loading} /> */}
      <div className="al-detail">
        {/* <p
          className="al-detail__top-icon"
          onClick={() => history.push(appRouteEnums.PATH_HOME)}
        >
          <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
        </p> */}
        <div className="al-detail__title">
          <TitleMenuComponent text={menuTitleEnums.TITLE_ALCOHOL_CHECK} />
        </div>
        <div className="al-detail__datePicker">
          <div className="label">作成日時</div>
          <div className="wrapperDatePicker">
          <input
            type="date"
            value={date.toISOString().split("T")[0]} // Format as YYYY-MM-DD
            onChange={handleChange}
            className="inputMonth"
          />
          </div>
        </div>
        <AlcoholCheckerTable date={date} />
      </div>
    </StyledAlcohol>
  );
};
