import { StyledTableViewWorkSchedule } from ".";

import { useEffect, useState } from "react";
import { AppModal, CircularLoader } from "../Common";
import { timesheetApi, workScheduleApi } from "@api";
import { useDispatch } from "react-redux";
import { updateSnackBar } from "@redux";
import ListWorkType from "./ListWorkType";
import { useWorkScheduleContext } from "./context/WorkScheduleContext";
import { IStoreWorkTypeRequest } from "@interfaces";
import { UpdateOverallTypeForm } from "./UpdateOverallTypeForm";
import { UpdateClassificationForm } from "./UpdateClassificationForm";
import { UpdateTypeForm } from "./UpdateTypeForm";

interface IProps {
    handleOpenDrawserToggle?: (id: any) => void;
    handleCloseDrawserToggle?: () => void;
    hasCollapse?: boolean;
    isCollapse?: boolean;
    data?: any;
    setReloadPage: React.Dispatch<React.SetStateAction<boolean>>;
    reloadPage: boolean;
    collapsedRowIds: number[];
    setCollapsedRowIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const TableViewWorkSchedule = (props: IProps) => {
    const { data, setReloadPage, reloadPage, collapsedRowIds, setCollapsedRowIds } = props;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [isOpenOverallTypeForm, setIsOpenOverallTypeForm] = useState(false);
    const [isOpenCreateClassificationForm, setIsOpenCreateClassificationForm] = useState(false);
    const [isOpenTypeForm, setIsOpenTypeForm] = useState(false);

    const [type1Data, setType1Data] = useState();
    const [type2Data, setType2Data] = useState();
    const [type3Data, setType3Data] = useState();

    const { setColorOptions, setCompanyOptions } = useWorkScheduleContext();

    // const [workType1, setWorkType1] = useState([])

    const handleOpenOverallTypeForm = () => {
        setIsOpenOverallTypeForm(true);
    };

    const handleCloseOverallTypeForm = (resetForm: any) => {
        resetForm();
        setIsOpenOverallTypeForm(false);
    };
    const handleOpenClassificationForm = () => {
        setIsOpenCreateClassificationForm(true);
    };
    const handleCloseClassificationForm = (resetForm: any) => {
        resetForm();
        setIsOpenCreateClassificationForm(false);
    };

    const handleOpenTypeForm = () => {
        setIsOpenTypeForm(true);
    };

    const handleCloseTypeForm = (resetForm: any) => {
        setIsOpenTypeForm(false);
        resetForm();
    };
    // const handleOnCreateClassificationSchedule = (values: any, resetForm: any) => {
    //     fetchCreateClassificationWorkScheduleApi(values, resetForm);
    // };

    const handleOnCreateTypeSchedule = (values: any, resetForm: any) => {
        fetchCreateTypeWorkScheduleApi(values, resetForm);
    };

    const handleDeleteWorkType = async (id: number) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.deleteWorkType({
                work_schedule_work_type_id: id,
            });
            setLoading(false);
            setIsOpenOverallTypeForm(false);
            setIsOpenCreateClassificationForm(false);
            setIsOpenTypeForm(false);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.message,
                })
            );
            setReloadPage?.(!reloadPage);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };

    // const fetchCreateClassificationWorkScheduleApi = async (values: any, resetForm: any) => {
    //     setLoading(true);
    //     try {
    //         const response = await workScheduleApi.storeClassificationWorkSchedule({
    //             ...values,
    //         });
    //         setLoading(false);
    //         // handleCloseNewForm(resetForm);
    //         handleCloseClassificationForm(resetForm);
    //         dispatch(
    //             updateSnackBar({
    //                 messageType: "success",
    //                 message: response.data.message,
    //             })
    //         );
    //         setReloadPage?.(!reloadPage);
    //         resetForm();
    //     } catch (err: any) {
    //         setLoading(false);
    //         dispatch(
    //             updateSnackBar({
    //                 messageType: "error",
    //                 message: err.response.data.message,
    //             })
    //         );
    //     }
    // };

    const fetchCreateTypeWorkScheduleApi = async (values: IStoreWorkTypeRequest, resetForm: any) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.storeTypeWorkScheduleV2({
                ...values,
                construction_id: data?.id,
                contractor_id: 0,
            });
            setLoading(false);
            handleCloseTypeForm(resetForm);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.message,
                })
            );
            setReloadPage?.(!reloadPage);
            resetForm();
            setIsOpenOverallTypeForm(false);
            setIsOpenCreateClassificationForm(false);
            setIsOpenTypeForm(false);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };

    const organizeWorkTypes = (workTypes) => {
        const type1 = workTypes.filter((wt) => wt.work_type.type === 1);
        const type2 = workTypes.filter((wt) => wt.work_type.type === 2);
        const type3 = workTypes.filter((wt) => wt.work_type.type === 3);

        const organized = type1.map((t1) => {
            const childrenType2 = type2
                .filter((t2) => t2.parent_id === t1.id)
                .map((t2) => {
                    const childrenType3 = type3.filter((t3) => t3.parent_id === t2.id);
                    return { ...t2, children: childrenType3 };
                });
            return { ...t1, children: childrenType2 };
        });

        return organized;
    };

    const fetchListContractors = async () => {
        try {
            const result = await timesheetApi.getListContractors();
            setCompanyOptions(result?.data?.data?.contractors);
        } catch (err) {}
    };

    const fetchListColors = async () => {
        try {
            const result = await timesheetApi.getListColors();
            setColorOptions(result?.data.data);
        } catch (err) {}
    };

    useEffect(() => {
        fetchListContractors();
        fetchListColors();
    }, []);

    return (
        <StyledTableViewWorkSchedule>
            <table className="table" style={{borderSpacing: 0}}>
                <tr className="table__header">
                    <td style={{ width: "20vw" }}>
                    </td>
                </tr>
                {data &&
                    data?.work_schedules?.map((schedule, scheduleIndex) => (
                        <div key={scheduleIndex}>
                            {organizeWorkTypes(schedule?.work_schedule_work_type).map((type1, index) => {
                                return (
                                    <ListWorkType
                                        key={index}
                                        type1={type1}
                                        setType1Data={setType1Data}
                                        setType2Data={setType2Data}
                                        setType3Data={setType3Data}
                                        handleOpenTypeForm={handleOpenTypeForm}
                                        handleOpenClassificationForm={handleOpenClassificationForm}
                                        handleOpenOverallTypeForm={handleOpenOverallTypeForm}
                                        setReloadPage={setReloadPage}
                                        collapsedRowIds={collapsedRowIds}
                                        setCollapsedRowIds={setCollapsedRowIds}
                                    />
                                );
                            })}
                        </div>
                    ))}
            </table>

            {isOpenOverallTypeForm && (
                <AppModal open handleClose={() => setIsOpenOverallTypeForm(false)}>
                    <UpdateOverallTypeForm data={type1Data} handleDeleteWorkType={handleDeleteWorkType} handleCloseNew={handleCloseOverallTypeForm} handleOnCreateSchedule={handleOnCreateTypeSchedule} />
                    {/* <CreateClassificationForm data={type1Data} handleCloseNew={handleCloseClassificationForm} handleOnCreateSchedule={handleOnCreateClassificationSchedule} /> */}
                </AppModal>
            )}

            {isOpenCreateClassificationForm && (
                <AppModal open handleClose={() => setIsOpenCreateClassificationForm(false)}>
                    {/* <CreateTypeForm data={type2Data} handleCloseNew={handleCloseTypeForm} handleOnCreateSchedule={handleOnCreateTypeSchedule} /> */}
                    <UpdateClassificationForm data={type2Data} handleDeleteWorkType={handleDeleteWorkType} handleCloseNew={handleCloseClassificationForm} handleOnCreateSchedule={handleOnCreateTypeSchedule} />
                </AppModal>
            )}

            {isOpenTypeForm && (
                <AppModal open handleClose={() => setIsOpenTypeForm(false)}>
                    {/* <CreateTypeForm data={type2Data} handleCloseNew={handleCloseTypeForm} handleOnCreateSchedule={handleOnCreateTypeSchedule} /> */}
                    <UpdateTypeForm data={type3Data} handleDeleteWorkType={handleDeleteWorkType} handleCloseNew={handleCloseTypeForm} />
                </AppModal>
            )}
            <CircularLoader loading={loading} />
        </StyledTableViewWorkSchedule>
    );
};
