import React from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { DEFAULT_OVERTIME_INPUT } from "@configs";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import styled from "styled-components";
import { AppCustomeSelect, AppInput, AppTimePicker } from "../Common";
import { formatDate } from "@utils";
import useContractors from "src/Utils/Hooks/useContractors";

interface IProps {
  values: any;
  handleChange: any;
  touched: any;
  errors: any;
  handleBlur: any;
  optionSiteConstructions: any;
  optionConstractors: any;
  setFieldValue: any;
  onChangeConstractors: any;
  onChangeConstruction: any;
}

const OTNewHoliday = (props: IProps) => {
  const {
    values,
    handleChange,
    touched,
    errors,
    optionSiteConstructions,
    optionConstractors,
    setFieldValue,
    onChangeConstractors,
    handleBlur,
    onChangeConstruction,
  } = props;
  const [valueConstruction, setValueConstruction] = React.useState<any>(null);

  const listConstructionOption =
    optionSiteConstructions?.constructions?.length &&
    optionSiteConstructions?.constructions?.map((item) => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });
  const constructionSelected = listConstructionOption?.find(
    (item) => item.value === values?.construction.toString()
  );
  const { updateContractorHistory } = useContractors();

  return (
    <div className="newOT__time">
      <Grid container spacing={4}>
        <Grid item xs={12} md={7} container>
          <WrapTime>
            <div className="start-time">
              <label className="label">開始時間</label>
              <AppTimePicker
                value={dayjs(values.start_time, "HH:mm:ss").toDate()}
                handleChange={(newValue) => {
                  const formatValue = formatDate({
                    date: newValue,
                    format: "HH:mm:ss",
                  });
                  setFieldValue("start_time", formatValue);
                }}
                errorMessage={
                  touched.start_time && errors.start_time
                    ? errors.start_time
                    : undefined
                }
              />
            </div>
            <div className="end-time">
              <label className="label">終了時間</label>
              <AppTimePicker
                value={dayjs(values.stop_time, "HH:mm:ss").toDate()}
                handleChange={(newValue) => {
                  const formatValue = formatDate({
                    date: newValue,
                    format: "HH:mm:ss",
                  });
                  setFieldValue("stop_time", formatValue);
                }}
                errorMessage={
                  touched.stop_time && errors.stop_time
                    ? errors.stop_time
                    : undefined
                }
              />
            </div>
          </WrapTime>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="user__department">
            <p className="label">工事名</p>
            <AppCustomeSelect
              options={listConstructionOption}
              placeholder="select 請負業者"
              name="construction"
              value={valueConstruction || constructionSelected}
              // noError={errors.constructors}
              onChange={(value) => {
                updateContractorHistory(value);
                onChangeConstruction(value, setFieldValue);
                setValueConstruction(value);
              }}
              error={errors.construction}
              touched={touched.construction}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="input">
            <AppInput
              isNumber
              value={String(values.work_time)}
              handleChange={handleChange("work_time")}
              label="稼働時間 (hour)"
              error={errors.work_time}
              touched={touched.work_time}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="input">
            <AppInput
              isNumber
              value={String(values.break_time)}
              handleChange={handleChange("break_time")}
              label="休憩 (hour)"
              error={errors.break_time}
              touched={touched.break_time}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="input">
            <AppInput
              label="業務内容"
              value={values.work_detail}
              name="work_detail"
              error={errors.work_detail}
              touched={touched.work_detail}
              handleChange={handleChange("work_detail")}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="input">
            <label className="label">業者名</label>

            <div className="select__contractor">
              <div style={{ width: "70%" }}>
                <AppCustomeSelect
                  groupOptions={optionConstractors}
                  options={
                    optionConstractors?.contractors?.length &&
                    optionConstractors?.contractors.map((item) => {
                      return {
                        label: item.name,
                        value: item.id.toString(),
                      };
                    })
                  }
                  placeholder="select 請負業者"
                  name="subContractor"
                  value={values.subContractor}
                  // noError={errors.constructors}
                  onChange={(value) => {
                    updateContractorHistory(value);
                    onChangeConstractors(value, values, setFieldValue);
                    setFieldValue("subContractor", value);
                  }}
                  isMulti
                />
              </div>
            </div>
            {errors.subContractor && touched.subContractor && (
              <p
                style={{
                  color: "red",
                  fontSize: "13px",
                  marginTop: "5px",
                }}
              >
                {errors?.subContractor}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <label className="label">振替休日予定</label>
          <div>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={values.compensatory_leave}
              onChange={handleChange("compensatory_leave")}
              className="form__checkbox"
            >
              <FormControlLabel
                value={1}
                className={clsx(
                  parseInt(values.compensatory_leave) === 0 ? "active" : ""
                )}
                control={<Radio />}
                label="はい"
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="いいえ"
                className={clsx(
                  parseInt(values.compensatory_leave) === 1 ? "active" : ""
                )}
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="未定"
                className={clsx(
                  parseInt(values.compensatory_leave) === 2 ? "active" : ""
                )}
              />
            </RadioGroup>
          </div>
          {parseInt(values.compensatory_leave) === 1 && (
            <AppInput
              value={values.detail}
              name="detail"
              handleChange={handleChange("detail")}
              error={touched.detail ? errors.detail : undefined}
              touched={touched.detail}
              handleBlur={handleBlur("detail")}
              placeholder={DEFAULT_OVERTIME_INPUT}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="input">
            <AppInput
              label="備考"
              value={values.memo}
              name="memo"
              // error={errors.memo}
              error={touched.memo ? errors.memo : undefined}
              touched={touched.memo}
              handleChange={handleChange("memo")}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OTNewHoliday;

const WrapTime = styled.div`
    display: flex;
    .end-time {
        margin-left: 20px;
    }
`;
