import styled from "styled-components";

// import bg_profile from "@assets/Images/bg_profile.png";
// import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledHomeContent = styled.div`
    background-color: ${(p) => p.theme.colors.white02};
    min-height:100vh;
    padding:2rem;
    @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
      padding:1rem;
    }

    .home {
        &__content-lists {
        padding-top: 2rem;
        }
        &__title {
        font-size:16px;
        text-align:left;
        padding-bottom:0.5rem;
        }
        &__profile{
        padding:1rem;
        display:flex;
        align-items:center;
        justify-content:start;
        border-bottom:1px solid ${(p) => p.theme.colors.lightgray09};
        }
        &__profile-name{
        color:${(p) => p.theme.colors.black01};
        font-size:40px;
        }
        &__profile-image {
        object-fit:contain;
        height:10rem;
        width:10rem;
        border-radius:100%;
        margin:10px;
        }
        &__wrapper {
             margin-top:8rem;
             padding-bottom:14rem;
        }
        &__topbar {
            background-color: ${(p) => p.theme.colors.white};
            border-bottom: 1px solid #f0f0f0;

            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                padding-top: 0;
            }
        }
        .wrapperContent {
            display: flex;
            justify-content: center;
        }
        &__content {

            }
            &-menu {
                display: block;
                background-color: ${(p) => p.theme.colors.white};
                border: 2px solid ${(p) => p.theme.colors.primary};
                border-radius: 2.4rem;
                padding: 2rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                    padding: 4rem 8rem;
                }
            }
                .wrapperText {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 2rem;
                    padding-right: 1rem;
                }
                .text {
                    position: relative;
                    max-width: 3rem;
                    font-size: 3.4rem;
                    line-height: 4rem;
                    color: #000000;
                    z-index: 1;
                    font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 110%;
                        height: 80%;
                        background-color: ${(p) => p.theme.colors.primary};
                        z-index: -1;
                    }
                }
            }
        }

        &__btnModal {
            width: 40rem;
            max-width: 100%;
        }
    }
`;
export const StyledListItem = styled.div`
    padding: 2rem 2rem;
    border-radius:6px;
    // border: 1px solid #d4d4d4;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color:${(p) => p.theme.colors.white00};
    // &::after {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     height: 2px;
    //     width: 9rem;
    //     background-color: ${(p) => p.theme.colors.secondary};
    // }
    // &::before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 2px;
    //     height: 9rem;
    //     background-color: ${(p) => p.theme.colors.secondary};
    // }
    .item {
        padding: 1.6rem 0;
        border-top: 1px solid ${(p) => p.theme.colors.black};
        height: 20.6rem;
        @media(min-width: 900px)  {
            /* min-height: 13.6rem; */
            height:auto;
        }
        &__top {
            display: flex;
            justify-content: space-between;
        }
        &__title {
            width: 105%;
            font-size: 1.4rem;
            cursor: pointer;
            word-break: break-all;
            padding-right: 1.5rem;
            color: #292d32;
            text-align: left;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        }
        &__amount {
            text-align: initial;
            color: #8e8e8e;
            font-size: 1.2rem;
        }
        &__code {
            font-size: 1.2rem;
            color: ${(p) => p.theme.colors.gray2};
            width: 13rem;
            text-align: right;
            cursor: pointer;
        }
        &__des {
            padding-top: 1.5rem;
            text-align: left;
            color: #656565;
            font-size: 1.2rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            word-break: break-all;
            cursor: pointer;
        }
    }
    .item.crossed-out {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><line x1="5" y1="5" x2="95" y2="95" stroke="black" stroke-width="1"/><line x1="95" y1="5" x2="5" y2="95" stroke="black" stroke-width="1"/></svg>') no-repeat center;
        background-size: 100% 100%;
        pointer-events: none;
    }
`;

export const Tag = styled.div<{ bgColor: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  font-size: 12px;
  color: white;
  background-color: ${(p) => p.bgColor};
  border-radius:20px;
  position: relative;
  font-weight: bold;

  @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
      padding: 0.5rem 3rem;
    }
`;

export const Border = styled.span`
  display: block;
  margin-left:2rem;
  border-bottom: 1px solid ${(p) => p.theme.colors.lightgray09};
  width: 83%;
`;

export const StyledHomeItem = styled.div`
  background-color:${(p)=>p.theme.colors.white02};
  color:${(p)=>p.theme.colors.black02};

  .home-table {
    background-color:${(p)=>p.theme.colors.white00};
    border-radius:6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .subTitle {
    background-color:${(p)=>p.theme.colors.lightgray09};
    color:${(p)=>p.theme.colors.gray04};
    border-top-right-radius:6px;
    border-top-left-radius:6px;
    padding:1rem;

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
    display:none;
    }
  }

  .home-content {

  }

  .label {
    display:none;

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
      justify-content:flex-end;
      display:flex;
    }

  }

  .label-arrow {
    display:flex;
    justify-content:center;

    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        display:none;
    }
  }

  .recordName {

    &__text{
      color:${(p)=>p.theme.colors.gray03};

      @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
      display:none;
      }
    }
  }

`;
