import { TimeSheetFormNameForm, TimeSheetFormTypeEnums } from "@enums";


import { StyledTimeSheetUserInfo } from "./styles";
// import { LABEL_TIME_SHEET_TYPE } from "@configs";
// import moment from "moment";


interface IProps {
  type?: any;
  first_name?: string;
  last_name?: string;
  date?: any;
  created_at?: string;
  handleChange?: (value: any) => void;
  isDetail?: boolean;
}
export const TimeSheetUserInfo = (props: IProps) => {
  const { type, first_name, last_name } = props;

  return (
    <StyledTimeSheetUserInfo>
      <div className="row_first">
        {/* <p className="title">{LABEL_TIME_SHEET_TYPE}123</p> */}
        <p className="main">
          {(() => {
            switch (parseInt(type)) {
              case TimeSheetFormTypeEnums.ENUMS_TYPE_DAYSHIT:
                return TimeSheetFormNameForm.ENUMS_NAME_DAYSHIFT;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_NIGHTSHIFT:
                return TimeSheetFormNameForm.ENUMS_NAME_NIGHTSHIFT;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_DATSHIFT_AND_NIGHTSHIFT:
                return TimeSheetFormNameForm.ENUMS_NAME_DATSHIFT_AND_NIGHTSHIFT;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_HOLIDAYWORK:
                return TimeSheetFormNameForm.ENUMS_NAME_HOLIDAYWORK;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_PAID_TIME_OFF:
                return TimeSheetFormNameForm.ENUMS_NAME_PAID_TIME_OFF;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_HALFDAY_WORK:
                return TimeSheetFormNameForm.ENUMS_NAME_HALFDAY_WORK;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_ABSENCE:
                return TimeSheetFormNameForm.ENUMS_NAME_ABSENCE;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_LEGAL_HOLIDAY:
                return TimeSheetFormNameForm.ENUMS_NAME_LEGAL_HOLIDAY;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_NIGHTSHIFT_AND_DAYSHIFT:
                return TimeSheetFormNameForm.ENUMS_NAME_NIGHTSHIFT_AND_DAYSHIFT;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_OVERTIME:
                return TimeSheetFormNameForm.ENUMS_NAME_OVERTIME;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_BEREAVEMENT_LEAVE:
                return TimeSheetFormNameForm.ENUMS_NAME_BEREAVEMENT_LEAVE;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_EARLY_LEAVE:
                return TimeSheetFormNameForm.ENUMS_NAME_EARLY_LEAVE;
              case TimeSheetFormTypeEnums.ENUMS_TYPE_TARDINESS:
                return TimeSheetFormNameForm.ENUMS_NAME_TARDINESS;
              default:
                return null;
            }
          })()}
        </p>
        {/* <p className="main">
          {last_name}
          {first_name}
        </p> */}
      </div>
      {/* <div className="row">
                <p className="title">作成日時</p>
                <p>{formatDate({ date: dayjs(created_at), format: "YYYY-MM-DD HH:mm:ss" })}</p>
            </div> */}
      {/* <div className="row">
                <p className="title">{LABEL_TIME_SHEET_TYPE}</p>
                <p className="main">
                    {(() => {
                        switch (parseInt(type)) {
                            case TimeSheetFormTypeEnums.ENUMS_TYPE_DAYSHIT:
                                return TimeSheetFormNameForm.ENUMS_NAME_DAYSHIFT;
                            case TimeSheetFormTypeEnums.ENUMS_TYPE_NIGHTSHIFT:
                                return TimeSheetFormNameForm.ENUMS_NAME_NIGHTSHIFT;
                            case TimeSheetFormTypeEnums.ENUMS_TYPE_DATSHIFT_AND_NIGHTSHIFT:
                                return TimeSheetFormNameForm.ENUMS_NAME_DATSHIFT_AND_NIGHTSHIFT;
                            case TimeSheetFormTypeEnums.ENUMS_TYPE_HOLIDAYWORK:
                                return TimeSheetFormNameForm.ENUMS_NAME_HOLIDAYWORK;
                            case TimeSheetFormTypeEnums.ENUMS_TYPE_PAID_TIME_OFF:
                                return TimeSheetFormNameForm.ENUMS_NAME_PAID_TIME_OFF;
                            case TimeSheetFormTypeEnums.ENUMS_TYPE_HALFDAY_WORK:
                                return TimeSheetFormNameForm.ENUMS_NAME_HALFDAY_WORK;
                            case TimeSheetFormTypeEnums.ENUMS_TYPE_ABSENCE:
                                return TimeSheetFormNameForm.ENUMS_NAME_ABSENCE;
                            case TimeSheetFormTypeEnums.ENUMS_TYPE_LEGAL_HOLIDAY:
                                return TimeSheetFormNameForm.ENUMS_NAME_LEGAL_HOLIDAY;
                            default:
                                return null;
                        }
                    })()}
                </p>
            </div> */}
    </StyledTimeSheetUserInfo>
  );
};
