import { OVERTIME_TYPE_VALUE } from "@enums";
import React from "react";

interface IProps {
  data?: any;
}
const OTDetailBereavement = (props: IProps) => {
  const { data } = props;
  return (
    <div>
      {data?.overtimeData?.phone_number && (
        <div className="user__contents">
          <p className="user__title">連絡先</p>
          <p className="user__title">{data?.overtimeData?.phone_number}</p>
        </div>
      )}
      {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT &&
        (data?.overtimeData?.family_living || data?.overtimeData?.family_relationship) && (
          <div className="user__contents">
            <p className="user__title">続柄</p>
            <p className="user__title">
              {data?.overtimeData?.family_living === 0 ? "同居" : "別居"} -{" "}
              {data?.overtimeData?.family_relationship}
            </p>
          </div>
        )}
      {data?.overtimeData?.memo && (
        <div className="user__contents-last">
          <p className="user__title">備考</p>
          <p className="user__title">{data?.overtimeData?.memo}</p>
        </div>
      )}
    </div>
  );
};

export default OTDetailBereavement;
