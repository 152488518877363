import { Formik } from "formik";
import React from "react";
import { StyledNewFormWorkSchedule } from "..";
import { workScheduleTypeForm } from "@utils";
import { Box, Button, Stack } from "@mui/material";
import DeleteTypeConfirmationModal from "./DeleteTypeConfirmationModal";
import { workScheduleApi } from "@api";

interface IProps {
    handleCloseNew: (resetForm: any) => void;
    handleOnCreateSchedule?: (values: any, resetForm: any) => void;
    handleDeleteWorkType?: (values: any) => void;
    data?: any;
    listContructions?: any;
}

export const UpdateTypeForm = (props: IProps) => {
    const { data, handleDeleteWorkType, handleCloseNew } = props;
    const formattedDate = new Date().toISOString().slice(0, 10);

    //component states
    const [detailData, setDetailData] = React.useState<any>(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);
    const initialValuesPackage = {
        contractions: "",
        constructors: [],
        listConstractors: [],
        workType: [
            {
                type: 3,
                work_type_id: [],
                work_type_date: [
                    {
                        start_date: formattedDate,
                        end_date: formattedDate,
                    },
                ],
            },
        ],
    };

    const listContractors = detailData?.work_schedules?.find((schedule) => schedule?.id === data?.work_schedule_id)?.work_schedule_work_type?.find((workType) => workType?.id === data?.id)?.contractors;
    const fetchGetWorkDetailApi = async (workScheduleId) => {
        try {
            const response = await workScheduleApi.getWorkDetail({
                workScheduleId,
            });
            setDetailData(response?.data?.data);
        } catch (err: any) {
            setDetailData(null);
        }
    };

    React.useEffect(() => {
        fetchGetWorkDetailApi(data?.work_schedule_id);
        return () => {
            // clean up
        };
    }, [data?.work_schedule_id]);

    return (
        <>
            <StyledNewFormWorkSchedule style={{ overflowX: "unset" }}>
                <Formik
                    initialValues={initialValuesPackage}
                    enableReinitialize
                    onSubmit={() => {
                        return;
                    }}
                    validationSchema={workScheduleTypeForm}
                >
                    {({ resetForm }) => {
                        return (
                            <div className="workSchedule__newForm">
                                <div className="workScheduleNewForm__select">
                                    <p style={{ marginBottom: "8px" }} className="label">
                                        {!data?.work_type?.name ? `工事名` : `工事名: ${data?.work_type?.name}`}
                                    </p>

                                    {Array.isArray(listContractors) && listContractors?.length > 0 ? (
                                        <Stack mt={1} gap={0.25}>
                                            <p className="label">作業会社</p>

                                            <Stack direction="row" gap={0.5}>
                                                {listContractors.map((contractor) => {
                                                    return <Box style={{ width: "fit-content", backgroundColor: "#e6e6e6", borderRadius: 6, margin: 2, padding: 6, fontSize: 14 }}>{contractor?.name}</Box>;
                                                })}
                                            </Stack>
                                        </Stack>
                                    ) : null}
                                </div>

                                <div className="workScheduleNewForm__btn delete">
                                    <Button
                                        onClick={() => {
                                            setIsOpenDeleteModal(true);
                                        }}
                                    >
                                        細別削除
                                    </Button>
                                </div>
                                <div className="workScheduleNewForm__btn cancel">
                                    <Button
                                        onClick={() => {
                                            handleCloseNew(resetForm);
                                        }}
                                    >
                                        キャンセル
                                    </Button>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </StyledNewFormWorkSchedule>

            {isOpenDeleteModal ? (
                <DeleteTypeConfirmationModal
                    open
                    handleClose={() => {
                        setIsOpenDeleteModal(false);
                    }}
                    action={
                        <Stack direction="row" gap={1} mt={2}>
                            <Button
                                sx={{
                                    fontSize: 16,
                                    width: "100%",
                                    padding: "10px !important",
                                    backgroundColor: "#fff !important",
                                    borderRadius: "8px !important",
                                    border: "1px solid #fa5c5c !important",
                                    color: "#fa5c5c !important",
                                    "&:hover": {
                                        backgroundColor: "#ffe5e5 !important",
                                    },
                                }}
                                variant="outlined"
                                onClick={() => {
                                    handleDeleteWorkType?.(data?.id);
                                }}
                            >
                                削除
                            </Button>
                            <Button
                                sx={{
                                    fontSize: 16,
                                    width: "100%",
                                    padding: "10px !important",
                                    backgroundColor: "#dedede !important",
                                    borderRadius: "8px !important",
                                    border: "none",
                                    color: "#656565 !important",
                                }}
                                onClick={() => {
                                    setIsOpenDeleteModal(false);
                                }}
                            >
                                キャンセル
                            </Button>
                        </Stack>
                    }
                />
            ) : null}
        </>
    );
};
