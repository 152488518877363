import { OVERTIME_TYPE_VALUE } from "@enums";
// import { Divider } from "@mui/material";
// import React from "react";

interface IProps {
  data?: any;
}
const OTDetailDayAndNight = (props: IProps) => {
  const { data } = props;
  return (
    <div>
      {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT && (
        <div className="user__contents">
          {/* <Divider /> */}
          <h3 className="user__title">日勤</h3>
        </div>
      )}
      {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY && (
        <div className="user__contents">
          {/* <Divider /> */}
          <h3 className="user__title">夜勤</h3>
        </div>
      )}
      {data?.overtimeData?.constructionId?.name && (
        <div className="user__contents">
          <p className="user__title">工事名</p>
          <p className="user__title">{data?.overtimeData?.constructionId?.name}</p>
        </div>
      )}
      {data?.overtimeData?.contractor.length > 0 && (
        <div className="user__contents">
          <p className="user__title">業者名</p>
          {data?.overtimeData?.contractor?.map((item) => {
            return <p className="user__title">{item?.name}</p>;
          })}
        </div>
      )}
      {data?.overtimeData?.break_time !== undefined && data?.overtimeData?.break_time !== null && (
        <div className="user__contents">
          <p className="user__title">休憩 (hour)</p>
          <p className="user__title">{data?.overtimeData?.break_time}</p>
        </div>
      )}
      {data?.overtimeData?.work_time !== undefined && data?.overtimeData?.work_time !== null && (
        <div className="user__contents">
          <p className="user__title">稼働時間 (hour)</p>
          <p className="user__title">{data?.overtimeData?.work_time}</p>
        </div>
      )}
      {data?.overtimeData?.work_detail && (
        <div className="user__contents">
          <p className="user__title">業務内容</p>
          <p className="user__title">{data?.overtimeData?.work_detail}</p>
        </div>
      )}
      {data?.overtimeData?.start_at && data?.overtimeData?.stop_at && (
        <div>
          <div className="user__contents">
            <p className="user__title">開始時間</p>
            <p className="user__title">{data?.overtimeData?.start_at}</p>
          </div>
          <div className="user__contents">
            <p className="user__title">終了時間</p>
            <p className="user__title">{data?.overtimeData?.stop_at}</p>
          </div>
        </div>
      )}
      {data?.overtimeData?.memo && (
        <div className="user__contents">
          <p className="user__title">備考</p>
          <p className="user__title">{data?.overtimeData?.memo}</p>
        </div>
      )}
      {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT && (
        <div className="user__contents">
          {/* <Divider /> */}
          <h3 className="user__title">夜勤</h3>
        </div>
      )}
      {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY && (
        <div className="user__contents">
          {/* <Divider /> */}
          <h3 className="user__title">日勤</h3>
        </div>
      )}
      <div>
        {data?.overtimeData?.constructionIdExtra?.name && (
          <div className="user__contents">
            <p className="user__title">工事名</p>
            <p className="user__title">
              {data?.overtimeData?.constructionIdExtra?.name}
            </p>
          </div>
        )}
        {data?.overtimeData?.contractorextra.length > 0 && (
          <div className="user__contents">
            <p className="user__title">業者名</p>
            {data?.overtimeData?.contractorextra?.map((item) => {
              return <p className="user__title">{item?.name}</p>;
            })}
          </div>
        )}
      </div>
      {data?.overtimeData?.break_time_extra !== undefined && data?.overtimeData?.break_time_extra !== null && (
        <div className="user__contents">
          <p className="user__title">休憩 (hour)</p>
          <p className="user__title">{data?.overtimeData?.break_time_extra}</p>
        </div>
      )}
      {data?.overtimeData?.work_time_extra !== undefined && data?.overtimeData?.work_time_extra !== null && (
        <div className="user__contents">
          <p className="user__title">稼働時間 (hour)</p>
          <p className="user__title">{data?.overtimeData?.work_time_extra}</p>
        </div>
      )}
      {data?.overtimeData?.work_detail_extra && (
        <div className="user__contents">
          <p className="user__title">業務内容</p>
          <p className="user__title">{data?.overtimeData?.work_detail_extra}</p>
        </div>
      )}
      {data?.overtimeData?.start_at_extra && data?.overtimeData?.stop_at_extra && (
        <div>
          <div className="user__contents">
            <p className="user__title">開始時間</p>
            <p className="user__title">{data?.overtimeData?.start_at_extra}</p>
          </div>
          <div className="user__contents">
            <p className="user__title">終了時間</p>
            <p className="content">{data?.overtimeData?.stop_at_extra}</p>
          </div>
        </div>
      )}
      {data?.overtimeData?.memo_extra && (
        <div className="user__contents-last">
          <p className="user__title">備考</p>
          <p className="user__title">{data?.overtimeData?.memo_extra}</p>
        </div>
      )}
    </div>
  );
};

export default OTDetailDayAndNight;
