import styled from "styled-components";

export const StyledAppDatePicker = styled.div`
    .MuiOutlinedInput-adornedEnd {
        font-size: 1.6rem;
        border-radius: 50px;
        background-color: ${(p) => p.theme.colors.white02};
    }
    .MuiOutlinedInput-input {
        /* max-width: 8rem; */
        padding: 1rem 0;
        padding-left: 1rem;
    }
    .MuiFormControl-marginNormal{
        margin: 0 0;
    }
`;
