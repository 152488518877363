export enum appRouteEnums {
    PATH_HOME = "/",
    PATH_PROFILE = "/profile",
    PATH_RETURN_SCHEDULE = "/return-schedule",
    PATH_INVENTORY = "/inventory",
    PATH_INVENTORY_DETAIL = "/inventory/:id",
    PATH_INSTRUMENTS = "/instruments",
    PATH_INSTRUMENTS_CALENDAR = "/instruments/calendar/:id",
    PATH_INSTRUMENTS_DETAIL = "/instruments/:id",
    PATH_DOCUMENT = "/document",
    PATH_TIMESHEET = "/timesheet",
    // create a new timesheet
    PATH_TIMESHEET_NEW = "/timesheet/new",
    PATH_TIMESHEET_NEW_DAYSHIFT = "/timesheet/new/0",
    PATH_TIMESHEET_NEW_NIGHTSHIFT = "/timesheet/new/1",
    PATH_TIMESHEET_NEW_DAYSHIFT_AND_NIGHTSHIFT = "/timesheet/new/2",
    PATH_TIMESHEET_NEW_HOLIDAYWORK = "/timesheet/new/3",
    PATH_TIMESHEET_NEW_PAID_TIME_OFF = "/timesheet/new/4",
    PATH_TIMESHEET_NEW_HALF_DAY_WORK = "/timesheet/new/5",
    PATH_TIMESHEET_NEW_ABSENCE = "/timesheet/new/6",
    PATH_TIMESHEET_NEW_LEGAL_HOLIDAY = "/timesheet/new/7",
    PATH_TIMESHEET_NEW_NIGHTSHIFT_AND_DAYSHIFT = "/timesheet/new/8",
    PATH_TIMESHEET_NEW_TARDINESS = "/timesheet/new/9",
    PATH_TIMESHEET_NEW_EARLY_LEAVE = "/timesheet/new/10",
    // PATH_TIMESHEET_NEW_OVERTIME = "/timesheet/new/8",
    // PATH_TIMESHEET_NEW_BEREAVEMENT_LEAVE = "/timesheet/new/10",
    // detail
    PATH_TIMESHEET_NEW_DETAIL_DAYSHIFT = "/timesheet/detail/0/:id",
    PATH_TIMESHEET_NEW_DETAIL_NIGHTSHIFT = "/timesheet/detail/1/:id",
    PATH_TIMESHEET_NEW_DETAIL_DAYSHIFT_AND_NIGHTSHIFT = "/timesheet/detail/2/:id",
    PATH_TIMESHEET_NEW_DETAIL_HOLIDAYWORK = "/timesheet/detail/3/:id",
    PATH_TIMESHEET_NEW_DETAIL_PAID_TIME_OFF = "/timesheet/detail/4/:id",
    PATH_TIMESHEET_NEW_DETAIL_HALF_DAY_WORK = "/timesheet/detail/5/:id",
    PATH_TIMESHEET_NEW_DETAIL_ABSENCE = "/timesheet/detail/6/:id",
    PATH_TIMESHEET_NEW_DETAIL_LEGAL_HOLIDAY = "/timesheet/detail/7/:id",
    PATH_TIMESHEET_NEW_DETAIL = "/timesheet/detail/:id",
    PATH_TIMESHEET_NEW__DETAIL_NIGHTSHIFT_AND_DAYSHIFT = "/timesheet/detail/8/:id",
    PATH_TIMESHEET_NEW_DETAIL_TARDINESS = "/timesheet/detail/9/:id",
    PATH_TIMESHEET_NEW_DETAIL_EARLY_LEAVE = "/timesheet/detail/10/:id",
    // PATH_TIMESHEET_NEW_DETAIL_OVERTIME = "/timesheet/detail/8/:id",
    // PATH_TIMESHEET_NEW_DETAIL_BEREAVEMENT_LEAVE = "/timesheet/detail/10/:id",
    // edit timesheet
    PATH_TIMESHEET_EDIT_DAYSHIFT = "/timesheet/edit/0/:id",
    PATH_TIMESHEET_EDIT_NIGHTSHIFT = "/timesheet/edit/1/:id",
    PATH_TIMESHEET_EDIT_DAYSHIFT_AND_NIGHTSHIFT = "/timesheet/edit/2/:id",
    PATH_TIMESHEET_EDIT_HOLIDAYWORK = "/timesheet/edit/3/:id",
    PATH_TIMESHEET_EDIT_PAID_TIME_OFF = "/timesheet/edit/4/:id",
    PATH_TIMESHEET_EDIT_HALF_DAY_WORK = "/timesheet/edit/5/:id",
    PATH_TIMESHEET_EDIT_ABSENCE = "/timesheet/edit/6/:id",
    PATH_TIMESHEET_EDIT_LEGAL_HOLIDAY = "/timesheet/edit/7/:id",
    PATH_TIMESHEET_NEW__EDIT_NIGHTSHIFT_AND_DAYSHIFT = "/timesheet/edit/8/:id",
    PATH_TIMESHEET_NEW_EDIT_TARDINESS = "/timesheet/edit/9/:id",
    PATH_TIMESHEET_NEW_EDIT_EARLY_LEAVE = "/timesheet/edit/10/:id",
    // PATH_TIMESHEET_NEW_EDIT_OVERTIME = "/timesheet/edit/8/:id",
    // PATH_TIMESHEET_NEW_EDIT_BEREAVEMENT_LEAVE = "/timesheet/edit/10/:id",
    PATH_SCHEDULE = "/schedule",
    PATH_WORK_SCHEDULE = "/work-schedule",
    PATH_WORK_SCHEDULE_LIST_CONSTRUCTION_SITE = "/work-schedule-list",
    PATH_WORK_SCHEDULE_DETAIL = "/work-schedule/:id",
    PATH_DAILY_LOG = "/daily",
    PATH_VEHICLES = "/vehicles",
    PATH_VEHICLES_CALENDAR = "/vehicles/calendar/:id",
    PATH_VEHICLES_DETAIL = "/vehicles/:id",
    PATH_NOTIFICATION = "/notification",
    PATH_OVERTIME = "/overtime",
    PATH_OVERTIME_NEW = "/overtime/new",
    PATH_OVERTIME_EDIT = "/overtime/edit/:id",
    PATH_OVERTIME_DETAIL = "/overtime/:id",

    PATH_LIST_DOCUMENT = "/list-document",
    PATH_WEEKLY_SCHEDULE = "/weekly-schedule",
    // PATH_TIMESHEET_NEW_TARDINESS = "PATH_TIMESHEET_NEW_TARDINESS"

    PATH_ALCOHOL_CHECK = "/alcohol-check",

    PATH_BUSINESS_CARDS = "/business-cards",
}
export enum authRouteEnums {
    PATH_SIGNIN = "/login",
    PATH_FOGOTPASSWORD = "/fogot-password",
    PATH_RESETPASSWORD = "/reset-password",
    PATH_CHANGEPASSORD = "/change-password",
}
export enum screenNameEnums {
    SCREEN_HOME = "home",
    SCREEN_PROFILE = "profile",
    SCREEN_RETURN_SCHEDULE = "return home",
}
export enum menuTitleEnums {
    TITLE_RETURN_SCHEDULE = "使用・予約中",
    TITLE_INVENTORY = "在庫",
    TITLE_VEHICLES = "車両",
    TITLE_DAILY_LOG = "集計",
    TITLE_INSTRUMENTS = "測定機器",
    TITLE_TIMESHEET = "日報",
    TITLE_DOCUMENT = "技術資料",
    TITLE_OVERTIME = "届出申請",
    TITLE_WORK_SCHEDULE = "工程表",
    TITLE_WORK_SCHEDULE_LIST_CONSTRUCTION = "工事物件",
    TITLE_WEEKLY_SCHEDULE = "週間予定表",
    TITLE_ALCOHOL_CHECK = "アルコールチェック"
}
