import React from "react";
import { IconButton } from "@mui/material";

import { StyledInventoryDetailHistory } from ".";
import arrow_increase from "@assets/Icons/arrow_increase.svg";
import arrow_decrease from "@assets/Icons/arrow_decrease.svg";
import { linkAvatar } from "@utils";
import default_avatar from "@assets/Images/default_avatar.jpg";
interface IProps {
  historyList?: any;
}
export const InventoryDetialHistoryComponent = (props: IProps) => {
  const { historyList } = props;
  return (
    <StyledInventoryDetailHistory>
      <div className="title">入出履歴</div>
      <div className="wrapper">
        {historyList &&
          historyList?.map((item, index) => {
            const isBorrowing = item?.isBorrowing;
            return (
              <div className="HistoryInventory" key={index}>
                <div className="wrapperImage">
                  <img
                    className="image"
                    src={
                      item?.user?.avatar?.path
                        ? linkAvatar(item?.user?.avatar?.path)
                        : default_avatar
                    }
                    onError={(e) => {
                      const target = e.currentTarget as HTMLImageElement;
                      if (target.src !== linkAvatar(item?.user?.avatar?.path, 2)) {
                        target.src = linkAvatar(item?.user?.avatar?.path, 2);
                      }
                    }}
                    alt="inventory history"
                  />
                </div>
                <div className="wrapperContent">
                  <p className="name">
                    {item?.user?.last_name}
                    {item?.user?.first_name}
                  </p>
                  <p className="constructionName">
                    {item && item?.construction_name
                      ? item?.construction_name
                      : item?.construction
                        ? item?.construction?.name
                        : ""}
                  </p>
                  <p className="subTitle">
                    <span className="amount">{makeDateStringFromItem(item)}</span>
                  </p>
                </div>
                <div className="button">
                  <div className="wrapperbtn">
                    <span className="amount">{isBorrowing ? "-" : "+"}{item.quantity}</span>
                    <span className="btn">
                      <IconButton disableRipple={true}>
                        <img src={isBorrowing ? arrow_decrease : arrow_increase} alt="icon" />
                      </IconButton>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </StyledInventoryDetailHistory>
  );
};

function makeDateStringFromItem(item: any) {
  return (item.from || item.to) ? `${item.from}~${item.to}` :
    item.created_at ? item.created_at :
      item.updated_at ? item.updated_at :
        "日付不明";
}
