export interface ICompanyItem {
    id: number;
    name: string;
    note: string;
    color: string;
    created_at: string;
}

export interface IColorItem {
    id: number;
    color: string;
    name: string;
}

export enum EWorkTypeAction {
    SELECT_COMPANY = "SELECT_COMPANY",
    SELECT_DATE_RANGE = "SELECT_DATE_RANGE",
    SELECT_PEOPLE = "SELECT_PEOPLE",
}

export interface IWorkTypeAction {
    selectedId: string;
    action: EWorkTypeAction | "";
    color?: string;
}
