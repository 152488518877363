import { useHistory, useLocation } from "react-router-dom";
import { Avatar, Badge, IconButton } from "@mui/material";
import { BellIcon } from "@assets";
import { useDispatch } from "react-redux";

import { linkImage, theme } from "@utils";
import { StyledAppTopBar } from ".";
import { AppModal } from "..";
import { ModalMenu } from "@components";
// import logo from "@assets/Icons/logo.svg";
import { appRouteEnums } from "@enums";
import { resetMenu, selectMenu, useAppSelector, selectAuth, selectNoti } from "@redux";
// import { GREETING_HELLO, NUMBER_TEXT_USERNAME } from "@configs";
import { StyledStickyHeader } from "src/Components/styles";
// import { HiOutlineClipboardList } from "react-icons/hi";
import BackIcon from "@assets/ProceedButton.svg";

type IProps = {
  screen?: string;
};

const maintinance = false;

export const AppTopBar = (props: IProps) => {
  const { screen } = props;
  // redux state
  const menu = useAppSelector(selectMenu);
  const auth = useAppSelector(selectAuth);
  const noti = useAppSelector(selectNoti);
  const { userInfo } = auth;
  const { show } = menu;
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const hiddenPaths = ["/"];
  // event close modal
  const handleCloseModal = () => {
    dispatch(resetMenu());
  };
  // event open modal
  // const handleOpenModal = () => {
  //   dispatch(setMenu());
  // };
  // get width of screen
  return (
    <StyledStickyHeader>
      <StyledAppTopBar screen={screen}>
        <div className="topbar">
          {/* <Grid container alignItems="center" sx={{ justifyContent: { xs: "space-between", md: "end" } }}> */}
          {/* <Grid item xs={12} sm={1} md={1} container justifyContent="space-between">
              <span className="topbar__logo" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
                <img src={logo} alt="logo" />
              </span>
              <p className="toHome">ホーム画面へ</p>
            </Grid> */}
          {/* <Grid item xs={12} sm={8} md={8}> */}
          {/* <div className="topbar__content"> */}
          {/* <div className="topbar__left">
                  <img className="topbar__left-smallLogo" src={logo} alt="logo" onClick={() => history.push(appRouteEnums.PATH_HOME)} />
                </div> */}

          {/* <div className="topbar__right"> */}
          {!hiddenPaths.includes(location.pathname) &&
            <div className="backIcon-container"
              // onClick={() => history.push(appRouteEnums.PATH_HOME)}
              onClick={() => history.goBack()}
            >
              {/* 現在のURLを判別して戻るURLを調整する必要あり */}
              <img src={BackIcon} alt="back-icon" className="backIcon" />
            </div>
          }

          <div className="topbar__right-btnBell">
            <IconButton onClick={() => history.push(appRouteEnums.PATH_NOTIFICATION)} size="large">
              <Badge
                badgeContent={noti.numberNoti}
                overlap="circular"
                style={{
                  transform: "translate(30px, -20px)",
                  fontSize: "1.4rem",
                }}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: theme.colors.crimson,
                    color: theme.colors.white00,
                  },
                  transform: "translate(30px, -20px)",
                }}
              ></Badge>
              <BellIcon />
            </IconButton>
            {/* </Tooltip> */}
          </div>

          <div className="topbar__userInfo" onClick={() => history.push(appRouteEnums.PATH_PROFILE)}>
            <Avatar src={linkImage(userInfo?.avatar?.path)} />
            {/* <div className="topbar__userInfo-text">
                      <span className="subText" onClick={() => history.push(appRouteEnums.PATH_PROFILE)}>
                        {GREETING_HELLO}
                      </span>
                      <span className="mainText">{ellipsisText(`${userInfo?.last_name}${userInfo?.first_name}`, NUMBER_TEXT_USERNAME)}</span>
                    </div> */}
          </div>
          {/* {screen !== "home" && (
                    <div className="topbar__right-btnMenu">
                      <p className="menuText">メニュー</p>
                      <AppIconButton onClick={handleOpenModal} icon={<MenuIcon />} />
                    </div>
                  )} */}


          {/* <div className="topbar__right-btnBell">
                                    <IconButton
                                        onClick={() =>
                                            history.push(appRouteEnums.PATH_LIST_DOCUMENT)
                                        }
                                        size="large"
                                    >
                                        <Badge
                                            badgeContent={noti.numberNoti}
                                            color="warning"
                                            overlap="circular"
                                            style={{
                                                transform: "translate(30px, -20px)",
                                                fontSize: "1.4rem",
                                            }}
                                        ></Badge>
                                        <HiOutlineClipboardList size={24} />
                                    </IconButton>
                                </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* </Grid> */}
          {/* </Grid> */}
        </div>
        <AppModal open={show} handleClose={handleCloseModal}>
          <ModalMenu />
        </AppModal>
      </StyledAppTopBar>
      <span style={{ display: maintinance ? "flex" : "none", background: "#f88", justifyContent: "center", padding: "1rem", fontSize: "2rem", color: "#800", fontWeight: "bold" }}>このサイトは現在読み取り専用です。追加されたコンテンツは失われる可能性があります。</span>
    </StyledStickyHeader>
  );
};
