import red_dot from "@assets/Icons/Notification_Icons/red_dot.svg";

import { StyledTechnicalDocumentsItem } from ".";
import DocumentIcon from "@assets/documentIcon.png";
import TrashcanIcon from "@assets/trashcanIcon.png";
import moment from "moment";

interface IProps {
  data?: any;
  handleOnSelectTechnicalDocuments: (values: any) => void;
  handleDeleteTechnicalDocuments: () => void;
}
export const TechnicalDocumentsItem = (props: IProps) => {
  const { data, handleOnSelectTechnicalDocuments, handleDeleteTechnicalDocuments } = props;
  const date = data.created_at && moment(data.created_at).format("YYYY-MM-DD");

  return (
    <StyledTechnicalDocumentsItem>
      <div className="noti__item">


        <div
          className="noti__item-content"
          onClick={() =>
            handleOnSelectTechnicalDocuments({
              type: data?.type,
              id: data?.id,
              status: data?.status,
              otID: data?.type?.otID,
              tsID: data?.type?.tsID,
              timesheetType: data?.type?.timesheet_type,
            })
          }
        >
          <div className="noti__item__items">

            <div style={{ display: "flex" }}>
              <div className="noti__item-status">
                {!data.read ? (
                  <span>
                    <img src={red_dot} alt="icon" />
                  </span>
                ) : (
                  ""
                )}
              </div>

              <div className="noti__item-icon">
                {/* {data?.type?.type === NotificationTypeEnums.TYPE_REQUEST_OT && ( */}
                <img src={DocumentIcon} alt="document-icon" className="downloadIcon" />
                {/* <IconButton className="icon__overtime" size="medium" disableRipple>
              <OverTimeIcon />
            </IconButton> */}
                {/* )} */}
              </div>

              <div className="noti__item-title">{data.title}</div>
            </div>

            <img
              onClick={handleDeleteTechnicalDocuments}
              src={TrashcanIcon}
              className="noti__item-deleteIcon"
            />
          </div>



          {/* <div className="noti__item-description">{data.content}</div> */}
        </div>

        <div className="noti__item-description">{date}</div>


      </div>
    </StyledTechnicalDocumentsItem>
  );
};
