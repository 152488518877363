import styled from "styled-components";

export const StyledStickyHeader = styled.div`
    position: fixed;
    left: 380px;  /* Sidebar width on desktop */
    width: calc(100% - 380px);
    top: 0;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        left: 0;
        width: 100%;
    }
`;