import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { StyledTableWrapper } from "./styles";
import { Box, Button, Divider } from "@mui/material";
import { editWorkScheduleForm, getDaysWeeksArrayMoment } from "@utils";
import clsx from "clsx";
import moment from "moment";
import { AppButton, AppInput, AppModal, CircularLoader } from "../Common";
import { Formik } from "formik";
import edit_icon from "@assets/Icons/WorkSchedule/edit_icon.svg";
import red_delete_icon from "@assets/Icons/WorkSchedule/red_delete_icon.svg";
import { workScheduleApi } from "@api";
import { useDispatch } from "react-redux";
import { updateSnackBar, useAppDispatch } from "@redux";
import styled from "styled-components";
import { useWorkScheduleContext } from "./context/WorkScheduleContext";
import { EWorkTypeAction } from "./types";
import { format } from "date-fns";

interface IProps {
    handleItemOnClick?: (values: any) => void;
    handleOpenViewDetail?: (id: any) => void;
    fetchDetailApi?: () => void;
    isViewDetail?: boolean;
    data?: any;
    date?: any;
    selectSubContractor?: any;
    tableStyle?: any;
    collapsedRowIds: number[];
}

interface IWorkDateData {
    id: number;
    parent_id: number;
    work_schedule_id: number;
    type: number;
    list: number[];
}

const workDatesData: Record<number, IWorkDateData> = {};

export const ViewGraphCalendar = (props: IProps) => {
    const { isViewDetail, date, fetchDetailApi, data, tableStyle, collapsedRowIds } = props;
    //console.log("collapsedRowIds");
    //console.log(collapsedRowIds);

    const dispatch = useDispatch();

    // state
    // const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
    const [currentEditedSchedule, setCurrentEditedSchedule] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [detailData, setDetailData] = React.useState<any>(null);
    const [currentDay, setCurrentDay] = React.useState<any>(null);
    const [currentWorkScheduleId, setCurrentWorkScheduleId] = React.useState<any>(null);
    const [currentWorkTypeWorkScheduleId, setCurrentWorkTypeWorkScheduleId] = React.useState<any>(null);
    const [reloadPage, setReloadPage] = React.useState<boolean>(false);

    const [workTypeId, setWorkTypeId] = React.useState<any>();

    React.useEffect(() => {
        if (fetchDetailApi) {
            fetchDetailApi();
        }
    }, [reloadPage]);

    const formatDay = (day: number) => {
        return day < 10 ? `0${day}` : `${day}`;
    };

    const combineDate = (currentDay: number, date: string) => {
        const formattedDay = formatDay(currentDay);
        return `${date}-${formattedDay}`;
    };

    const fetchGetWorkDetailApi = async (workScheduleId, scheduleId) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.getWorkDetail({
                workScheduleId,
            });
            setDetailData(response?.data?.data);
            setWorkTypeId(response?.data?.data?.work_schedules?.find((schedule) => schedule?.id === workScheduleId)?.work_schedule_work_type?.find((workType) => workType?.id === scheduleId)?.work_type?.type);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
        }
    };

    const fetchUpdateWorkScheduleApi = async (values: any, resetForm: any) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.updateWorkSchedule({
                workScheduleWorkTypeId: values.workScheduleWorkTypeId,
                date: values.date,
                numberOfPeople: values.numberOfPeople,
            });
            setLoading(false);
            // handleCloseUpdate();
            setCurrentEditedSchedule(null);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.data,
                })
            );
            // window.location.reload();
            setReloadPage(!reloadPage);
            setCurrentEditedSchedule(null);
            resetForm();
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };

    const initialValuesPackage = {
        workScheduleId: "",
        workTypeId: "",
        date: "",
        numberOfPeople: "",
    };

    const handleOpenEditModal = (workScheduleId, schedule, day) => {
        fetchGetWorkDetailApi(workScheduleId, schedule?.id);
        setCurrentWorkScheduleId(workScheduleId);
        setCurrentEditedSchedule(schedule);
        setCurrentWorkTypeWorkScheduleId(schedule?.id);
        setCurrentDay(day);
    };

    const organizeWorkTypes = (workTypes) => {
        const type1 = workTypes.filter((wt) => wt.work_type.type === 1);
        const type2 = workTypes.filter((wt) => wt.work_type.type === 2);
        const type3 = workTypes.filter((wt) => wt.work_type.type === 3);

        const organized = type1.map((t1) => {
            const childrenType2 = type2
                .filter((t2) => t2.parent_id === t1.id)
                .map((t2) => {
                    const childrenType3 = type3.filter((t3) => t3.parent_id === t2.id);
                    return { ...t2, children: childrenType3 };
                });
            return { ...t1, children: childrenType2 };
        });

        return organized;
    };

    const handleOnDeleteWorkSchedule = async (values: any) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.deleteDaySchedule({
                workScheduleWorkTypeId: values.workScheduleWorkTypeId,
                date: values.date,
                workType: values.currentWorkTypeWorkScheduleId,
            });
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.data,
                })
            );
            // window.location.reload();
            setCurrentEditedSchedule(null);
            setReloadPage(!reloadPage);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };

    const [currentNode, setCurrentNode] = useState(0);

    return (
        <StyledTableWrapper>
            <table className="table" style={tableStyle}>
                <tr className="table__header">
                    {getDaysWeeksArrayMoment(date).map((day, index) => (
                        <td key={index} className={clsx(day.dayInWeek === "sun" || day.dayInWeek === "sat" ? "dayOff" : "")}>
                            {day.dayNumber}
                        </td>
                    ))}
                </tr>

                {!isViewDetail &&
                    !!data &&
                    data?.work_schedules?.map((schedule) => {
                        return organizeWorkTypes(schedule?.work_schedule_work_type).map((type1) => {
                            const type1Colors = Array(32).fill("");
                            const aggregatedType1WorkDates = Array(32).fill(0);
                            const type1WorkDates = Array(32).fill(0);

                            type1?.work_type_date?.forEach((it) => {
                                const start = Number(format(new Date(it?.start_date), "dd"));
                                const end = Number(format(new Date(it?.end_date), "dd"));

                                for (let i = start; i <= end; i++) {
                                    if (it?.number_of_people || it?.color) type1WorkDates[i] = 1;

                                    if (!it?.color) {
                                        type1WorkDates[i] = 0;
                                        type1Colors[i] = "#eeeeee";
                                        continue;
                                    }

                                    type1Colors[i] = it?.color;
                                }
                            });

                            workDatesData[type1.id] = {
                                id: type1.id,
                                parent_id: type1.parent_id,
                                work_schedule_id: type1.work_schedule_id,
                                type: 1,
                                list: ([] as number[]).concat(type1WorkDates),
                            };

                            return (
                                <>
                                    <TableRowCustomStyled className="table__body table-row-customized" key={`Type${type1?.id}`}>
                                        {(() => {
                                            const cols = [] as any;
                                            for (let i = 1; i <= getDaysWeeksArrayMoment(date).length; i++) {
                                                let isOpenForm = true;

                                                // const currentDate = i < 10 ? "0" + i : "" + i;
                                                const currentFullDate = moment(date).date(i).format("YYYY-MM-DD");
                                                const workType1Date = type1?.work_type_date;

                                                let totalNumberOfPeopleType1 = 0;
                                                type1.children.forEach((type2) => {
                                                    const workType2Date = type2?.work_type_date;
                                                    const listEvent2 = workType2Date.filter((event) => {
                                                        return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                    });

                                                    let newestEntry2;
                                                    newestEntry2 = newestEntry2 || {};
                                                    if (listEvent2?.length > 0) {
                                                        newestEntry2 = listEvent2.reduce((prev, current) => {
                                                            return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                        });
                                                    }

                                                    if (newestEntry2?.number_of_people > 0) {
                                                        isOpenForm = false;
                                                    }

                                                    if (type2.children?.length === 0) {
                                                        totalNumberOfPeopleType1 += newestEntry2?.number_of_people || 0;
                                                    } else {
                                                        let numType2 = 0;
                                                        type2.children?.forEach((type3) => {
                                                            const workType3Date = type3?.work_type_date;
                                                            const listEvent3 = workType3Date.filter((event) => {
                                                                return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                            });

                                                            let newestEntry3;
                                                            if (listEvent3?.length > 0) {
                                                                newestEntry3 = listEvent3.reduce((prev, current) => {
                                                                    return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                                });
                                                            }
                                                            if (newestEntry3?.number_of_people !== 0) {
                                                                numType2 += newestEntry3?.number_of_people || 0;
                                                            }
                                                        });
                                                        totalNumberOfPeopleType1 += numType2 || 0;
                                                    }
                                                });

                                                const listEvent = workType1Date?.filter((event) => {
                                                    return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                });
                                                let newestEntry;
                                                if (listEvent.length > 0) {
                                                    newestEntry = listEvent.reduce((prev, current) => {
                                                        return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                    });
                                                }

                                                if (totalNumberOfPeopleType1 > 0 || (listEvent.length > 0 && newestEntry && newestEntry?.number_of_people > 0)) {
                                                    const displayNumberOfPeople = totalNumberOfPeopleType1 > 0 ? totalNumberOfPeopleType1 : newestEntry?.number_of_people;
                                                    const formattedDisplayNumberOfPeople =
                                                        Number(displayNumberOfPeople) === displayNumberOfPeople && displayNumberOfPeople.toString().includes(".") && displayNumberOfPeople.toString().split(".")[1].length > 2
                                                            ? displayNumberOfPeople.toFixed(2)
                                                            : displayNumberOfPeople;
                                                    cols.push(
                                                        <CalendarNode
                                                            onClick={() => {
                                                                if (isOpenForm) {
                                                                    handleOpenEditModal(schedule?.id, type1, i);
                                                                }
                                                            }}
                                                            isOpenForm={isOpenForm}
                                                            numberOfPeople={formattedDisplayNumberOfPeople}
                                                            hasNumberOfPeople
                                                            key={i}
                                                            index={i}
                                                            data={type1}
                                                            setCurrentNode={setCurrentNode}
                                                            currentNode={currentNode}
                                                            listColor={type1Colors}
                                                            workDates={workDatesData}
                                                        />
                                                    );
                                                } else {
                                                    cols.push(<CalendarNode key={i} data={type1} index={i} setCurrentNode={setCurrentNode} listColor={type1Colors} currentNode={currentNode} workDates={workDatesData} />);
                                                }
                                            }
                                            return cols;
                                        })()}
                                    </TableRowCustomStyled>

                                    {(() => {
                                        const trs: any = [];

                                        type1.children.forEach((type2) => {
                                            const isCollapsed = collapsedRowIds.includes(type1.id);
                                            //console.log("type2 id: ", type2.id);
                                            const type2WorkDates = Array(32).fill(0);
                                            const aggregatedType2WorkDates = Array(32).fill(0);
                                            const type2Colors = Array(32).fill("");

                                            type2?.work_type_date?.forEach((it) => {
                                                const start = Number(format(new Date(it?.start_date), "dd"));
                                                const end = Number(format(new Date(it?.end_date), "dd"));

                                                for (let i = start; i <= end; i++) {
                                                    if (it?.number_of_people || it?.color) {
                                                        type2WorkDates[i] = 2;
                                                    }

                                                    if (!it?.color) {
                                                        type2WorkDates[i] = 0;
                                                        type2Colors[i] = "#eeeeee";
                                                        type1Colors[i] = "#eeeeee";
                                                        continue;
                                                    }

                                                    type2Colors[i] = it?.color;
                                                }
                                            });

                                            workDatesData[type2.id] = {
                                                id: type2.id,
                                                parent_id: type2.parent_id,
                                                work_schedule_id: type2.work_schedule_id,
                                                type: 2,
                                                list: ([] as number[]).concat(type2WorkDates),
                                            };

                                            const newTr = (
                                                <TableRowCustomStyled className="table__body table-row-customized level-2" key={`Classification${type2?.id}`} style={{ display: isCollapsed ? "none" : undefined }}>
                                                    {(() => {
                                                        const cols = [] as any;
                                                        for (let i = 1; i <= getDaysWeeksArrayMoment(date).length; i++) {
                                                            {
                                                                let isOpenType2Form = true;
                                                                // const currentDate = i < 10 ? "0" + i : "" + i;
                                                                const currentFullDate = moment(date).date(i).format("YYYY-MM-DD");
                                                                const workType2Date = type2?.work_type_date;
                                                                const listEvent = workType2Date.filter((event) => {
                                                                    return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                                });
                                                                let totalNumberOfPeopleType2 = 0;

                                                                let newestEntry2;
                                                                if (listEvent.length > 0) {
                                                                    newestEntry2 = listEvent.reduce((prev, current) => {
                                                                        return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                                    });
                                                                }

                                                                if (type2.children?.length === 0) {
                                                                    totalNumberOfPeopleType2 += newestEntry2?.number_of_people || 0;
                                                                } else {
                                                                    type2.children?.forEach((type3) => {
                                                                        const workType3Date = type3?.work_type_date;
                                                                        const listEvent3 = workType3Date.filter((event) => {
                                                                            return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                                        });

                                                                        let newestEntry3;
                                                                        if (listEvent3?.length > 0) {
                                                                            newestEntry3 = listEvent3.reduce((prev, current) => {
                                                                                return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                                            });
                                                                        }

                                                                        if (newestEntry3?.number_of_people > 0) {
                                                                            isOpenType2Form = false;
                                                                        }
                                                                        totalNumberOfPeopleType2 = totalNumberOfPeopleType2 + (newestEntry3?.number_of_people || 0);
                                                                    });
                                                                }

                                                                if (totalNumberOfPeopleType2 > 0 || (listEvent.length > 0 && newestEntry2 && newestEntry2?.number_of_people > 0)) {
                                                                    const displayNumberOfPeople = totalNumberOfPeopleType2 > 0 ? totalNumberOfPeopleType2 : newestEntry2?.number_of_people;
                                                                    const formattedDisplayNumberOfPeople =
                                                                        Number(displayNumberOfPeople) === displayNumberOfPeople && displayNumberOfPeople.toString().includes(".") && displayNumberOfPeople.toString().split(".")[1].length > 2
                                                                            ? displayNumberOfPeople.toFixed(2)
                                                                            : displayNumberOfPeople;
                                                                    cols.push(
                                                                        <CalendarNode
                                                                            onClick={() => {
                                                                                if (isOpenType2Form) {
                                                                                    handleOpenEditModal(schedule?.id, type2, i);
                                                                                }
                                                                            }}
                                                                            numberOfPeople={Number(formattedDisplayNumberOfPeople)}
                                                                            hasNumberOfPeople
                                                                            isOpenForm={isOpenType2Form}
                                                                            data={type2}
                                                                            index={i}
                                                                            setCurrentNode={setCurrentNode}
                                                                            currentNode={currentNode}
                                                                            listColor={type2Colors}
                                                                            workDates={workDatesData}
                                                                        />
                                                                    );
                                                                } else {
                                                                    cols.push(<CalendarNode setCurrentNode={setCurrentNode} data={type2} index={i} listColor={type2Colors} currentNode={currentNode} workDates={workDatesData} />);
                                                                }
                                                            }
                                                        }
                                                        return cols;
                                                    })()}
                                                </TableRowCustomStyled>
                                            );
                                            trs.push(newTr);

                                            {
                                                type2?.children?.forEach((type3) => {
                                                    const isCollapsed = collapsedRowIds.includes(type1.id) || collapsedRowIds.includes(type2.id);
                                                    //console.log("type3 id: ", type3.id);
                                                    const type3WorkDates = Array(32).fill(0);
                                                    const type3Colors = Array(32).fill("");

                                                    type3?.work_type_date?.forEach((it) => {
                                                        const start = Number(format(new Date(it?.start_date), "dd"));
                                                        const end = Number(format(new Date(it?.end_date), "dd"));

                                                        for (let i = start; i <= end; i++) {
                                                            if (it?.number_of_people || it?.color) {
                                                                type3WorkDates[i] = 3;
                                                            }

                                                            if (!it?.color) {
                                                                type3WorkDates[i] = 0;
                                                                type3Colors[i] = "#eeeeee";
                                                                type2Colors[i] = "#eeeeee";
                                                                type1Colors[i] = "#eeeeee";
                                                                continue;
                                                            }
                                                            type3Colors[i] = it?.color;
                                                        }
                                                    });

                                                    workDatesData[type3.id] = {
                                                        id: type3.id,
                                                        parent_id: type3.parent_id,
                                                        work_schedule_id: type3.work_schedule_id,
                                                        type: 3,
                                                        list: ([] as number[]).concat(type3WorkDates),
                                                    };

                                                    const list3 = ([] as number[]).concat(workDatesData[type3.id].list);

                                                    if (list3.some((it) => !!it)) {
                                                        for (let i = 0; i <= 31; i++) {
                                                            if (!type3WorkDates[i] && !aggregatedType2WorkDates[i]) aggregatedType2WorkDates[i] = 0;
                                                            else aggregatedType2WorkDates[i] = 4;
                                                        }
                                                    }

                                                    const newTr = (
                                                        <TableRowCustomStyled className="table__body table-row-customized" key={`Details${type3?.id}`} style={{ display: isCollapsed ? "none" : undefined }}>
                                                            {(() => {
                                                                const cols = [] as any;
                                                                for (let i = 1; i <= getDaysWeeksArrayMoment(date).length; i++) {
                                                                    {
                                                                        // const currentDate = i < 10 ? "0" + i : "" + i;
                                                                        const currentFullDate = moment(date).date(i).format("YYYY-MM-DD");

                                                                        const workType3Date = type3?.work_type_date;
                                                                        const listEvent = workType3Date.filter((event) => {
                                                                            return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                                        });
                                                                        let newestEntry;
                                                                        if (listEvent.length > 0) {
                                                                            newestEntry = listEvent.reduce((prev, current) => {
                                                                                return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                                            });
                                                                        }
                                                                        if (listEvent.length > 0 && newestEntry && newestEntry?.number_of_people > 0) {
                                                                            cols.push(
                                                                                <CalendarNode
                                                                                    onClick={() => {
                                                                                        handleOpenEditModal(schedule?.id, type3, i);
                                                                                    }}
                                                                                    numberOfPeople={newestEntry?.number_of_people}
                                                                                    hasNumberOfPeople
                                                                                    data={type3}
                                                                                    index={i}
                                                                                    setCurrentNode={setCurrentNode}
                                                                                    currentNode={currentNode}
                                                                                    listColor={type3Colors}
                                                                                    workDates={workDatesData}
                                                                                />
                                                                            );
                                                                        } else {
                                                                            cols.push(<CalendarNode data={type3} index={i} setCurrentNode={setCurrentNode} currentNode={currentNode} listColor={type3Colors} workDates={workDatesData} />);
                                                                        }
                                                                    }
                                                                }
                                                                return cols;
                                                            })()}
                                                        </TableRowCustomStyled>
                                                    );
                                                    trs.push(newTr);
                                                });
                                            }

                                            workDatesData[type2.id] = {
                                                ...workDatesData[type2.id],
                                                ...(aggregatedType2WorkDates.some((it) => it === 4) && {
                                                    list: ([] as number[]).concat(aggregatedType2WorkDates),
                                                }),
                                            };
                                            const listWorkDates = ([] as number[]).concat(workDatesData[type2.id].list);

                                            if (listWorkDates.some((it) => !!it)) {
                                                for (let i = 0; i <= 31; i++) {
                                                    if (!listWorkDates[i] && !aggregatedType1WorkDates[i]) aggregatedType1WorkDates[i] = 0;
                                                    else aggregatedType1WorkDates[i] = 4;
                                                }
                                            }
                                        });

                                        workDatesData[type1.id] = {
                                            ...workDatesData[type1.id],
                                            ...(aggregatedType1WorkDates.some((it) => it === 4) && {
                                                list: ([] as number[]).concat(aggregatedType1WorkDates),
                                            }),
                                        };

                                        return trs.map((trItem) => trItem);
                                    })()}
                                </>
                            );
                        });
                    })}
            </table>
            <AppModal open={!!currentEditedSchedule} handleClose={() => setCurrentEditedSchedule(null)} className="edit-modal">
                <div className="edit-modal__container">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Formik
                            initialValues={initialValuesPackage}
                            onSubmit={(values, { resetForm }) => {
                                fetchUpdateWorkScheduleApi(
                                    {
                                        workScheduleWorkTypeId: currentWorkTypeWorkScheduleId,
                                        date: combineDate(currentDay, moment(date).format("YYYY-MM")),
                                        numberOfPeople: values.numberOfPeople,
                                    },
                                    resetForm
                                );
                            }}
                            validationSchema={editWorkScheduleForm}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => {
                                return (
                                    <div className="form__container" style={{ textAlign: "left", width: "100%" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p style={{ fontSize: "1.5rem" }}>工事名(略名)</p>
                                            <p style={{ fontSize: "1.5rem" }}>{detailData?.name}</p>
                                        </div>
                                        <Divider style={{ margin: "2rem 0" }} />
                                        <p style={{ marginBottom: "8px", fontSize: "1.5rem" }}>作業会社 </p>
                                        <div style={{ display: "flex" }}>
                                            {detailData?.work_schedules
                                                ?.find((schedule) => schedule?.id === currentWorkScheduleId)
                                                ?.work_schedule_work_type?.find((workType) => workType?.id === currentWorkTypeWorkScheduleId)
                                                ?.contractors?.map((contractor) => (
                                                    <div style={{ backgroundColor: "#e6e6e6", borderRadius: "2px", margin: "2px", padding: "6px", fontSize: "14px" }}>{contractor?.name}</div>
                                                ))}
                                        </div>
                                        <Divider style={{ margin: "2rem 0" }} />

                                        <p style={{ marginBottom: "8px", fontSize: "1.5rem" }}>人数</p>
                                        <AppInput
                                            value={values.numberOfPeople}
                                            name="numberOfPeople"
                                            handleChange={handleChange("numberOfPeople")}
                                            placeholder="人数"
                                            error={errors.numberOfPeople}
                                            touched={touched.numberOfPeople}
                                            handleBlur={handleBlur("numberOfPeople")}
                                        />
                                        <Divider style={{ margin: "2rem 0" }} />

                                        <div
                                            className="workScheduleNewForm__btn update"
                                            style={{
                                                marginTop: "3rem",
                                            }}
                                        >
                                            <AppButton onClick={handleSubmit} text="アップデート" startIcon={<img src={edit_icon} />} />
                                        </div>

                                        <div
                                            className="workScheduleNewForm__btn delete"
                                            style={{
                                                marginTop: "3rem",
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleOnDeleteWorkSchedule({
                                                        workScheduleWorkTypeId: currentWorkTypeWorkScheduleId,
                                                        date: combineDate(currentDay, moment(date).format("YYYY-MM")),
                                                        workType: workTypeId,
                                                    })
                                                }
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid rgb(250, 92, 92",
                                                    padding: "10px",
                                                    borderRadius: "8px",
                                                    fontSize: "16px",
                                                }}
                                            >
                                                <span className="icon">
                                                    <img src={red_delete_icon} />
                                                </span>
                                                <span className="text">消去</span>
                                            </Button>
                                        </div>

                                        {/* <div
                                            className="workScheduleNewForm__btn delete"
                                            style={{
                                                marginTop: "3rem",
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleOnDeleteWorkSchedule({
                                                        id: currentWorkScheduleId,
                                                    })
                                                }
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid rgb(250, 92, 92",
                                                    padding: "10px",
                                                    borderRadius: "8px",
                                                }}
                                            >
                                                <span className="icon">
                                                    <img src={red_delete_icon} />
                                                </span>
                                                <span className="text">消去</span>
                                            </Button>
                                        </div> */}
                                    </div>
                                );
                            }}
                        </Formik>
                    </Box>
                </div>
            </AppModal>
            <CircularLoader loading={loading} />
        </StyledTableWrapper>
    );
};

const CalendarNode = ({
    onClick,
    numberOfPeople,
    isOpenForm = false,
    hasNumberOfPeople = false,
    data,
    index,
    currentNode,
    setCurrentNode,
    listColor: listColor2,
    workDates,
}: {
    onClick?: () => void;
    numberOfPeople?: number;
    hasNumberOfPeople?: boolean;
    isOpenForm?: boolean;
    data?: any;
    index: number;
    currentNode?: number;
    setCurrentNode?: Dispatch<SetStateAction<number>>;
    listColor: string[];
    workDates?: Record<number, IWorkDateData>;
}) => {
    const listColor = listColor2?.some((it) => it === "#000000") ? listColor2?.map((it) => (it === "#000000" ? it : "#eeeeee")) : listColor2;
    const { workTypeAction, selectedDateRange, dateRangeColors, setSelectedDateRange } = useWorkScheduleContext();
    const dispatch = useAppDispatch();
    const listWorkDatesByWorkTypeId = Array.isArray(workDates?.[data?.id]?.list) && !!workDates?.[data?.id]?.list?.length ? workDates?.[data?.id]?.list : [];

    const canSelectDateRange = !!workTypeAction?.color;

    const handleSelectedDateRange = () => {
        if (Number(selectedDateRange?.length) >= 2) {
            setSelectedDateRange?.([Number(index)]);
            return;
        }
        setSelectedDateRange?.((prev) =>
            ([] as number[])
                .concat(prev)
                .concat(Number(index))
                .sort((a, b) => a - b)
        );
    };
    const isSelectedRow = data?.id === workTypeAction?.selectedId && workTypeAction?.action === EWorkTypeAction.SELECT_DATE_RANGE;

    const isBetweenDateRange = useMemo(() => {
        const currentIndex = Number(index);
        const currentMouseDownNode = Number(currentNode || 0);

        if (!!selectedDateRange?.[0] && !!selectedDateRange?.[1]) {
            return currentIndex >= selectedDateRange?.[0] && currentIndex <= selectedDateRange?.[1];
        }
        if (currentMouseDownNode <= 0) return false;

        const newDateRange = [currentMouseDownNode].concat(!selectedDateRange?.[0] ? [] : selectedDateRange?.[0]).sort((a, b) => a - b);

        return currentIndex >= newDateRange?.[0] && currentIndex <= newDateRange?.[1];
    }, [index, selectedDateRange, currentNode]);

    const dateRangeColor = data?.work_type_date?.find((it) => {
        const start = Number(format(new Date(it?.start_date), "dd"));
        const end = Number(format(new Date(it?.end_date), "dd"));

        return start <= Number(index) && Number(index) <= end;
    });

    if (!hasNumberOfPeople)
        return (
            <CalendarNodeStyled
                onMouseDown={() => {
                    if (!canSelectDateRange) {
                        dispatch(
                            updateSnackBar({
                                messageType: "error",
                                message: "色を選択してください",
                            })
                        );

                        return;
                    }
                    if (!isSelectedRow) return;
                    setSelectedDateRange?.([Number(index)]);
                    setCurrentNode?.(0);
                }}
                onMouseUp={() => {
                    if (!canSelectDateRange) return;

                    handleSelectedDateRange();
                    setCurrentNode?.(0);
                }}
                onMouseOver={() => {
                    if (!canSelectDateRange) return;

                    if (!isSelectedRow) return;
                    setCurrentNode?.(index || 0);
                }}
                className={clsx({
                    "selected-row": isSelectedRow,
                    // "selected-node": isSelectedRow && isBetweenDateRange,
                    // "can-select-date": !listColor?.[index],
                })}
                style={{
                    backgroundColor: listColor?.[index] || dateRangeColor?.color,
                    ...(isSelectedRow && {
                        backgroundColor: dateRangeColors?.[index] || listColor?.[index],
                    }),
                    ...(isSelectedRow &&
                        listColor?.[index] === "#eeeeee" &&
                        !dateRangeColors?.[index] && {
                            backgroundColor: "#ffffff",
                        }),
                    ...(isSelectedRow &&
                        isBetweenDateRange && {
                            backgroundColor: workTypeAction?.color,
                        }),
                    ...(listWorkDatesByWorkTypeId.some((item) => item === 4) &&
                        listWorkDatesByWorkTypeId[index] !== 4 && {
                            backgroundColor: "#eee",
                        }),
                    ...(listWorkDatesByWorkTypeId[index] === 4 && {
                        backgroundColor: "#000",
                    }),
                }}
            ></CalendarNodeStyled>
        );

    return (
        <td
            className="table__event"
            onClick={() => {
                onClick?.();
            }}
            onMouseDown={() => {
                if (!canSelectDateRange) {
                    dispatch(
                        updateSnackBar({
                            messageType: "error",
                            message: "色を選択してください",
                        })
                    );

                    return;
                }
                if (!isSelectedRow) return;
                setSelectedDateRange?.([Number(index)]);
                setCurrentNode?.(0);
            }}
            onMouseUp={() => {
                if (!canSelectDateRange) return;

                handleSelectedDateRange();
                setCurrentNode?.(0);
            }}
            onMouseOver={() => {
                if (!canSelectDateRange) return;

                if (!isSelectedRow) return;
                setCurrentNode?.(index || 0);
            }}
        >
            <div
                className={`${!isOpenForm ? "event" : "event event_pointer"}`}
                style={{
                    color: "#ffffff",
                    height: "30px",
                    ...(!listWorkDatesByWorkTypeId.some((item) => item === 4) && {
                        backgroundColor: listColor?.[index] || dateRangeColor?.color,
                    }),
                    ...(listWorkDatesByWorkTypeId[index] === 4 && {
                        backgroundColor: "#000",
                    }),
                    ...(listWorkDatesByWorkTypeId.some((item) => item === 4) &&
                        listWorkDatesByWorkTypeId[index] !== 4 && {
                            backgroundColor: "#eee",
                        }),
                    ...(isSelectedRow && {
                        backgroundColor: dateRangeColors?.[index] || listColor?.[index],
                    }),
                    ...(isSelectedRow &&
                        isBetweenDateRange && {
                            backgroundColor: workTypeAction?.color,
                        }),
                }}
            >
                {listWorkDatesByWorkTypeId[index] === 4 || listWorkDatesByWorkTypeId[index] === data?.work_type?.type ? (
                    <p
                        className="event__name"
                        style={{
                            textAlign: "center",
                        }}
                    >
                        {numberOfPeople}
                    </p>
                ) : null}
            </div>
        </td>
    );
};

const CalendarNodeStyled = styled.td`
    height: 30px;
    background-color: #eee;

    &.selected-row {
        background-color: #fff;
        &:hover {
            /* background-color: red; */
        }
        &.can-select-date {
            background-color: #ffffff;
        }
    }
    &.selected-node {
        background-color: yellow;
    }
`;

const TableRowCustomStyled = styled.tr`
    &.table-row-customized {
        td {
            min-width: 36px !important;
            height: 38px;
            padding: 0px;
            box-sizing: border-box;
        }
    }
`;
