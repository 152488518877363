import styled from "styled-components";
import inventory_bg from "@assets/Background/inventory_bg.png";

export const StyledNotificationComponent = styled.div`
    padding-bottom: 4rem;
    background-color:${(p) => p.theme.colors.white02};
    // background-repeat: no-repeat;
    min-height: 100vh;
    border-top: 1px solid #f0f0f0;
    // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
    //     background-image: url(${inventory_bg});
    // }
    margin-top:7rem;
    padding-bottom:12rem;
    .top-icon{
        text-align: left;
    }
    .wrapperForm {
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
      @media (max-width: ${(p) => p.theme.breakPoints.breakLarge}) {
      padding: 0 1rem;
      display: block;
      justify-content: none;
    }

    }

    .MuiDivider-root {
        display: none;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display: block;
        }
    }
    .title-text{
    margin-top: 20px;
    margin-left:20px;
    padding-bottom:20px;
    font-size: 40px;
        // font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
        color: ${(p) => p.theme.colors.black01};
      @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
      font-size: 32px;
    }
    }

    .form {
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        padding-left: 0.5rem;

        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            justify-content: center;
            padding-left: 0.5rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding-left: 0.5rem;
            padding-right: 0;
        }

        &__search {
            padding: 1.6rem 0;
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
        }
        &__checkbox {
            // padding: 1.6rem 0;
            width: 100%;
            padding-left: 0.5rem;
            border-top: 1px solid #f0f0f0;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                /* display: flex;
                justify-content: flex-end; */
                border-bottom: 0px solid transparent;
                border-top: 0px solid transparent;
            }
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            width: 50%;
            display: flex;
            justify-content: flex-end;
        }
        &__buttons {
            margin: 1rem 0;
            display: flex;
            gap: 0.5rem;
            width: 100%;
            flex-wrap: wrap;
        }
        .filter-row {
            display: flex;
            align-items: center;
            margin-bottom: 1.5rem;
        }
        .MuiFormGroup-root {
            flex-direction: row;
        }
        .MuiTypography-root {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            font-size: 1.2rem;
            color: #d5d5d5;
        }
        .MuiSvgIcon-root {
            width: 1.5em;
            height: 1.5em;
        }
        .active {
            .MuiTypography-root {
                color: ${(p) => p.theme.colors.darkBlack} !important;
            }
        }
        &__label-group {
            margin-bottom: 0.5rem;
            padding-left: 1rem;
            display: flex;
            align-items: center;
        }
        &__label {
            font-size: 1.4rem;
            min-width: 100px;
            text-align: left;
            margin-right: 1rem;
        }
    }

    .noti {
        display: flex;
        justify-content: center;
        padding-left: 1rem;
        padding-right: 1rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 0;
        }
        &__wrapper {
            width: 100%;
            // @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            //     width: 100%;
            // }
            // @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            //     width: 80rem;
            // }
        }
        &__content {
            width:85%;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: 100%;
            }
        }
    }
    .wrapperLoading {
        display: flex;
        justify-content: center;
        padding-top: 2rem;
    }
    .viewMore {
        text-align: center;
        // padding-top: 1rem;
        // padding-bottom: 4rem;
        cursor: pointer;
        &__text {
            // font-size: 1.6rem;
            // font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            // color: ${(p) => p.theme.colors.secondary};
            // text-decoration: underline;
        }
        &__icon {
            // margin-left: 0.5rem;
            padding-top: 0.5rem;
        }
    }
`;
export const StyledNotificationItem = styled.div`
    .noti__item {
        min-height:10rem;
        margin-bottom:1rem;
        border-radius:6px;
        background-color:${(p)=>p.theme.colors.white00};
        transition: 250ms;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        width:100%;
        text-align: left;
        padding: 1.5rem 1.5rem;
        &:hover {
            background-color: ${(p) => p.theme.colors.blue06};
        }

        &-statusIcon {
            margin-right: 10px;
            display: flex;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
            }
        }

        &-checkbox {
            margin-right: 10px;
            display: flex;
            align-items: center;
            input {
                width: 15px;
                height: 15px;
            }
            display: flex;
            align-items: flex-start;
        }

        &-deleteIcon {
            width: 2rem;
            height: 2rem;
            cursor: pointer;
        }

        &-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &-title {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            display: block;
            display: -webkit-box;
            max-width: 100%;
            margin-bottom: 0.5rem;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-description {
            font-size: 1.2rem;
            display: block;
            display: -webkit-box;
            max-width: 100%;
            line-height: 1.2;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-status {
            // display: flex;
            // align-items: center;
            padding-right: 1rem;
            margin-top:-3px;
        }
        &-approval {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: #ff4c25;
            width:30px;
            height:16px;
            margin-right:1.2rem;
        }
        &-approval span {
            font-size: 11px;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            color: ${(p) => p.theme.colors.white00};
            text-align: center;
            margin-top:-2px;
        }

    }
    .icon {

        // &__vehicle {
        //     background-color: #ffeedf !important;
        // }
        // &__instrument {
        //     background-color: #ffdff8 !important;
        // }
        // &__overtime {
        //     background-color: #d1e9ff !important;
        // }
    }
`;

export const NextButton = styled.div`
padding: 1rem 3rem;
border-radius: 50px;
font-weight: bold;
transition: all 0.3s;
width:20rem;
text-align : center;
font-size : 12px;
cursor: pointer;
background-color: ${(p)=>p.theme.colors.orange};
color: ${(p)=>p.theme.colors.white00};
&:hover {
  // background-color: ${(p)=>p.theme.colors.blue06};
  filter: brightness(1.2);
}
&:focus {
  outline: 2px solid ${(p)=>p.theme.colors.lightgray09};
}
`;

export const StyledToggleButton = styled.div<{ isActive: boolean }>`
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s;
  min-width: 10rem;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  height: 3.6rem;
  background-color: ${(p) => (p.isActive ? p.theme.colors.orange : p.theme.colors.gray03)};
  color: ${(p) => p.theme.colors.white00};

  @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
    width: 100%;
  }
`;

export const StyledToggleButtonShort = styled.div<{ isActive: boolean }>`
  padding: 1rem 1rem;
  border-radius: 100px;
  font-weight: bold;
  transition: all 0.3s;
  min-width: 15rem;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  height:4rem;
  background-color: ${(p) => (p.isActive ? p.theme.colors.orange : p.theme.colors.gray03)};
  color: ${(p) => p.theme.colors.white00};

  @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
    min-width:5rem;
    padding:1rem 2rem;
  }
`;
