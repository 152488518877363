import { Box } from "@mui/system";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { AppTextArea } from "src/Components/Common";
import { TimeSheetHalfDayFormSchemaType } from "../../validation";
import { useTimeSheetContext } from "../../context";
import { Divider } from "@mui/material";
import { StyledEmployeeForm } from "../form-sheet-night-shift/form-livelet-employee";

export interface LiveletEmployeeItemFormWidgetProps {
  nestIndex: number;
  // workType: WorkTypeResponse;
}

export function LiveLetEmployeeItemFormWidget({ nestIndex }: LiveletEmployeeItemFormWidgetProps) {
  const form = useFormContext<TimeSheetHalfDayFormSchemaType>();

  const { employees } = useTimeSheetContext();
  const { control } = form;

  const { fields } = useFieldArray({
    control,
    name: `list_constructions.${nestIndex}.livelet_employee`,
  });

  const watchContractors = useWatch({
    name: `list_constructions.${nestIndex}.livelet_employee`,
  });
  return (
    <StyledEmployeeForm>
      {fields.map((field, k) => {
        const contractorsSelect = watchContractors[k].employee_name;

        const constructorMatch = employees.find(
          (employee) => employee.value === contractorsSelect
        );

        return (
          <div className="employeeDetail" key={field.id}>
            {/* <div className="employeeDetail__contentWithBorder"> */}
            <p className="employeeDetail__name">
              スタッフへの注意事項: {constructorMatch?.label || contractorsSelect}
            </p>

            <label className="label">メモ</label>
            <Controller
              name={`list_constructions.${nestIndex}.livelet_employee.${k}.employee_memo`}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <AppTextArea value={value} handleChange={onChange} />
                  {error && (
                    <p
                      style={{
                        color: "#FF597B",
                        fontSize: "13px",
                        margin: "8px",
                      }}
                    >
                      {error?.message}
                    </p>
                  )}
                </>
              )}
            />

            {/* </div> */}
          </div>
        );
      })}
    </StyledEmployeeForm>
  );
}
