import React from "react";

interface IProps {
  data?: any;
}
const OTDetailHoliday = (props: IProps) => {
  const { data } = props;
  return (
    <div>
      {data?.overtimeData?.constructionId?.name && (
        <div className="user__contents">
          <p className="user__title">工事名</p>
          <p className="user__title">{data?.overtimeData?.constructionId?.name}</p>
        </div>
      )}
      {data?.overtimeData?.contractor.length > 0 && (
        <div className="user__contents">
          <p className="user__title">業者名</p>
          {data?.overtimeData?.contractor?.map((item) => {
            return <p className="user__title">{item?.name}</p>;
          })}
        </div>
      )}
      {data?.overtimeData?.break_time !== undefined && data?.overtimeData?.break_time !== null && (
        <div className="user__contents">
          <p className="user__title">休憩 (hour)</p>
          <p className="user__title">{data?.overtimeData?.break_time}</p>
        </div>
      )}
      {data?.overtimeData?.work_time !== undefined && data?.overtimeData?.work_time !== null && (
        <div className="user__contents">
          <p className="user__title">稼働時間 (hour)</p>
          <p className="user__title">{data?.overtimeData?.work_time}</p>
        </div>
      )}
      {data?.overtimeData?.work_detail && (
        <div className="user__contents">
          <p className="user__title">業務内容</p>
          <p className="user__title">{data?.overtimeData?.work_detail}</p>
        </div>
      )}
      {data?.overtimeData?.start_at && data?.overtimeData?.stop_at && (
        <div>
          <div className="user__contents">
            <p className="user__title">開始時間</p>
            <p className="user__title">{data?.overtimeData?.start_at}</p>
          </div>
          <div className="user__contents">
            <p className="user__title">終了時間</p>
            <p className="user__title">{data?.overtimeData?.stop_at}</p>
          </div>
        </div>
      )}
      {data?.overtimeData?.memo && (
        <div className="user__contents-last">
          <p className="user__title">備考</p>
          <p className="user__title">{data?.overtimeData?.memo}</p>
        </div>
      )}
    </div>
  );
};

export default OTDetailHoliday;
