export enum NotificationTypeEnums{
    TYPE_INSTRUMENT = 1,
    TYPE_VEHICLE=2,
    TYPE_REQUEST_OT=3,
    TYPE_REQUEST_TIMESHEET=4
}
// 種別フィルター用
export enum FilterNotificationTypeEnums {
    TYPE_ALL = 0,
    TYPE_REQUEST_OT = 3,
    TYPE_REQUEST_TIMESHEET = 4,
    TYPE_RESERVATION = 12,
    TYPE_OTHERS = 99,
}
// 種別フィルター表示用
export enum FilterNotificationTypeNameEnums {
    TYPE_ALL_DISPLAY = "全て",
    TYPE_REQUEST_OT_DISPLAY = "申請",
    TYPE_REQUEST_TIMESHEET_DISPLAY = "日報",
    TYPE_RESERVATION_DISPLAY = "予約",
    TYPE_OTHERS_DISPLAY = "その他",
}
export enum NotificationLinkEnums{
    RETURN_VEHICLE = "return-schedule",
    REQUEST_OT = "overtime"
}
export enum FilterNotificationEnums{
    ENUMS_FILTER_ALL = "全て",
    ENUMS_FILTER_UNREAD = "未読",
    ENUMS_FILTER_READ = "読んだ"
}
export enum FilterNotificationStatusEnums{
    ENUMS_STATUS_ALL = 3,
    ENUMS_STATUS_UNREAD = 0,
    ENUMS_STATUS_READ = 1,
}
