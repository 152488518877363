/* eslint-disable no-console */
import { Box } from "@mui/system";
import { useHistory, useParams } from "react-router-dom";

import { timesheetApi } from "@api";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import { appRouteEnums } from "@enums";
import { selectAuth, useAppSelector } from "@redux";
import { converUrlToGetId, formatDate, useFormWithSchema, useShowErrorFromApi } from "@utils";
import queryString from "query-string";
import { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { AppAlert, AppIconButton, AppModal, AppTopBar, CircularLoader, CustomFormProvider, TimeSheetUserInfo } from "../../..";
import { useTimeSheetContext } from "../../context";
import { StyledNewTimeSheetForm } from "../../styles";
import { TimeSheetNightShiftFormSchemaType, timeSheetNightShiftFormSchema } from "../../validation";
import { FormSheetNightShift } from "../../widget";

export function TimeSheetNightShift() {
  const [nameGroup, setNameGroup] = useState<string>("");
  const [idTimeSheetItem, setIdTimeSheetItem] = useState<number | null>(null);
  const [dateUpdate, setDateUpdate] = useState("");
  const [editLoading, setEditLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const history = useHistory();
  const auth = useAppSelector(selectAuth);
  const { userInfo } = auth;
  const defaultDatePicker = queryString.parse(window.location.search)?.date;
  const parsed = queryString.parse(location.search);

  const params: any = useParams();

  const { employees, loading } = useTimeSheetContext();
  const showErrorFromApi = useShowErrorFromApi();

  const fetchListGroupsByUser = async (id: number) => {
    try {
      const result = await timesheetApi.listGroupsByUser(id);
      setNameGroup(result?.data?.data[0].name);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (!userInfo?.id) return;
    fetchListGroupsByUser(userInfo?.id);
  }, []);

  // const form = useFormWithSchema({
  //     schema: timeSheetNightShiftFormSchema,
  //     options: {
  //         defaultValues: {
  //             alcohol_check: false,
  //             check_date: defaultDatePicker
  //                 ? (defaultDatePicker as string)
  //                 : formatDate({ date: new Date(), format: "YYYY-MM-DD" }),
  //             device_check: "TANITAアルコールチェッカーEA-100",
  //         },
  //     },
  // });

  const idSelectType = converUrlToGetId(window.location.href);

  const formCreate = useFormWithSchema({
    schema: timeSheetNightShiftFormSchema.partial(),
    options: {
      defaultValues: {
        alcohol_check: true,
        check_date: defaultDatePicker ? (defaultDatePicker as string) : formatDate({ date: new Date(), format: "YYYY-MM-DD" }),
        device_check: "TANITAアルコールチェッカーEA-100",
      },
    },
  });
  const formUpdate = useFormWithSchema({
    schema: timeSheetNightShiftFormSchema.partial(),
  });

  const {
    reset: resetCreate,
    formState: { isSubmitting },
  } = formCreate;
  const {
    reset,
    formState: { isSubmitting: isSubmittingFormUpdate },
  } = formUpdate;

  // const form: any = params?.id ? formUpdate : formCreate;
  const form: any = parsed?.copy || params?.id ? formUpdate : formCreate;

  useEffect(() => {
    if (employees.length === 0) return;
    const getLastestInfo = async () => {
      try {
        setEditLoading(true);
        const res = await timesheetApi.getLatestInfo();
        const {
          alcohol_default_check,
          inspector_default_check,
          driver_default_check,
          vehicle_default_check,
          device_default_check,
          start_time_check_alcohol,
          end_time_check_alcohol,
          start_capacity_alcohol,
          end_capacity_alcohol,
          driver_id,
          inspector_id,
          vehicle_id,
          device_check,
        } = res.data.data.timesheetItems[0];
        resetCreate({
          device_check: device_default_check === 0 ? "TANITAアルコールチェッカーEA-100" : device_check,
          driver_id: driver_default_check === 0 ? "" : driver_id ? employees.find((employee) => employee.value === driver_id)?.value : "",
          inspector_id: inspector_default_check === 0 ? "" : inspector_id ? employees.find((employee) => employee.value === inspector_id)?.value : "",
          vehicle_id: vehicle_default_check === 0 ? "" : vehicle_id ? vehicle_id : "",
          list_constructions: [],

          start_time_check_alcohol: alcohol_default_check === 0 ? undefined : start_time_check_alcohol,
          end_time_check_alcohol: alcohol_default_check === 0 ? undefined : end_time_check_alcohol,
          start_capacity_alcohol: alcohol_default_check === 0 ? "" : start_capacity_alcohol,
          end_capacity_alcohol: alcohol_default_check === 0 ? "" : end_capacity_alcohol,
          alcohol_default_check: alcohol_default_check === 0 ? false : true,
          inspector_default_check: inspector_default_check === 0 ? false : true,
          driver_default_check: driver_default_check === 0 ? false : true,
          vehicle_default_check: vehicle_default_check === 0 ? false : true,
          device_default_check: device_default_check === 0 ? false : true,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setEditLoading(false);
      }
    };
    getLastestInfo();
  }, [employees, resetCreate]);

  const handleCreateTimeSheetNightShift = async (values: TimeSheetNightShiftFormSchemaType) => {
    const body = {
      // date: defaultDatePicker as string,
      date: values?.check_date,
      type: idSelectType,
      data: [
        {
          item_type: idSelectType === "3" ? "0" : idSelectType,
          ...values,
          alcohol_check: values.alcohol_check ? "1" : "0",
          alcohol_default_check: values.alcohol_default_check ? "1" : "0",
          inspector_default_check: values.inspector_default_check ? "1" : "0",
          driver_default_check: values.driver_default_check ? "1" : "0",
          vehicle_default_check: values.vehicle_default_check ? "1" : "0",
          device_default_check: values.device_default_check ? "1" : "0",
        },
      ],
    };

    try {
      await timesheetApi.addMultipleConstructionSite(body);
      setIsSuccess(true);
      setSuccessMessage("正常に 作成した");
    } catch (error) {
      const err = error as AxiosError<any>;
      showErrorFromApi(err);
    }
  };
  const handleUpdateTimeSheetNightShift = async (values: TimeSheetNightShiftFormSchemaType) => {
    const body = parsed?.copy
      ? {
        type: idSelectType,
        // id: params?.id,
        //   date: defaultDatePicker as string,
        date: values?.check_date,
        data: [
          {
            item_type: idSelectType === "3" ? "0" : idSelectType,
            // id: idTimeSheetItem,
            ...values,
            alcohol_check: values.alcohol_check ? "1" : "0",
            alcohol_default_check: values.alcohol_default_check ? "1" : "0",
            inspector_default_check: values.inspector_default_check ? "1" : "0",
            driver_default_check: values.driver_default_check ? "1" : "0",
            vehicle_default_check: values.vehicle_default_check ? "1" : "0",
            device_default_check: values.device_default_check ? "1" : "0",
          },
        ],
      }
      : {
        type: idSelectType,
        id: params?.id,
        date: dateUpdate,
        data: [
          {
            item_type: idSelectType === "3" ? "0" : idSelectType,
            id: idTimeSheetItem,
            ...values,
            alcohol_check: values.alcohol_check ? "1" : "0",
            alcohol_default_check: values.alcohol_default_check ? "1" : "0",
            inspector_default_check: values.inspector_default_check ? "1" : "0",
            driver_default_check: values.driver_default_check ? "1" : "0",
            vehicle_default_check: values.vehicle_default_check ? "1" : "0",
            device_default_check: values.device_default_check ? "1" : "0",
          },
        ],
      };

    try {
      // await timesheetApi.updateTimesheetDetail(body, params?.id);
      if (parsed?.copy) {
        await timesheetApi.addMultipleConstructionSite(body);
        setIsSuccess(true);
        setSuccessMessage("正常に更新");
      } else {
        await timesheetApi.updateTimesheetDetail(body, params?.id);
        setIsSuccess(true);
        setSuccessMessage("正常に更新");
      }

      // await timesheetApi.updateTimesheetDetail(body, params?.id);
      // setIsSuccess(true), setSuccessMessage("正常に更新");
    } catch (error: any) {
      const err = error as AxiosError<any>;
      showErrorFromApi(err);
    }
  };

  // const onSubmit = params?.id ? handleUpdateTimeSheetNightShift : handleCreateTimeSheetNightShift;
  const onSubmit = parsed?.copy || params?.id ? handleUpdateTimeSheetNightShift : handleCreateTimeSheetNightShift;

  useEffect(() => {
    // if (!params.id || employees.length === 0) return;
    if (employees.length === 0) return;

    const nightShiftDetails = async () => {
      setEditLoading(true);
      try {
        // const res = await timesheetApi.viewTimesheetDetail(params.id);
        const res = (parsed?.copy || params?.id) && (await timesheetApi.viewTimesheetDetail(parsed?.copy ? parsed?.copy : params.id));

        const {
          alcohol_check,
          check_date,
          device_check,
          driver,
          inspector,
          vehicle,
          TimesheetItemConstruction,
          id,
          date,
          start_time_check_alcohol,
          end_time_check_alcohol,
          start_capacity_alcohol,
          end_capacity_alcohol,
          alcohol_default_check,
          inspector_default_check,
          driver_default_check,
          vehicle_default_check,
          device_default_check,
        } = res.data.items[0];
        console.log("res.data.items[0]", res.data.items[0]);
        const nightShiftValuesForm: TimeSheetNightShiftFormSchemaType = {
          alcohol_check: alcohol_check === 0 ? false : true,
          check_date: parsed.date ? parsed.date : check_date,
          // check_date,
          device_check,
          driver_id: driver ? employees.find((employee) => employee.value === driver?.id)?.value : "",
          inspector_id: inspector ? employees.find((employee) => employee.value === inspector?.id)?.value : "",
          vehicle_id: vehicle?.id ? vehicle?.id : "",
          list_constructions: TimesheetItemConstruction.map((construction) => ({
            break_time: construction.break_time,
            memo: construction.memo || "",
            construction_id: construction.construction_id,
            start_at: construction.start_at,
            listConstruction: construction.construction,
            stop_at: construction.stop_at,
            weather_id: construction.weather_id.toString(),
            contractors: construction.contractor_timesheet_item.map((contractorItem) => ({
              contractor_id: contractorItem.contractor_id,
              number_of_people: Number(contractorItem.number_of_people),
              work_type_1: contractorItem.work_types.filter((workType) => workType.type === 1).map((workType) => String(workType.id)),
              work_type_2: contractorItem.work_types.filter((workType) => workType.type === 2).map((workType) => String(workType.id)),
              work_type_3: contractorItem.work_types.filter((workType) => workType.type === 3).map((workType) => String(workType.id)),
            })),
            livelet_employee: construction?.employee_timesheet_item?.map((liveletEmployee) => ({
              employee_id: liveletEmployee.user_id,
              employee_name: liveletEmployee.user_id === 0 ? liveletEmployee.user_name : `${liveletEmployee.user.last_name} ${liveletEmployee.user.first_name}`,
              employee_memo: liveletEmployee.memo,
            })),
          })),
          // item_type: 1,
          start_time_check_alcohol: start_time_check_alcohol,
          end_time_check_alcohol: end_time_check_alcohol,
          start_capacity_alcohol: start_capacity_alcohol,
          end_capacity_alcohol: end_capacity_alcohol,
          alcohol_default_check: alcohol_default_check === 0 ? false : true,
          inspector_default_check: inspector_default_check === 0 ? false : true,
          driver_default_check: driver_default_check === 0 ? false : true,
          vehicle_default_check: vehicle_default_check === 0 ? false : true,
          device_default_check: device_default_check === 0 ? false : true,
        };
        setDateUpdate(date);
        setIdTimeSheetItem(id);
        reset(nightShiftValuesForm);
      } catch (error) {
        console.log(error);
      } finally {
        setEditLoading(false);
      }
    };
    nightShiftDetails();
  }, [employees, reset, params?.id, parsed?.id]);

  const handleCloseModal = () => {
    setIsSuccess(false);
    history.push(appRouteEnums.PATH_TIMESHEET);
  };

  return (
    <StyledNewTimeSheetForm>
      {/* <AppTopBar /> */}
      <CircularLoader loading={loading || editLoading || isSubmitting || isSubmittingFormUpdate} />
      <AppModal open={isSuccess} handleClose={handleCloseModal}>
        <AppAlert icon={<img src={hot_yellow_frame} alt="icon" />} text={successMessage} handleClose={handleCloseModal} />
      </AppModal>
      <div className="detail">
        <div className="detail__top">
          {/* <p className="detail__top-icon" onClick={() => history.goBack()}>
            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
          </p> */}
          <p className="detail__top-title">日報</p>
        </div>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
        <div className="detail__content">
          <div className="detail__contentTop">
            <TimeSheetUserInfo
              last_name={userInfo?.last_name}
              first_name={userInfo?.first_name}
              type={idSelectType}
            // date={new Date()}
            // handleChange={handleChangeDate}
            // created_at={data?.created_at}
            />
            <p className="detail__name">{userInfo?.last_name} {userInfo?.first_name}</p>
          </div>
          <div className="boxWrapper">
            {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              > */}
            <div className="detail__group">
              <p className="label">グループ</p>

              <p className="main">{nameGroup && nameGroup}</p>
            </div>
            {/* </Box> */}
          </div>
          <CustomFormProvider form={form} onSubmit={onSubmit}>
            <FormSheetNightShift />
          </CustomFormProvider>
        </div>
        {/* </Box> */}
      </div>
    </StyledNewTimeSheetForm>
  );
}
