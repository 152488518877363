import styled from "styled-components";

export const StyleAppTimeRangePicker = styled.div`
  min-width:20rem;
    .MuiOutlinedInput-root {
        border-radius: 50px;
        background-color:${(p) => p.theme.colors.white02};
    }
    .MuiOutlinedInput-input {
        font-size: 1.4rem;
        padding: 1.2rem;
    }
    .MuiSvgIcon-root {
        width: 1.5em;
        height: 1.5em;
    }
    .error{
        color: red;
        font-size: 12px;
    }
`;
