import { Grid, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import React from "react";
import { encode, decode } from "js-base64";

import { StyledSignIn } from ".";
import "../../App.css";
import background_signin from "@assets/Images/background_signin.png";
import logo from "@assets/MoreDoLogo.svg";
import Xitem from "@assets/Icons/X_Item_Icon.svg";
import { AppButton, AppInput, CircularLoader, PassChangeButton } from "@components";
import { loginSchema } from "@utils";
import { authRouteEnums, appRouteEnums } from "@enums";
import { EyeCloseIcon, EyeIcon, LockIcon } from "@assets";
import { login, selectAuth, useAppSelector, setRemember, setRememberMe } from "@redux";

export const SignIn = () => {
  // redux store
  const auth = useAppSelector(selectAuth);
  const { isLoading, tokenInfoAuth, isRemember, emailRemember, passwordRemember } = auth;
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    if (tokenInfoAuth) {
      history.push(appRouteEnums.PATH_HOME);
    }
  }, [auth]);
  React.useEffect(() => {
    if (!isRemember) {
      dispatch(
        setRememberMe({
          email: "",
          password: "",
        })
      );
    }
  });
  const initialValuesPackage = {
    email: emailRemember || "",
    password: (passwordRemember && decode(passwordRemember)) || "",
  };
  // events
  const handleChangeRemember = () => {
    dispatch(setRemember(!isRemember));
  };

  return (
    <StyledSignIn>
      <CircularLoader loading={isLoading} />
      <div className="login">
        <div className="login__bigContainer">
          <img src={logo} className="login__logo" />
          <div className="login__middleContainer" style={{ textAlign: "left" }}>
            <h3 className="login__title">ログイン</h3>
            <Formik
              initialValues={initialValuesPackage}
              onSubmit={(values) => {
                if (isRemember) {
                  dispatch(
                    setRememberMe({
                      email: values.email,
                      password: encode(values.password),
                    })
                  );
                }
                dispatch(
                  login({
                    dispatch,
                    values,
                  })
                );
              }}
              validationSchema={loginSchema}
            >
              {({ handleSubmit, values, handleChange, errors, touched }) => {
                const [showPassword, setShowPassword] = React.useState(false);

                return (
                  <div className="login__smallContainer">
                    <div className="login__input">
                      <p style={{ textAlign: "left" }}>メールアドレス</p>
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange("email")}
                        className="authInput"
                        placeholder="メールアドレス"
                      />
                      <IconButton
                        style={{opacity: 0}}
                        className="eye-icon"
                      >
                        <EyeIcon />
                      </IconButton>
                      {touched.email && errors.email && <div className="error">{errors.email}</div>}
                    </div>
                    <div className="login__input">
                      <p style={{ textAlign: "left" }}>パスワード</p>
                      <div style={{display: "flex", alignItems: "center", position: "relative"}}>
                        <div style={{position: "absolute", left: "1rem", display: "flex", alignItems: "center"}}><LockIcon /></div>
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={values.password}
                          onChange={handleChange("password")}
                          className="authInput"
                          placeholder="パスワード"
                          style={{paddingLeft: "4rem"}}
                        />
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          className="eye-icon"
                        >
                          {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
                        </IconButton>
                      </div>
                      {touched.password && errors.password && <div className="error">{errors.password}</div>}
                    </div>
                    <div className="login__check">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isRemember}
                            onChange={handleChangeRemember}
                          />
                        }
                        label="ログイン状態を保持する"
                      />
                    </div>
                    <div className="login__button">
                      <PassChangeButton onClick={() => handleSubmit()}>
                        ログイン
                      </PassChangeButton>
                    </div>
                  </div>
                );
              }}
            </Formik>
            <p className="login__forgot" style={{ textAlign: "left" }}>
              <span
                onClick={() => history.push(authRouteEnums.PATH_FOGOTPASSWORD)}
              >
                パスワードをお忘れですか ?
              </span>
            </p>
          </div>
        </div>
      </div>
    </StyledSignIn>
  );
};
