import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";

import { StyledModalMenuComponent } from "./styles";
import { linkImage } from "@utils";
import { useAppSelector, selectMenu, getMenus, selectAuth, selectVehicle, /*selectGroupsLoading,*/ selectTimesheetsLoading, selectDocumentsLoading, /*selectEmployeesLoading*/ selectOvertimeLoading } from "@redux";
import { useHistory } from "react-router-dom";
import { selectWeeklyScheduleLoading } from "src/Redux/Slices/weeklySchedule";
import { appRouteEnums } from "@enums";
import logo from "@assets/MoreDoLogo.svg";
import Alcohol from "@assets/menuicons/alcohol.png";
import Analysis from "@assets/menuicons/analysis.png";
import Document from "@assets/menuicons/document.png";
import Instrument from "@assets/menuicons/instrument.png";
import Inventory from "@assets/menuicons/inventory.png";
import Notification from "@assets/menuicons/notification.png";
import Overtime from "@assets/menuicons/overtime.png";
import Process from "@assets/menuicons/process.png";
import Return from "@assets/menuicons/return.png";
import Schedule from "@assets/menuicons/schedule.png";
import Timesheet from "@assets/menuicons/timesheet.png";
import Vehicle from "@assets/menuicons/vehicle.png";
import BluePrint from "@assets/Blueprint.png";


export const MenuComponent = () => {
  const menus = useAppSelector(selectMenu);
  const { dataMenu } = menus;
  const auth = useAppSelector(selectAuth) || { tokenInfoAuth: false };
  const { tokenInfoAuth } = auth;
  const vehicleState = useAppSelector(selectVehicle);
  const vehiclesLoading = vehicleState.ongoingRequests?.length > 0;

  //const groupsLoading = useAppSelector(selectGroupsLoading);
  const timesheetsLoadingState = useAppSelector(selectTimesheetsLoading);
  const documentsLoading = useAppSelector(selectDocumentsLoading);
  const overtimesLoading = useAppSelector(selectOvertimeLoading);
  const weeklyScheduleLoading = useAppSelector(selectWeeklyScheduleLoading);
  //const employeesLoading = useAppSelector(selectEmployeesLoading);

  //const timesheetsLoading = groupsLoading || timesheetsLoadingState || employeesLoading;

  // hooks
  const dispatch = useDispatch();
  const history = useHistory();

  // event choose item in menu
  const handleOnClick = (link: string) => {
    // window.location.href = link;
    history.push(link);
  };
  React.useEffect(() => {
    if (tokenInfoAuth) {
      dispatch(getMenus({ dispatch }));
    }
  }, [tokenInfoAuth, dispatch]);

  const menuIcons: Record<string, string> = {
    "タイムシート": Timesheet,
    "届出・申請": Overtime,
    "最新の在庫状況": Inventory,
    "車両の予約": Vehicle,
    "機器の予約": Instrument,
    "変更・返却": Return,
    "物件・工程": Process,
    "集計": Analysis,
    "資料・文献": Document,
    "施設予約": Schedule,
    "個人の通知": Notification,
    "アルコールチェック状況検索": Alcohol,
  };

  return (
    <StyledModalMenuComponent>
      <img src={BluePrint} className="blueprint" />
      <span className="menu__logo" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
        <img src={logo} alt="logo" />
      </span>
      {dataMenu ? (
        <div className="menu__wrapper">
          {dataMenu ? (
            dataMenu.map((menu: any) => {
              const loading = (menu.id === 8 && vehiclesLoading) || (menu.id === 7 && timesheetsLoadingState) || (menu.id === 4 && documentsLoading) || (menu.id === 9 && overtimesLoading) || (menu.id === 11 && weeklyScheduleLoading);
              const linkUrl = new URL(menu.link);
              const localLink = linkUrl.pathname + linkUrl.search + linkUrl.hash;
              const spinner = loading ? (<CircularProgress size={"1em"} />) : null;
              return (
                <div
                  key={menu.id}
                  className="menu__item"
                  onClick={() => handleOnClick(localLink)}
                >
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <div className="menu__wrapperImage">
                      <img
                        className="menu__image"
                        src={menuIcons[menu?.name] || linkImage(menu?.icon?.path)}
                      />
                    </div>
                  </Box>
                  <p className="menu__title">{menu.name} {spinner}</p>
                </div>
              );
            })
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <CircularProgress />
            </Box>
          )}
        </div>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <CircularProgress />
        </Box>
      )}
    </StyledModalMenuComponent>
  );
};
