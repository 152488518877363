import { Formik } from "formik";
import React from "react";
import { Button } from "@mui/material";

import { PlusIcon } from "@assets";
import { StyledNewFormWorkSchedule } from "..";
import { AppButton, AppCustomeSelect } from "../..";
import { workScheduleApi } from "@api";
import { workScheduleAddForm } from "@utils";
//import useContractors from "src/Utils/Hooks/useContractors";

interface IProps {
    handleCloseNew: (resetForm: any) => void;
    handleOnCreateSchedule: (values: any, resetForm: any) => void;
    data?: any;
    listContructions?: any;
}

export const CreateTypeOfWorkForm = (props: IProps) => {
    const { handleCloseNew, handleOnCreateSchedule, data } = props;
    const formattedDate = new Date().toISOString().slice(0, 10);
    //const { updateContractorHistory } = useContractors();

    //component states
    // const [listWorkTypes, setListWorkTypes] = React.useState<any>();
    const [optionWorkTypes, setOptionWorkTypes] = React.useState<any>();
    // const [optionConstractors, setOptionConstractors] = React.useState<any>();
    // const [totalPeopleContructors, setTotalPeopleContructors] = React.useState<any>(0);
    const initialValuesPackage = {
        contractions: "",
        constructors: [],
        listConstractors: [],
        detail: "",
        workType1: {
            type: 1,
            work_type_id: [],
            work_type_date: [
                {
                    start_date: formattedDate,
                    end_date: formattedDate,
                },
            ],
        },
    };

    React.useEffect(() => {
        fetchWorkTypeByType();
        return () => {
            // clean up
        };
    }, []);
    const fetchWorkTypeByType = async () => {
        try {
            const result = await workScheduleApi.getWorkTypeByType();
            setOptionWorkTypes(result?.data?.data);
        } catch (err) {}
    };

    return (
        <StyledNewFormWorkSchedule style={{ overflowX: "unset" }}>
            <Formik
                initialValues={initialValuesPackage}
                enableReinitialize
                onSubmit={(values, { resetForm }) => {
                    handleOnCreateSchedule(
                        {
                            // construction_id: data?.construction?.id || values.contractions,
                            construction_id: data?.id,
                            type: 1,
                            work_type: values?.workType1?.work_type_id,
                            detail: values?.detail,
                        },
                        resetForm
                    );
                }}
                validationSchema={workScheduleAddForm}
            >
                {({ handleSubmit, values, touched, errors, setFieldValue, resetForm }) => {
                    return (
                        <div className="workSchedule__newForm">
                            <div className="workScheduleNewForm__select">
                                {/** Construction site*/}
                                <p style={{ marginBottom: "8px" }} className="label">
                                    {!data?.name ? `工事名` : `工事名: ${data?.name}`}
                                </p>

                                {/* <p style={{ marginBottom: "8px" }} className="label">
                                    {data?.name}
                                </p> */}

                                <>
                                    <div>
                                        <label className="label">工種を追加</label>
                                        <AppCustomeSelect
                                            options={
                                                !!optionWorkTypes?.type_1?.length &&
                                                optionWorkTypes?.type_1?.map((item) => {
                                                    return {
                                                        label: item.name,
                                                        value: item.id.toString(),
                                                    };
                                                })
                                            }
                                            className={""}
                                            groupOptions={null}
                                            name="workType1.work_type_id"
                                            value={optionWorkTypes?.type_1
                                                ?.filter((item) => item.id.toString() === values.workType1.work_type_id[0])
                                                .map((item) => ({
                                                    label: item.name,
                                                    value: item.id.toString(),
                                                }))}
                                            placeholder="工種を追加"
                                            onChange={(value) => {
                                                setFieldValue("workType1", {
                                                    type: 1,
                                                    work_type_id: [value.value],
                                                    work_type_date: [
                                                        {
                                                            start_date: formattedDate,
                                                            end_date: formattedDate,
                                                            work_type_id: value.value,
                                                        },
                                                    ],
                                                });
                                            }}
                                        />
                                        {errors?.workType1 && touched?.workType1 ? <div className="error">{errors?.workType1?.work_type_id}</div> : null}
                                        {/* Type 1 Dates */}
                                        {/* <FieldArray
                                            name="workType1.work_type_date"
                                            render={(arrayHelpers) => (
                                                <>
                                                    {values?.workType1?.work_type_date?.map((type, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div
                                                                    className="workScheduleNewForm__datePicker"
                                                                    style={{
                                                                        marginTop: "12px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        <button
                                                                            onClick={() => {
                                                                                arrayHelpers.remove(index);
                                                                            }}
                                                                            style={{
                                                                                border: "none",
                                                                                backgroundColor: "#fff",
                                                                                marginLeft: "auto",
                                                                            }}
                                                                        >
                                                                            <IoIosClose size={25} color="#656565" />
                                                                        </button>
                                                                    </div>
                                                                    <AppDateRangePicker
                                                                        name={`workType1.work_type_date.${index}`}
                                                                        value={{
                                                                            startDate: (values?.workType1?.work_type_date[index] as any)?.start_date,
                                                                            endDate: (values?.workType1?.work_type_date[index] as any)?.end_date,
                                                                        }}
                                                                        onChange={(e) => {
                                                                            const id = values?.workType1.work_type_id?.[0];
                                                                            const newDates: any[] = [...(values?.workType1?.work_type_date ?? [])];
                                                                            const newStartDate = moment(e?.startDate).format("YYYY-MM-DD");
                                                                            const newEndDate = moment(e?.endDate).format("YYYY-MM-DD");
                                                                            newDates[index] = {
                                                                                work_type_id: id,
                                                                                start_date: newStartDate,
                                                                                end_date: newEndDate,
                                                                            };

                                                                            setFieldValue(`workType1.work_type_date`, newDates);
                                                                        }}
                                                                        startText="開始"
                                                                        endText="終了"
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    <div className="btn-addDate__container">
                                                        <button
                                                            className="btn-addDate"
                                                            onClick={() =>
                                                                arrayHelpers.insert(values.workType1.work_type_id.length, {
                                                                    start_date: formattedDate,
                                                                    end_date: formattedDate,
                                                                    work_type_id: values?.workType1.work_type_id[0],
                                                                })
                                                            }
                                                        >
                                                            + 日付追加
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        /> */}
                                    </div>
                                </>

                                {/* <Divider style={{ margin: "2rem 0" }} />
                                <p style={{ marginBottom: "8px" }}>詳細</p>
                                <AppInput value={values.detail} name="detail" handleChange={handleChange("detail")} placeholder="詳細" error={errors.detail} touched={touched.detail} handleBlur={handleBlur("detail")} />
                                <Divider style={{ margin: "2rem 0" }} />
                                <p style={{ marginBottom: "8px" }} className="label">
                                    作業会社
                                </p>
                                <div className="select__contractor">
                                    <AppCustomeSelect
                                        groupOptions={optionConstractors}
                                        options={
                                            optionConstractors?.contractors?.length &&
                                            optionConstractors?.contractors?.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id.toString(),
                                                };
                                            })
                                        }
                                        placeholder="select 請負業者"
                                        name="constructors"
                                        value={values.constructors}
                                        // noError={errors.constructors}
                                        onChange={(value) => {
                                            updateContractorHistory(value);
                                            onChangeConstractors(value, values, setFieldValue);
                                            setFieldValue("constructors", value);
                                        }}
                                        isMulti
                                    />
                                    <p className="select__number">
                                        <span>{LABEL_CONTRACTOR_AMOUNT}</span>
                                        <span>{totalPeopleContructors}</span>
                                    </p>
                                </div>
                                {errors?.constructors && touched?.constructors ? <div className="error">{errors?.constructors}</div> : null} */}

                                {/* <RenderConstractorsFieldsForm
                                    valuesForm={values.listConstractors}
                                    handleChangeNumberOfPeople={handleChangeNumberOfPeople}
                                    values={values.constructors.map((e: any) => e?.value)}
                                    options={listWorkTypes}
                                    // name="listConstractors"
                                    typeOfConstractors="listConstractors"
                                    // errors={errors.constructors}
                                    listConstractors={optionConstractors?.contractors}
                                    workSchedule
                                    setFieldValue={setFieldValue}
                                />
                                {errors?.listConstractors && touched?.listConstractors ? <div className="error">{(errors?.listConstractors?.[0] as any)?.number_of_people}</div> : null} */}
                            </div>

                            <div className="workScheduleNewForm__btn">
                                <AppButton text="追加" onClick={handleSubmit} startIcon={<PlusIcon />} />
                            </div>
                            <div className="workScheduleNewForm__btn cancel">
                                <Button
                                    onClick={() => {
                                        handleCloseNew(resetForm);
                                        resetForm();
                                    }}
                                >
                                    キャンセル
                                </Button>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </StyledNewFormWorkSchedule>
    );
};
