// import { MAX_WIDTH_BODY } from "@configs";
import styled from "styled-components";

type IStyledAppBar = {
    screen?: string;
};
export const StyledAppTopBar = styled.div<IStyledAppBar>`
    display: flex;
    justify-content: right;
    background-color: ${(p) => p.theme.colors.white};
    .MuiBadge-badge{
        font-size: 1.2rem;
    }

    .menuText {
    position:absolute;
    margin-left: 10px;
    margin-top:3px;
    font-size:7px;
            }
    .toHome {
            padding-top:2px;
            font-size:10px;
            color: ${(p) => p.theme.colors.black};
            }
    .topbar {
        padding: 0.5rem 1.5rem;
        background-color: ${(p) => p.theme.colors.white};
        display: flex;
        justify-content: end;
        align-items:center;
        // @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        //     justify-content: space-between;
        // }
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 1rem 1.5rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 1rem 1.5rem;
        }
        &__logo {
            display: none;
            &:hover {
                cursor: pointer;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: flex;
                justify-content: flex-start;
            }
        }
        &__content {
            display: flex;
            justify-content: end;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: flex;
                justify-content: space-between;
            }
        }
        &__left {
            display: flex;
            justify-content: flex-end;
            &-smallLogo {
                width: 4rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    display: none;
                }
            }
        }
        &__right {
            display: flex;
            justify-content: flex-end;
            &-btnBell,
            &-btnMenu {
                padding-right: 0.6rem;
                display: block;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    display: ${(p) => (p.screen === "home" ? "none" : "block")};
                }
            }
            &-btnBell {
                /* display: ${(p) => (p.screen === "profile" ? "none" : "block")}; */
                display: block;
                margin-top:10px;
                @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    display: block;
                }
            }
        }
        &__userInfo {
            display: flex;
            border-radius: 50px;
            padding: 0px 20px 0px 20px;
            // background-color: ${(p) => p.theme.colors.gray6};
            display: ${(p) => (p.screen === "profile" ? "none" : "flex")};
            cursor: pointer;
            @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-right:30px;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: flex;
            }
            .MuiAvatar-root {
                width: 48px;
                height: 48px;
            }
            &-text {
                display: flex;
                flex-direction: column;
                text-align: left;
                padding-left: 10px;
            }
            .subText {
                font-size: 12px;
                color: ${(p) => p.theme.colors.gray2};
            }
            .mainText {
                font-size: 16px;
                color: ${(p) => p.theme.colors.black};
            }

        }
    }
`;
