import React, { createContext, SetStateAction, useContext, useEffect, useState } from "react";
import { EWorkTypeAction, IColorItem, ICompanyItem, IWorkTypeAction } from "src/Components/WorkSchedule/types";

interface IWorkScheduleContextValue {
    companyOptions: ICompanyItem[];
    colorOptions: IColorItem[];
    workTypeAction: IWorkTypeAction;
    date: any;
    selectedDateRange: number[];
    dateRangeColors: string[];
    workTypeData: any[];
    setCompanyOptions: (value: SetStateAction<ICompanyItem[]>) => void;
    setColorOptions: (value: SetStateAction<IColorItem[]>) => void;
    setWorkTypeAction: (value: SetStateAction<IWorkTypeAction>) => void;
    setDate: (value: SetStateAction<any>) => void;
    setSelectedDateRange: (value: SetStateAction<number[]>) => void;
    setDateRangeColors: (value: SetStateAction<string[]>) => void;
    setWorkTypeData: (value: SetStateAction<any[]>) => void;
}

const initialState = {
    companyOptions: [],
    colorOptions: [],
    workTypeAction: {
        selectedId: "",
        action: "" as EWorkTypeAction,
        color: "",
    },
    date: null,
    selectedDateRange: [],
    dateRangeColors: [],
    workTypeData: [],
    setCompanyOptions: (value) => value,
    setColorOptions: (value) => value,
    setWorkTypeAction: (value) => value,
    setDate: (value) => value,
    setSelectedDateRange: (value) => value,
    setDateRangeColors: (value) => value,
    setWorkTypeData: (value) => value,
};

const WorkScheduleContext = createContext<IWorkScheduleContextValue>(initialState);

export const WorkScheduleProvider = ({ children }: { children: React.ReactElement }) => {
    const [companyOptions, setCompanyOptions] = useState<ICompanyItem[]>([]);
    const [colorOptions, setColorOptions] = useState<IColorItem[]>([]);
    const [workTypeAction, setWorkTypeAction] = useState<IWorkTypeAction>({
        selectedId: "",
        action: "",
        color: "",
    });
    const [date, setDate] = useState(new Date());
    const [selectedDateRange, setSelectedDateRange] = useState<number[]>([]);
    const [dateRangeColors, setDateRangeColors] = useState<string[]>(Array(32).fill(""));
    const [workTypeData, setWorkTypeData] = useState<any>();

    useEffect(() => {
        if (selectedDateRange?.length < 2) return;

        setDateRangeColors((prev) => {
            return prev?.map((item, index) => {
                if (index >= selectedDateRange[0] && index <= selectedDateRange[1]) return workTypeAction?.color || "";
                return item;
            });
        });
        setSelectedDateRange([]);
    }, [JSON.stringify(selectedDateRange), workTypeAction?.color]);

    useEffect(() => {
        setDateRangeColors(Array(32).fill(""));
    }, [workTypeAction?.selectedId]);

    return (
        <WorkScheduleContext.Provider
            value={{
                date,
                dateRangeColors,
                selectedDateRange,
                colorOptions,
                companyOptions,
                workTypeAction,
                workTypeData,
                setDate,
                setDateRangeColors,
                setSelectedDateRange,
                setColorOptions,
                setCompanyOptions,
                setWorkTypeAction,
                setWorkTypeData,
            }}
        >
            {children}
        </WorkScheduleContext.Provider>
    );
};

export const useWorkScheduleContext = () => {
    const context = useContext(WorkScheduleContext);

    if (!context) throw new Error("WorkScheduleContext must be use inside WorkScheduleProvider");

    return context;
};
