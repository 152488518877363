import { theme } from "@utils";
import React from "react";
// import { BsFiletypeCsv } from "react-icons/bs";
import styled from "styled-components";

interface Props {
  type: string;
  startDate?: string;
  endDate?: string;
}

export const AppExportCsv = React.memo((props: Props) => {
  const { type, startDate, endDate } = props;
  const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/+$/, '');  // Remove trailing slashes
  const normalizedType = type.replace(/^\/+/, '');                    // Remove leading slashes
  const exportUrl = (startDate && endDate) ? `${apiUrl}/api/${normalizedType}Csv/export?startDate=${startDate}&endDate=${endDate}` : `${apiUrl}/${normalizedType}/export`;
  //✓
  return (
    <a
      href={exportUrl}
      style={{ textDecoration: "none" }}
    >
      <PrimaryButton>
        {/* <BsFiletypeCsv className="icon" size={24} /> */}
        <p className="text">CSV出力</p>
      </PrimaryButton>
    </a>
  );
});

const PrimaryButton = styled.div`
padding: 1rem 3rem;
border-radius: 50px;
font-weight: bold;
transition: all 0.3s;
width:15rem;
text-align : center;
font-size : 12px;
cursor: pointer;
background-color: ${theme.colors.orange};
color: ${theme.colors.white00};
height:4rem;

@media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width:100%;
        }

&:hover {
  background-color: ${theme.colors.yellow08};
}
&:focus {
  outline: 2px solid ${theme.colors.lightgray09};
}

    // display: flex;
    // font-weight: 700;
    // border: 2px solid orange;
    // color: orange;
    // border-radius: 10px;
    // display: flex;
    // align-items: center;
    // width: fit-content;
    // padding: 8px 10px;
    // :hover {
    //     cursor: pointer;
    //     color: #d88d02;
    //     border: 2px solid #d88d02;
    //     .icon {
    //         fill: #d88d02;
    //     }
    // }
    // .icon {
    //     fill: orange;
    // }
    // .text {
    //     margin-left: 1rem;
    // }
`;
