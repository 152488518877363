import { StyledTableWrapper } from "./styles";
import { getDaysWeeksArrayMoment } from "@utils";
import clsx from "clsx";
import styled from "styled-components";

interface IProps {
    data?: any;
    date?: any;
    tableStyle?: any;
}
export const ViewWrapGraphCalendar = (props: IProps) => {
    const { date, data, tableStyle } = props;

    return (
        <StyledTableWrapper>
            <table className="table" style={tableStyle}>
                <tr className="table__header">
                    {getDaysWeeksArrayMoment(date).map((day, index) => (
                        <td key={index} className={clsx(day.dayInWeek === "sun" || day.dayInWeek === "sat" ? "dayOff" : "")}>
                            {day.dayNumber}
                        </td>
                    ))}
                </tr>

                {!!data?.length &&
                    (data ?? []).map((dataItem, scheduleIndex) => {
                        return (
                            <>
                                <TableRowCustomStyled className="table__body table-row-customized" key={`Type${scheduleIndex}`}>
                                    {(() => {
                                        const cols = [] as any;
                                        for (let i = 1; i <= getDaysWeeksArrayMoment(date).length; i++) {
                                            {
                                                const currentDate = i < 10 ? "0" + i : "" + i;

                                                const totalNumberOfPeople =
                                                    dataItem?.work_schedules
                                                        ?.map((schedule) => {
                                                            const filteredWorkTypes = schedule?.work_schedule_work_type.filter((workType) => {
                                                                return workType?.date?.split("-")[2] === currentDate;
                                                            });

                                                            const numberOfPeople = filteredWorkTypes?.map((workType) => workType?.number_of_people);

                                                            return numberOfPeople?.reduce((sum, num) => sum + num, 0);
                                                        })
                                                        ?.reduce((total, dailyTotal) => total + dailyTotal, 0) ?? 0;

                                                if (totalNumberOfPeople > 0) {
                                                    cols.push(
                                                        <td key={i} className="table__event">
                                                            <div
                                                                className="event cursor-default"
                                                                style={{
                                                                    backgroundColor: "#000",
                                                                    color: "#fff",
                                                                    height: "42px",
                                                                }}
                                                            >
                                                                <p
                                                                    className="event__name"
                                                                    style={{
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    {totalNumberOfPeople}
                                                                </p>
                                                            </div>
                                                        </td>
                                                    );
                                                } else {
                                                    cols.push(<td style={{ height: "42px" }}></td>);
                                                }
                                            }
                                        }
                                        return cols;
                                    })()}
                                </TableRowCustomStyled>
                            </>
                        );
                    })}
            </table>
        </StyledTableWrapper>
    );
};

const TableRowCustomStyled = styled.tr`
    &.table-row-customized {
        td {
            min-width: 36px !important;
        }
    }
`;
