import React from "react";
import { linkAvatar, theme, useConstructionSites } from "@utils";

// import checked_status_icon from "@assets/Icons/OT/checked_status_icon.svg";
// import unchecked_status_icon from "@assets/Icons/OT/unchecked_status_icon.svg";
import { OT_STATUS_OK, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import { Avatar, Grid } from "@mui/material";
import { StyledDaily } from "./styles";
import { TimeSheetFormTypeEnums, appRouteEnums } from "@enums";
import { selectAuth, setLoading, useAppSelector } from "@redux";
import { timesheetApi } from "@api";
import { useHistory } from "react-router-dom";
import checkIcon from "@assets/checkIcon.png";
import xIcon from "@assets/xIcon.png";

interface IProps {
  data?: any;
  handleViewDetail?: ({ otId, userId, status }) => void;
}
export const DailyViewTableComponent = (props: IProps) => {
  const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();
  // props
  const { data } = props;
  const user = useAppSelector(selectAuth);
  const { userInfo } = user;
  const history = useHistory();

  // hooks
  const { sortConstructionSites } = useConstructionSites();
  const handleShowTypeTimeSheet = (value) => {
    /**
     * 日報承認要求
     * 日勤 :0
     * 夜勤 :1
     * 日勤と夜勤 :2
     * 休日出勤 : 3
     * 有給休暇: 4
     * 半日出勤 :5
     * 欠勤 :6
     * 公休日: 7
     */
    switch (value) {
      case TimeSheetFormTypeEnums.ENUMS_TYPE_DAYSHIT:
        return "日勤";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_NIGHTSHIFT:
        return "夜勤";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_DATSHIFT_AND_NIGHTSHIFT:
        return "日勤からの夜勤";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_HOLIDAYWORK:
        return "休日出勤";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_PAID_TIME_OFF:
        return "有給休暇";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_HALFDAY_WORK:
        return "半日有給";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_ABSENCE:
        return "欠勤";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_LEGAL_HOLIDAY:
        return "公休日";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_NIGHTSHIFT_AND_DAYSHIFT:
        return "夜勤からの日勤";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_EARLY_LEAVE:
        return "早退";
      case TimeSheetFormTypeEnums.ENUMS_TYPE_TARDINESS:
        return "遅刻";
      default:
        return "";
    }
  };

  const fetchApiDetail = async () => {
    setLoading(true);
    try {
      const result = await timesheetApi.getListConstructions();
      setOptionSiteConstructions(sortConstructionSites(result?.data.data?.constructions));
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    fetchApiDetail();
  }, []);

  const handleToTimesheet = (id, employee_id, type_timsheet) => {
    if (employee_id === userInfo?.id) {
      history.push(`${appRouteEnums.PATH_TIMESHEET}/edit/${type_timsheet}/${id}`);
    } else {
      history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${type_timsheet}/${id}`);
    }
  };

  return (
    <StyledDaily>
      <div className="data-table">
        <Grid container >

          <Grid item xs={12}>
            <div className="top-titles">
              <Grid container spacing={1}>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>名前</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>日付</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>日報種別</Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>現場名</Grid>
                <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>工種</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>種別</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>タイプ</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>合計</Grid>
                <Grid item xs={4} sm={4} md={4} lg={1} xl={1}>承認</Grid>
              </Grid>
            </div>
          </Grid>




          {data &&
            data?.map((item, index) => {
              const numberContruction = item?.TimesheetItemConstruction?.map(
                (item) => item.id
              );

              const contructionFill = item?.TimesheetItemConstruction?.map((item) => {
                const getId = item?.construction_id;
                return getId;
              });

              const constructionId =
                numberContruction?.length < 2
                  ? item?.TimesheetItemConstruction?.[0]?.construction_id
                  : item?.TimesheetItemConstruction?.map(
                    (item) => item?.construction_id
                  );

              // const constructionId = item?.TimesheetItemConstruction?.[0].construction_id;
              const constructionsMatch =
                optionSiteConstructions && numberContruction?.length < 2
                  ? optionSiteConstructions?.find(
                    (construction) => construction?.id === constructionId
                  )
                  : optionSiteConstructions?.find(
                    (construction) => construction?.id === contructionFill?.[0]
                  );

              // const listWorkType = item?.TimesheetItemConstruction?.[0]?.contractor_timesheet_item
              const numberOfPeople = item?.TimesheetItemConstruction?.map(
                (item) => item?.contractor_timesheet_item[0]?.number_of_people
              );

              const getIdContruction = optionSiteConstructions?.map((item) => item);

              const getItemById = getIdContruction?.filter(function (item) {
                return contructionFill?.includes(item.id);
              });
              const contruction = getItemById?.map((item) => item.name);

              return (

                <Grid item xs={12} key={index}>
                  <div className="contentsContainer">

                    <Grid container spacing={1}
                      sx={{ borderBottom: index === data.length - 1 ? "none" : `1px solid ${theme.colors.lightgray09}`, paddingBottom: 1, alignItems: "center" }} >

                      <Grid item xs={8} sm={8} md={8} lg={2} xl={2} sx={{ order: { md: 1, lg: 1 } }}>
                        <div className="daily__userContainer">
                          <div className="daily__userAvatar">
                            <Avatar src={linkAvatar(item?.avatar)} />
                          </div>
                          <div className="daily__userName">
                            {item?.user?.last_name +
                              " " +
                              item?.user?.first_name}
                          </div>

                        </div>

                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={1} xl={1} sx={{ order: { xs: 4, sm: 4, md: 4, lg: 2 } }}>
                        <div className="subTitle">
                          <p className="subtitle__text">日付</p>
                        </div>
                        <p className="">{item?.date}</p>
                      </Grid>



                      <Grid item xs={4} sm={4} md={4} lg={1} xl={1} sx={{ order: { xs: 5, sm: 5, md: 5, lg: 3 } }}>
                        <div className="subTitle">
                          <p className="subtitle__text">日報種別</p>
                        </div>
                        <div className="">
                          {handleShowTypeTimeSheet(item?.type_timesheet)}
                        </div>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={2} xl={2} sx={{ order: { xs: 6, sm: 6, md: 6, lg: 4 } }}>
                        <div className="subTitle">
                          <p className="subtitle__text">現場名</p>
                        </div>
                        <div className="">
                          {constructionsMatch &&
                            contruction.map((item) => {
                              return <p>{item}</p>;
                            })}
                        </div>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={2} xl={2} sx={{ order: { xs: 7, sm: 7, md: 7, lg: 5 } }}>
                        <div className="subTitle">
                          <p className="subtitle__text">工種</p>
                        </div>
                        {item?.TimesheetItemConstruction &&
                          item?.TimesheetItemConstruction?.map((item) =>
                            item?.contractor_timesheet_item[0]?.work_types?.map(
                              (contractors, contractorsIndex) => {
                                return (
                                  <div
                                    key={contractorsIndex}
                                    className=""
                                  >
                                    <p className="">
                                      {contractors.type ===
                                        1 &&
                                        contractors.name}
                                    </p>
                                  </div>
                                );
                              }
                            )
                          )}
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={1} xl={1} sx={{ order: { xs: 8, sm: 8, md: 8, lg: 6 } }}>
                        <div className="subTitle">
                          <p className="subtitle__text">種別</p>
                        </div>
                        {item?.TimesheetItemConstruction &&
                          item?.TimesheetItemConstruction?.map((item) =>
                            item?.contractor_timesheet_item[0]?.work_types?.map(
                              (contractors, contractorsIndex) => {
                                return (
                                  <div
                                    key={contractorsIndex}
                                    className=""
                                  >
                                    <p className="">
                                      {contractors.type ===
                                        2 &&
                                        contractors.name}
                                    </p>
                                  </div>
                                );
                              }
                            )
                          )}
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={1} xl={1} sx={{ order: { xs: 9, sm: 9, md: 9, lg: 7 } }}>
                        <div className="subTitle">
                          <p className="subtitle__text">タイプ</p>
                        </div>

                        {item?.TimesheetItemConstruction &&
                          item?.TimesheetItemConstruction?.map((item) =>
                            item?.contractor_timesheet_item[0]?.work_types?.map(
                              (contractors, contractorsIndex) => {
                                return (
                                  <div
                                    key={contractorsIndex}
                                    className=""
                                  >
                                    <p className="">
                                      {contractors.type ===
                                        3 &&
                                        contractors.name}
                                    </p>
                                  </div>
                                );
                              }
                            )
                          )}
                      </Grid>

                      <Grid item xs={2} sm={2} md={2} lg={1} xl={1} sx={{ order: { xs: 2, sm: 2, md: 2, lg: 8 } }}>
                        <div className="total">
                          <div className="subTitle">
                            <p className="subtitle__text">合計</p>
                          </div>
                          {numberOfPeople?.map((item, i) => (
                            <p className="" key={i}>
                              {item}
                            </p>
                          ))}
                        </div>
                      </Grid>

                      <Grid item xs={2} sm={2} md={2} lg={1} xl={1} sx={{ order: { xs: 3, sm: 3, md: 3, lg: 9 } }}>
                        <div className="approval">
                          {item?.status === OT_STATUS_OK && (
                            <div
                              className="approval-checked"
                              onClick={() =>
                                handleToTimesheet(
                                  item?.timesheet_id,
                                  item?.user?.id,
                                  item?.type_timesheet
                                )
                              }
                            >
                              <img className="logoutIcon" src={checkIcon} alt="OK-icon" />
                            </div>
                          )}
                          {item?.status === OT_STATUS_REJECT && (
                            <div
                              className="approval-rejected"
                              onClick={() =>
                                handleToTimesheet(
                                  item?.timesheet_id,
                                  item?.user?.id,
                                  item?.type_timesheet
                                )
                              }
                            >
                              <img className="logoutIcon" src={xIcon} alt="NG-icon" />
                            </div>
                          )}
                          {item?.status === OT_STATUS_PENDING && (
                            <div
                              className="approval-unchecked"
                              onClick={() =>
                                handleToTimesheet(
                                  item?.timesheet_id,
                                  item?.user?.id,
                                  item?.type_timesheet
                                )
                              }
                            >
                              <span className="text">未</span>
                            </div>
                          )}
                        </div>
                      </Grid>

                    </Grid>
                  </div>
                </Grid>


                // <div className="item" key={index}>
                //   <div className="item__top">
                //     <div className="item__userInfo">
                //       <span className="item__userInfo-avatar">
                //         <Avatar src={linkImage(item?.user?.avatar?.path)} />
                //       </span>
                //       <span className="item__userInfo-title">
                //         <p className="name">
                //           {item?.user?.last_name +
                //             " " +
                //             item?.user?.first_name}
                //         </p>
                //       </span>
                //     </div>
                //     <div className="item__date desktop">
                //       <div className="item__date-start">
                //         <p className="title">日にち</p>
                //         <p className="content">{item?.date}</p>
                //       </div>
                //       <div className="item__title">
                //         <p className="title">日報種別</p>
                //         <div className="">
                //           {handleShowTypeTimeSheet(item?.type_timesheet)}
                //         </div>
                //       </div>
                //       <div className="item__title">
                //         <p className="title">現場名</p>
                //         <div className="">
                //           {constructionsMatch &&
                //             contruction.map((item) => {
                //               return <p>{item}</p>;
                //             })}
                //         </div>
                //       </div>
                //       <div className="item__date-hour">
                //         <div className="item__work-type">
                //           <div className="work-type-item-title">
                //             <p className="title">工種</p>
                //           </div>
                //           {item?.TimesheetItemConstruction &&
                //             item?.TimesheetItemConstruction?.map((item) =>
                //               item?.contractor_timesheet_item[0]?.work_types?.map(
                //                 (contractors, contractorsIndex) => {
                //                   return (
                //                     <div
                //                       key={contractorsIndex}
                //                       className="work-type-item"
                //                     >
                //                       <p className="content work-type-content">
                //                         {contractors.type ===
                //                           1 &&
                //                           contractors.name}
                //                       </p>
                //                     </div>
                //                   );
                //                 }
                //               )
                //             )}
                //         </div>
                //         <div className="item__work-type">
                //           <div className="work-type-item-title">
                //             <p className="title">種別</p>
                //           </div>
                //           {item?.TimesheetItemConstruction &&
                //             item?.TimesheetItemConstruction?.map((item) =>
                //               item?.contractor_timesheet_item[0]?.work_types?.map(
                //                 (contractors, contractorsIndex) => {
                //                   return (
                //                     <div
                //                       key={contractorsIndex}
                //                       className="work-type-item"
                //                     >
                //                       <p className="content work-type-content">
                //                         {contractors.type ===
                //                           2 &&
                //                           contractors.name}
                //                       </p>
                //                     </div>
                //                   );
                //                 }
                //               )
                //             )}
                //         </div>
                //         <div className="item__work-type">
                //           <div className="work-type-item-title">
                //             <p className="title">タイプ</p>
                //           </div>
                //           {item?.TimesheetItemConstruction &&
                //             item?.TimesheetItemConstruction?.map((item) =>
                //               item?.contractor_timesheet_item[0]?.work_types?.map(
                //                 (contractors, contractorsIndex) => {
                //                   return (
                //                     <div
                //                       key={contractorsIndex}
                //                       className="work-type-item"
                //                     >
                //                       <p className="content work-type-content">
                //                         {contractors.type ===
                //                           3 &&
                //                           contractors.name}
                //                       </p>
                //                     </div>
                //                   );
                //                 }
                //               )
                //             )}
                //         </div>
                //       </div>

                //       <div className="item__date-number">
                //         <p className="title">総合計</p>
                //         {numberOfPeople?.map((item, i) => (
                //           <p className="content" key={i}>
                //             {item}
                //           </p>
                //         ))}
                //       </div>
                //     </div>
                //     <div className="item__status">
                //       {item?.status === OT_STATUS_OK && (
                //         <div
                //           className="item__status-checked"
                //           style={{ cursor: "pointer" }}
                //           onClick={() =>
                //             handleToTimesheet(
                //               item?.timesheet_id,
                //               item?.user?.id,
                //               item?.type_timesheet
                //             )
                //           }
                //         >
                //           <span className="text">OK</span>
                //           <img
                //             className="icon"
                //             src={checked_status_icon}
                //             alt="icon"
                //           />
                //         </div>
                //       )}
                //       {item?.status === OT_STATUS_REJECT && (
                //         <div
                //           className="item__status-unChecked"
                //           style={{ cursor: "pointer" }}
                //           onClick={() =>
                //             handleToTimesheet(
                //               item?.timesheet_id,
                //               item?.user?.id,
                //               item?.type_timesheet
                //             )
                //           }
                //         >
                //           <span className="text">NG</span>
                //           <img
                //             className="icon"
                //             src={unchecked_status_icon}
                //             alt="icon"
                //           />
                //         </div>
                //       )}
                //       {item?.status === OT_STATUS_PENDING && (
                //         <div
                //           className="item__status-pending"
                //           style={{ cursor: "pointer" }}
                //           onClick={() =>
                //             handleToTimesheet(
                //               item?.timesheet_id,
                //               item?.user?.id,
                //               item?.type_timesheet
                //             )
                //           }
                //         >
                //           <span className="text">未決</span>
                //         </div>
                //       )}
                //     </div>
                //   </div>

                //   {/* </div> */}
                //   {/* ))} */}
                // </div>
              );
            })}
        </Grid>
      </div>
    </StyledDaily>
  );
};
