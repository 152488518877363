import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "react-select-search/lib/css/select-search.css";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "@redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { theme } from "@utils";

function updateSafeTop() {
    if (!window.visualViewport) { return; }
    console.log(window.visualViewport.offsetTop);
    //document.documentElement.style.setProperty('--safe-top', `${window.visualViewport.offsetTop}px`);
}

window.addEventListener('resize', updateSafeTop);
window.addEventListener('scroll', updateSafeTop);
window.addEventListener('load', updateSafeTop);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <React.Fragment>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </ThemeProvider>
                <CssBaseline />
            </React.Fragment>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
