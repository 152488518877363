import styled from "styled-components";

export const StyledAppLayout = styled.div`
    display: flex;
    height: 100vh;
    overflow-x: hidden;
`;

export const Sidebar = styled.div<{isOpen: boolean}>`
    width:380px;
    position:fixed;
    transition: left 0.3s ease-in-out;
    z-index: 1100;
    top: 0;
    height: 100vh;
    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        width:280px;
        left: ${(p) => (p.isOpen ? "0" : "-350px")};
        box-shadow: 1px -2px 23px 10px rgba(0,0,0,0.25);
        -webkit-box-shadow: 1px -2px 23px 10px rgba(0,0,0,0.25);
        -moz-box-shadow: 1px -2px 23px 10px rgba(0,0,0,0.25);
    }
`;

export const MainContent = styled.div`
    position: relative;
    margin-left:380px;
    width: calc(100% - 380px);
    overflow-x: hidden;
    @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        margin-left: 0;
        width: 100%;
    }
`;

export const MenuToggleButton = styled.button`
    position: fixed;
    top: 6px;
    right: 15px;
    z-index: 1100;
    color: black;
    border: none;
    padding: 10px;
    font-size: 24px;
    cursor: pointer;
    border-radius: 5px;
    background-color:rgba(0,0,0,0);
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        display: none;
    }
`;
