import React from "react";
import moment from "moment";
// import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PlusIcon } from "@assets";
import { timesheetApi, workScheduleApi } from "@api";
import { PLACEHOLDER_CONSTRACTOR } from "@configs";
import { appRouteEnums, menuTitleEnums } from "@enums";
import { selectAuth, updateSnackBar, useAppSelector } from "@redux";
import { linkImage } from "@utils";
import { CreateTypeOfWorkForm, StyledWorkScheduleScreen, TableViewWorkSchedule, ViewGraphCalendar } from ".";
import { TitleMenuComponent, WorkScheduleToggleModal, AppCustomeSelect, CircularLoader, AppModal, AppButton } from "..";
import { useWorkScheduleContext, WorkScheduleProvider } from "./context/WorkScheduleContext";
import "../../App.css";
// import tick_icon from "@assets/Icons/tick_icon.svg";

// const defaultGroup = {
//     id: 0,
//     name: "全て",
// };

const WorkScheduleDetailScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useAppSelector(selectAuth);
  const { userInfo } = auth;

  const { id } = useParams<{ id: any }>();

  const { setDate: setDateContext, setWorkTypeData } = useWorkScheduleContext();

  // component states
  const [collapsedRowIds, setCollapsedRowIds] = React.useState<number[]>([]);
  // const [toggle, setToggle] = React.useState<number>(0);
  const [openDrawserToogle, setOpenDrawserToogle] = React.useState<boolean>(false);
  const [selectSubContractor, setSelectSubContractor] = React.useState<any>(null);
  const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
  const [date, setDate] = React.useState(new Date());
  const [users, setUsers] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [dataWorkSchedule, setDataWorkSchedule] = React.useState<any>([]);
  const [dataToggle, setDataToggle] = React.useState<any>();
  const [openNewForm, setOpenNewForm] = React.useState<boolean>(false);
  const [contructions, setContructions] = React.useState<any>();
  // const [groups, setGroups] = React.useState<any>([]);
  // const [employeeInput, setEmployeeInput] = React.useState<any>("");
  // const [filterData, setFilterData] = React.useState<any>([]);
  // const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();
  // const [optionEmployee, setOptionEmployee] = React.useState<any[]>([]);
  const [reloadPage, setReloadPage] = React.useState<boolean>(false);

  const [itemDetail, setItemDetail] = React.useState<any>();
  React.useEffect(() => {
    fetchApiDetailWorkSchedule();
    fetchDetailWork();
  }, [selectedGroup, selectSubContractor, date, reloadPage]);

  React.useEffect(() => {
    fetchListConstructionSite();

    return () => {
      // clean up
    };
  }, []);

  const fetchDetailWork = async () => {
    try {
      const response = await workScheduleApi.getListWorksInConstruction({
        month: date && moment(date).format("YYYY-MM"),
        constructionId: id ? id : "",
        contractorId: selectSubContractor?.value ?? "",
      });
      if (response?.data?.success) {
        setItemDetail(response?.data?.data?.[0]);
        setWorkTypeData(response?.data?.data?.[0]);
      }
    } catch (err: any) {
      if (err.response?.status === 404) {
        setItemDetail(null);
        setWorkTypeData([]);
        dispatch(
          updateSnackBar({
            messageType: "error",
            message: err.response?.data?.message,
          })
        );
      } else {
        dispatch(
          updateSnackBar({
            messageType: "error",
            message: err.response?.data?.message,
          })
        );
      }
    }
  };
  const fetchApiDetailWorkSchedule = async () => {
    setLoading(true);
    try {
      // const responseGroups = await timesheetApi.listGroupsTimesheet();
      // setGroups(responseGroups.data);
      const responseListUsers = await timesheetApi.getListContractors();
      const filteredSortedUsers = (!responseListUsers?.data?.data?.contractors || !Array.isArray(responseListUsers.data.data.contractors)) ? [] : responseListUsers.data.data.contractors
        //.filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id)))
        .sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0));
      setUsers(filteredSortedUsers);
      //setUsers(responseListUsers?.data?.data?.contractors);

      // setSelectedGroup(selectedGroup ? selectedGroup : 0);
      const responseWorkSchedule = await workScheduleApi.getOverViewWorkSchedule(
        {
          month: date && moment(date).format("YYYY-MM"),
          userId: selectSubContractor?.value ? selectSubContractor.value : "",
          constructionId: itemDetail?.id,
        },
        selectedGroup
      );
      // const responseGetListWorkSchedule = await workScheduleApi.getListWorksInConstruction({
      //     month: moment(date).format("YYYY-MM"),
      //     constructionId: id ? id : "",
      // });
      // setItemDetail(responseGetListWorkSchedule?.data?.data?.[0]);
      setDataWorkSchedule(responseWorkSchedule.data?.data);
      // setFilterData(responseWorkSchedule.data?.data);
      setLoading(false);
    } catch (err: any) {
      if (err.response?.status === 404) {
        setDataWorkSchedule([]);
      } else {
        dispatch(
          updateSnackBar({
            messageType: "error",
            message: err.response?.data?.message,
          })
        );
      }
      setLoading(false);
    }
  };
  // const handleOnChange = (e) => {
  //     setEmployeeInput(e);
  //     setSelectFilterConstructions("");
  //     if (e !== null) {
  //         setDataWorkSchedule(filterData.filter((item) => item.employee?.id === parseInt(e?.value)));
  //     } else {
  //         setDataWorkSchedule(filterData);
  //     }
  // };
  // fetch api
  // const fetchListConstructions = async () => {
  //     try {
  //         const result = await timesheetApi.getListConstructions();
  //         setOptionSiteConstructions(result.data.data);
  //     } catch (err) {}
  // };
  const fetchCreateWorkScheduleApi = async (values: any, resetForm: any) => {
    setLoading(true);
    try {
      const response = await workScheduleApi.storeWorkScheduleV2({
        ...values,
        contractor_id: 0,
      });
      setLoading(false);
      // handleCloseNewForm(resetForm);
      setOpenNewForm(false);
      dispatch(
        updateSnackBar({
          messageType: "success",
          message: response.data.message,
        })
      );
      setReloadPage(!reloadPage);
      resetForm();
    } catch (err: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response.data.message,
        })
      );
    }
  };
  // fetch api list timesheetApi
  const fetchListConstructionSite = async () => {
    try {
      const res = await workScheduleApi.getMyListContruction();
      setContructions(res.data.data);
      setReloadPage(!reloadPage);
    } catch (err) { }
  };
  // const fetchListEmployee = async () => {
  //     try {
  //         const res = await timesheetApi.listEmployees();
  //         setOptionEmployee(res?.data?.data);
  //     } catch (err) {}
  // };
  // events
  const handleCloseDrawserToggle = () => {
    setOpenDrawserToogle(false);
    setDataToggle("");
  };
  const handleOpenDrawserToggle = (id) => {
    const indexData = dataWorkSchedule.findIndex((e) => e.id === id);
    setDataToggle(dataWorkSchedule[indexData]);
    setOpenDrawserToogle(true);
  };
  const handleOnChangeSelect = (id) => {
    setSelectSubContractor(id);
  };
  // const handleSelectGroup = (id) => {
  //     setSelectSubContractor("");
  //     if (id === 0) {
  //         setSelectedGroup([]);
  //         return;
  //     }
  //     setSelectedGroup(checkExistUserSelected(id, selectedGroup) ? selectedGroup.filter((e) => e !== id) : [...selectedGroup, id]);
  // };
//  const handleChangeMonth = (date: any) => {
//    setDate(date);
//    setDateContext(date);
//    setSelectedGroup([]);
//  };
  const handleChangeMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value);
    setDate(newDate);
    setDateContext(newDate);
    setSelectedGroup([]);
  };

  const handleOpenViewDetail = (id) => {
    history.push(`${appRouteEnums.PATH_WORK_SCHEDULE}/${id}`);
  };
  const handleOpenNew = () => {
    setOpenNewForm(true);
  };
  const handleCloseNewForm = (resetForm: any) => {
    resetForm();
    setOpenNewForm(false);
  };
  const handleOnCreateSchedule = (values: any, resetForm: any) => {
    fetchCreateWorkScheduleApi(values, resetForm);
  };

  return (
    // <StyledWorkScheduleScreen isCollaspe={isCollapse}>
    <StyledWorkScheduleScreen>
      {/* <AppTopBar /> */}

      {openNewForm && (
        <AppModal open={openNewForm} handleClose={() => setOpenNewForm(false)}>
          <CreateTypeOfWorkForm data={itemDetail} handleCloseNew={handleCloseNewForm} handleOnCreateSchedule={handleOnCreateSchedule} listContructions={contructions} />
        </AppModal>
      )}

      <WorkScheduleToggleModal anchor="right" openToogle={openDrawserToogle} handleCloseToggle={handleCloseDrawserToggle} data={dataToggle} />
      <CircularLoader loading={loading} />

      <div className="workSchedule">
        <div>
          <div className="workSchedule__title">
            <TitleMenuComponent text={menuTitleEnums.TITLE_WORK_SCHEDULE} />
          </div>
          {/* <div className="workSchedule__toggle">
                        <span className={clsx(toggle === 0 ? "activeToggle" : "", "left")} onClick={() => setToggle(0)}>
                            テーブルビュー
                        </span>
                        <span className={clsx(toggle === 1 ? "activeToggle" : "", "right")} onClick={() => setToggle(1)}>
                            グラフビュー
                        </span>
                    </div> */}

          <div className="workSchedule__form">
            <div className="ws-top">
              <div className="wrapperInput">
                <div className="workSchedule__datePicker">
                  <div className="wrapperDatePicker">
                    <input
                      type="date"
                      value={`${date.toISOString().slice(0, 7)}-01`} // Format as YYYY-MM-01
                      onChange={handleChangeMonth}
                      className="inputMonth"
                    />
                  </div>
                </div>
              </div>
              <div className="workSchedule__select">
                <div className="wrapperSelect">
                  {!!users?.length && (
                    <AppCustomeSelect
                      placeholder={PLACEHOLDER_CONSTRACTOR}
                      onChange={handleOnChangeSelect}
                      groupUserOptions={
                        users?.length &&
                        users.map((user) => {
                          return {
                            label: `${user.name}`,
                            value: user?.id?.toString(),
                            avatar: linkImage(user?.avatar?.path),
                          };
                        })
                      }
                      options={
                        users?.length &&
                        users.map((user) => {
                          return {
                            label: `${user.name}`,
                            value: user?.id?.toString(),
                            avatar: linkImage(user?.avatar?.path),
                          };
                        })
                      }
                      isClearable
                      value={selectSubContractor}
                    />
                  )}
                </div>
              </div>
            </div>


            <div className="ws-middle">
              <div>
                <div>
                  <p>担当</p>
                </div>
                <div>
                  <h3>{itemDetail?.employee?.last_name} {itemDetail?.employee?.first_name}</h3>
                </div>
              </div>

              <div>
                <div>
                  <p>工事名（略名）</p>
                </div>
                <div>
                  <h3>{itemDetail?.name}</h3>
                </div>
              </div>

              <div>
                <div>
                  <p>工期</p>
                </div>
                <div>
                  <h3>{itemDetail?.start_date}
                    <br /> {itemDetail?.end_date}</h3>
                </div>
              </div>


            </div>

            {/* <Grid item xs={12} md={12}> */}

            {/* <div className="table_component">
                  <table>
                    <thead>
                      <tr>
                        <th>担当</th>
                        <th>工事名（略名）</th>
                        <th>工期</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="truncate ">
                            {" "}
                            {itemDetail?.employee?.last_name} {itemDetail?.employee?.first_name}
                          </div>{" "}
                        </td>
                        <td>
                          <div className="truncate "> {itemDetail?.name}</div>{" "}
                        </td>
                        <td>
                          {itemDetail?.start_date}
                          <br /> {itemDetail?.end_date}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
            {/* </Grid> */}

            {/* </Grid> */}
          </div>
          <div className="workSchedule__content">
            {/* <div className="visible__mobile">
                            {toggle === 0 && <TableViewWorkSchedule handleCloseDrawserToggle={handleCloseDrawserToggle} handleOpenDrawserToggle={handleOpenDrawserToggle} data={itemDetail} setReloadPage={setReloadPage} reloadPage={reloadPage} collapsedRowIds={collapsedRowIds} setCollapsedRowIds={setCollapsedRowIds} />}
                            {toggle === 1 && (
                                <ViewGraphCalendar
                                    data={itemDetail}
                                    handleOpenViewDetail={handleOpenViewDetail}
                                    date={date}
                                    fetchDetailApi={fetchDetailWork}
                                    selectSubContractor={selectSubContractor}
                                    tableStyle={{
                                        tableLayout: "auto",
                                        width: "auto",
                                    }}
                                    collapsedRowIds={collapsedRowIds}
                                />
                            )}
                        </div> */}
            <div className="visible__desktop" style={{ marginTop: "40px" }}>
              {/* <span className="btnCollaspe">
                                <AppIconButton icon={<ArrowLeftIcon />} onClick={() => setCollapse(!isCollapse)} />
                            </span> */}
              <div className="wrapperDesktopContent">
                <TableViewWorkSchedule hasCollapse={true} handleCloseDrawserToggle={handleCloseDrawserToggle} handleOpenDrawserToggle={handleOpenDrawserToggle} data={itemDetail} setReloadPage={setReloadPage} reloadPage={reloadPage} collapsedRowIds={collapsedRowIds} setCollapsedRowIds={setCollapsedRowIds} />
                <div className="wrapperDesktopContent__graphView" style={{ width: "auto" }}>
                  <ViewGraphCalendar
                    data={itemDetail}
                    handleOpenViewDetail={handleOpenViewDetail}
                    date={date}
                    fetchDetailApi={fetchDetailWork}
                    selectSubContractor={selectSubContractor}
                    tableStyle={{
                      tableLayout: "auto",
                      width: "auto",
                    }}
                    collapsedRowIds={collapsedRowIds}
                  />
                </div>
              </div>
            </div>
          </div>
          {contructions?.length && itemDetail?.employee?.id === userInfo?.id && (
            <div className="btn__add">
              <div className="btn__wrapper">
                <AppButton onClick={handleOpenNew} text="新しい工種を追加する" startIcon={<PlusIcon />} />
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledWorkScheduleScreen>
  );
};

export default function WorkScheduleDetailScreenWithContext() {
  return (
    <WorkScheduleProvider>
      <WorkScheduleDetailScreen />
    </WorkScheduleProvider>
  );
}
