import styled from "styled-components";
import small_inventory_bg from "@assets/Background/small_inventory_bg.png";
import inventory_bg from "@assets/Background/inventory_bg.png";

interface IStyleCategory {
    activeCollapse?: boolean;
}

export const StyledInventoryView = styled.div`
    // background-image: url(${small_inventory_bg});
    // background-repeat: no-repeat;
    background-color: ${(p) => p.theme.colors.white02};
    min-height: 100vh;
    padding-bottom: 4rem;
    padding: 1rem 1rem;
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        padding: 2rem 2rem;
        // background-image: url(${inventory_bg});
    }
    padding-bottom: 4rem;
    .visible-pc {
        display: none;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display: block;
        }
    }
    .visible-mobile{
        padding: "0 2rem";
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            display: none;
        }
    }
    .wrapperInventory {
        display: flex;
        justify-content: center;
        margin-top:7rem;
        padding-bottom:8rem;
    }
    .inventory {
        // padding: 1rem 1rem;
        // min-height: 80vh;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            // padding: 2rem 2rem;
            // width: 608px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            // padding: 2rem 2rem;
            // width: 1224px;
            // max-width: 1224px;
        }
        &__top {
            text-align: left;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-bottom: 2rem;
            }
            &-searchForm {
                height: 100%;
                display: flex;
                align-items: end;
                .MuiIconButton-root {
                    padding: 0rem 1rem;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                        padding: 0rem 2rem;
                    }
                }
            }
        }
        &__content {
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                border-top: 1px solid ${(p) => p.theme.colors.lightgray09};
            }
            .category {
                padding: 2rem;
                @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    min-height: 77vh;
                    // border-right: 1px solid #d4d4d4;
                    padding-left: 0;
                }
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding-left: 2rem;
                }
            }
        }
    }
`;
export const StyledInventoryCategory = styled.div<IStyleCategory>`
    .categoryItem {
        padding: 1.6rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        .collapse {
            &__header {
                display: flex;
                justify-content: space-between;
                text-align: left;
                cursor: pointer;
                &-title {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                    font-size: 1.6rem;
                    color: ${(p) => (p.activeCollapse ? p.theme.colors.orange : "#292D32")};
                    position: relative;
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    line-height: 1.2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-color: transparent;
                    }
                }
            }
            &__content {
                padding-top: 1.2rem;
            }
            &__subText {
                text-align: left;
                font-size: 1.6rem;
                padding: 0.5rem 1.6rem;
                display: block;
                display: -webkit-box;
                max-width: 100%;
                height: 40px;
                margin-bottom: 1rem;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                cursor: pointer;
                &:hover {
                    color: ${(p) => p.theme.colors.orange};
                }
            }
        }
    }
`;
export const StyledListItemInventory = styled.div`
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding-left: 2.5rem;
        // border-left: 1px solid rgba(0, 0, 0, 0.15);
    }
    .wrapperLoading {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .inventoryItems {
        &__title {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            font-size: 1.6rem;
            padding: 2.5rem 0;
            text-align: left;
        }
        .error {
            min-height: 77vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;
export const StyledInventoryItem = styled.div`
    .wrapper {
        background-color: ${(p) => p.theme.colors.white00};
        padding: 1rem;
        border-radius: 6px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        cursor: pointer;
        position: relative;
        z-index: 1;

        .wrapperImage {
            width: 9rem;
            height: 9rem;
            max-height: 9rem;
            border-radius: 6px;
            object-fit:cover;
            // border: 2px solid ${(p) => p.theme.colors.gray3};
            overflow: hidden;

            .image {
                width: 100%;
                height: 100%;
            }
        }
        .wrapperContent {
            padding-left: 1rem;
            text-align: left;
            width: 100%;
            display:flex;
            flex-direction:column;
            justify-content:space-between;
            .title {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 1.6rem;
                display: block;
                display: -webkit-box;
                max-width: 100%;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
            .subTitle {
                display: flex;
                // padding-top: 1rem;
            }
            .myUrl {
                // position:absolute;
                max-width: 255px;
                @media screen and (min-width: 900px) and (max-width: 1200px) {
                    max-width: 200px;
                }
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                z-index:100;
                &:hover{
                    color: ${(p) => p.theme.colors.blue06};
                    text-decoration:underline
                }
            }
            .amount {
                display: block;
                padding: 0 0 0.2rem 0.5rem;
                font-size: 1.4rem;
                color: ${(p) => p.theme.colors.black01};
                word-break: break-all;
            }
        }
    }
`;
