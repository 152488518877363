/* eslint-disable no-console */
// import { Box } from "@mui/system";
import { useHistory, useParams } from "react-router-dom";

import { timesheetApi } from "@api";
// import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import { appRouteEnums } from "@enums";
import { selectAuth, useAppSelector } from "@redux";
import { converUrlToGetId, formatDate, useFormWithSchema, useShowErrorFromApi } from "@utils";
import queryString from "query-string";
import { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { AppAlert, AppModal, CircularLoader, CustomFormProvider, TimeSheetUserInfo } from "../../..";
import { useTimeSheetContext } from "../../context";
import { StyledNewTimeSheetForm } from "../../styles";
import { timeSheetDayAndNightFormSchema, TimeSheetDayAndNightFormSchemaType } from "../../validation";
import { FirstFormSheetDayAndNight, FormSheetDayAndNight } from "../../widget/form-sheet-day-and-night";

export function TimeSheetDayAndNight() {
  const [nameGroup, setNameGroup] = useState<string>("");
  const [firstFormId, setFirstFormId] = useState(null);
  const [firstFormDate, setFirstFormDate] = useState(null);
  const [secondFormId, setSecondFormId] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const history = useHistory();
  const auth = useAppSelector(selectAuth);
  const { userInfo } = auth;
  const defaultDatePicker = queryString.parse(window.location.search)?.date;
  const params: any = useParams();
  const parsed = queryString.parse(location.search);

  const showErrorFromApi = useShowErrorFromApi();

  const { employees, loading } = useTimeSheetContext();

  const fetchListGroupsByUser = async (id: number) => {
    try {
      const result = await timesheetApi.listGroupsByUser(id);
      setNameGroup(result?.data?.data[0].name);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!userInfo?.id) return;
    fetchListGroupsByUser(userInfo?.id);
  }, [userInfo?.id]);

  const idSelectType = converUrlToGetId(window.location.href);

  const firstFormCreate = useFormWithSchema({
    schema: timeSheetDayAndNightFormSchema,

    options: {
      defaultValues: {
        alcohol_check: true,
        check_date: defaultDatePicker ? (defaultDatePicker as string) : formatDate({ date: new Date(), format: "YYYY-MM-DD" }),
        device_check: "TANITAアルコールチェッカーEA-100",
      },
      mode: "all",
    },
  });

  const firstFormUpdate = useFormWithSchema({
    schema: timeSheetDayAndNightFormSchema,
  });

  const secondFormCreate = useFormWithSchema({
    schema: timeSheetDayAndNightFormSchema,
    options: {
      defaultValues: {
        alcohol_check: true,
        check_date: defaultDatePicker ? (defaultDatePicker as string) : formatDate({ date: new Date(), format: "YYYY-MM-DD" }),
        device_check: "TANITAアルコールチェッカーEA-100",
      },
    },
  });

  const secondFormUpdate = useFormWithSchema({
    schema: timeSheetDayAndNightFormSchema,
  });

  const {
    trigger: triggerCreate,
    reset: firstFormResetCreate,
    getValues,
    formState: { isValid },
  } = firstFormCreate;

  const {
    trigger: triggerUpdate,
    reset: firstFormReset,
    getValues: getFirstFormUpdateValues,
    formState: { isValid: firstFormUpdateIsValid },
  } = firstFormUpdate;

  const {
    reset: secondFormResetCreate,
    formState: { isSubmitting: isSubmittingFormCreate },
  } = secondFormCreate;
  const {
    reset: secondFormReset,
    formState: { isSubmitting: isSubmittingFormUpdate },
  } = secondFormUpdate;

  const firstForm: any = parsed?.copy || params?.id ? firstFormUpdate : firstFormCreate;
  const secondForm: any = parsed?.copy || params?.id ? secondFormUpdate : secondFormCreate;
  const trigger = parsed?.copy || params?.id ? triggerUpdate : triggerCreate;

  useEffect(() => {
    if (employees.length === 0) return;
    const getLastestInfo = async () => {
      try {
        setEditLoading(true);
        const res = await timesheetApi.getLatestInfo();
        const {
          alcohol_default_check,
          inspector_default_check,
          driver_default_check,
          vehicle_default_check,
          device_default_check,
          start_time_check_alcohol,
          end_time_check_alcohol,
          start_capacity_alcohol,
          end_capacity_alcohol,
          driver_id,
          inspector_id,
          vehicle_id,
          device_check,
        } = res.data.data.timesheetItems[0];
        const dataLastestSecondForm = res?.data?.data?.timesheetItems[1];
        if (res.data.data.timesheetItems[0].item_type === 0) {
          firstFormResetCreate({
            device_check: device_default_check === 0 ? "TANITAアルコールチェッカーEA-100" : device_check,
            driver_id: driver_default_check === 0 ? "" : driver_id ? employees.find((employee) => employee.value === driver_id)?.value : "",
            inspector_id: inspector_default_check === 0 ? "" : inspector_id ? employees.find((employee) => employee.value === inspector_id)?.value : "",
            vehicle_id: vehicle_default_check === 0 ? "" : vehicle_id ? vehicle_id : "",
            list_constructions: [],

            start_time_check_alcohol: alcohol_default_check === 0 ? undefined : start_time_check_alcohol,
            end_time_check_alcohol: alcohol_default_check === 0 ? undefined : end_time_check_alcohol,
            start_capacity_alcohol: alcohol_default_check === 0 ? "" : start_capacity_alcohol,
            end_capacity_alcohol: alcohol_default_check === 0 ? "" : end_capacity_alcohol,
            alcohol_default_check: alcohol_default_check === 0 ? false : true,
            inspector_default_check: inspector_default_check === 0 ? false : true,
            driver_default_check: driver_default_check === 0 ? false : true,
            vehicle_default_check: vehicle_default_check === 0 ? false : true,
            device_default_check: device_default_check === 0 ? false : true,
          });
        } else if (dataLastestSecondForm) {
          firstFormResetCreate({
            device_check: dataLastestSecondForm.device_default_check === 0 ? "TANITAアルコールチェッカーEA-100" : dataLastestSecondForm.device_check,
            driver_id: dataLastestSecondForm.driver_default_check === 0 ? "" : dataLastestSecondForm.driver_id ? employees.find((employee) => employee.value === dataLastestSecondForm.driver_id)?.value : "",
            inspector_id: dataLastestSecondForm.inspector_default_check === 0 ? "" : dataLastestSecondForm.inspector_id ? employees.find((employee) => employee.value === dataLastestSecondForm.inspector_id)?.value : "",
            vehicle_id: dataLastestSecondForm.vehicle_default_check === 0 ? "" : dataLastestSecondForm.vehicle_id ? dataLastestSecondForm.vehicle_id : "",
            list_constructions: [],

            start_time_check_alcohol: dataLastestSecondForm.alcohol_default_check === 0 ? undefined : dataLastestSecondForm.start_time_check_alcohol,
            end_time_check_alcohol: dataLastestSecondForm.alcohol_default_check === 0 ? undefined : dataLastestSecondForm.end_time_check_alcohol,
            start_capacity_alcohol: dataLastestSecondForm.alcohol_default_check === 0 ? "" : dataLastestSecondForm.start_capacity_alcohol,
            end_capacity_alcohol: dataLastestSecondForm.alcohol_default_check === 0 ? "" : dataLastestSecondForm.end_capacity_alcohol,
            alcohol_default_check: dataLastestSecondForm.alcohol_default_check === 0 ? false : true,
            inspector_default_check: dataLastestSecondForm.inspector_default_check === 0 ? false : true,
            driver_default_check: dataLastestSecondForm.driver_default_check === 0 ? false : true,
            vehicle_default_check: dataLastestSecondForm.vehicle_default_check === 0 ? false : true,
            device_default_check: dataLastestSecondForm.device_default_check === 0 ? false : true,
          });
        }
        if (dataLastestSecondForm) {
          secondFormResetCreate({
            device_check: dataLastestSecondForm.device_default_check === 0 ? "TANITAアルコールチェッカーEA-100" : dataLastestSecondForm.device_check,
            driver_id: dataLastestSecondForm.driver_default_check === 0 ? "" : dataLastestSecondForm.driver_id ? employees.find((employee) => employee.value === dataLastestSecondForm.driver_id)?.value : "",
            inspector_id: dataLastestSecondForm.inspector_default_check === 0 ? "" : dataLastestSecondForm.inspector_id ? employees.find((employee) => employee.value === dataLastestSecondForm.inspector_id)?.value : "",
            vehicle_id: dataLastestSecondForm.vehicle_default_check === 0 ? "" : dataLastestSecondForm.vehicle_id ? dataLastestSecondForm.vehicle_id : "",
            list_constructions: [],

            start_time_check_alcohol: dataLastestSecondForm.alcohol_default_check === 0 ? undefined : dataLastestSecondForm.start_time_check_alcohol,
            end_time_check_alcohol: dataLastestSecondForm.alcohol_default_check === 0 ? undefined : dataLastestSecondForm.end_time_check_alcohol,
            start_capacity_alcohol: dataLastestSecondForm.alcohol_default_check === 0 ? "" : dataLastestSecondForm.start_capacity_alcohol,
            end_capacity_alcohol: dataLastestSecondForm.alcohol_default_check === 0 ? "" : dataLastestSecondForm.end_capacity_alcohol,
            alcohol_default_check: dataLastestSecondForm.alcohol_default_check === 0 ? false : true,
            inspector_default_check: dataLastestSecondForm.inspector_default_check === 0 ? false : true,
            driver_default_check: dataLastestSecondForm.driver_default_check === 0 ? false : true,
            vehicle_default_check: dataLastestSecondForm.vehicle_default_check === 0 ? false : true,
            device_default_check: dataLastestSecondForm.device_default_check === 0 ? false : true,
          });
        } else {
          secondFormResetCreate({
            device_check: device_default_check === 0 ? "TANITAアルコールチェッカーEA-100" : device_check,
            driver_id: driver_default_check === 0 ? "" : driver_id ? employees.find((employee) => employee.value === driver_id)?.value : "",
            inspector_id: inspector_default_check === 0 ? "" : inspector_id ? employees.find((employee) => employee.value === inspector_id)?.value : "",
            vehicle_id: vehicle_default_check === 0 ? "" : vehicle_id ? vehicle_id : "",
            list_constructions: [],

            start_time_check_alcohol: alcohol_default_check === 0 ? undefined : start_time_check_alcohol,
            end_time_check_alcohol: alcohol_default_check === 0 ? undefined : end_time_check_alcohol,
            start_capacity_alcohol: alcohol_default_check === 0 ? "" : start_capacity_alcohol,
            end_capacity_alcohol: alcohol_default_check === 0 ? "" : end_capacity_alcohol,
            alcohol_default_check: alcohol_default_check === 0 ? false : true,
            inspector_default_check: inspector_default_check === 0 ? false : true,
            driver_default_check: driver_default_check === 0 ? false : true,
            vehicle_default_check: vehicle_default_check === 0 ? false : true,
            device_default_check: device_default_check === 0 ? false : true,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setEditLoading(false);
      }
    };
    getLastestInfo();
  }, [employees, firstFormResetCreate, secondFormResetCreate]);

  const handleCreateTimeSheetDayAndNight = async (values) => {
    if (!isValid) return;
    const firstFormValues = getValues();

    const body = {
      // date: defaultDatePicker as string,
      date: values?.check_date,
      type: "2",
      data: [
        {
          item_type: "0",
          ...firstFormValues,
          alcohol_check: firstFormValues.alcohol_check ? "1" : "0",
          alcohol_default_check: firstFormValues.alcohol_default_check ? "1" : "0",
          inspector_default_check: firstFormValues.inspector_default_check ? "1" : "0",
          driver_default_check: firstFormValues.driver_default_check ? "1" : "0",
          vehicle_default_check: firstFormValues.vehicle_default_check ? "1" : "0",
          device_default_check: firstFormValues.device_default_check ? "1" : "0",
        },
        {
          item_type: "1",
          ...values,
          alcohol_check: values.alcohol_check ? "1" : "0",
          alcohol_default_check: values.alcohol_default_check ? "1" : "0",
          inspector_default_check: values.inspector_default_check ? "1" : "0",
          driver_default_check: values.driver_default_check ? "1" : "0",
          vehicle_default_check: values.vehicle_default_check ? "1" : "0",
          device_default_check: values.device_default_check ? "1" : "0",
        },
      ],
    };

    try {
      await timesheetApi.addMultipleConstructionSite(body);
      setIsSuccess(true), setSuccessMessage("正常に 作成した");
    } catch (error: any) {
      const err = error as AxiosError<any>;
      showErrorFromApi(err);
    }
  };

  const handleUpdateTimeSheetDayAndNight = async (values) => {
    if (!firstFormUpdateIsValid) return;
    const secondFormUpdateValues = getFirstFormUpdateValues();

    const body = parsed?.copy
      ? {
        type: idSelectType,
        // id: params?.id,
        //   date: defaultDatePicker as string,
        date: values?.check_date,
        data: [
          {
            item_type: "0",
            // id: firstFormId,
            ...secondFormUpdateValues,
            alcohol_check: secondFormUpdateValues.alcohol_check ? "1" : "0",
            alcohol_default_check: secondFormUpdateValues.alcohol_default_check ? "1" : "0",
            inspector_default_check: secondFormUpdateValues.inspector_default_check ? "1" : "0",
            driver_default_check: secondFormUpdateValues.driver_default_check ? "1" : "0",
            vehicle_default_check: secondFormUpdateValues.vehicle_default_check ? "1" : "0",
            device_default_check: secondFormUpdateValues.device_default_check ? "1" : "0",
          },
          {
            item_type: "1",
            // id: secondFormId,
            ...values,
            alcohol_check: values.alcohol_check ? "1" : "0",
            alcohol_default_check: values.alcohol_default_check ? "1" : "0",
            inspector_default_check: values.inspector_default_check ? "1" : "0",
            driver_default_check: values.driver_default_check ? "1" : "0",
            vehicle_default_check: values.vehicle_default_check ? "1" : "0",
            device_default_check: values.device_default_check ? "1" : "0",
          },
        ],
      }
      : {
        type: idSelectType,
        id: params?.id,
        date: firstFormDate,
        data: [
          {
            item_type: "0",
            id: firstFormId,
            ...secondFormUpdateValues,
            alcohol_check: secondFormUpdateValues.alcohol_check ? "1" : "0",
            alcohol_default_check: secondFormUpdateValues.alcohol_default_check ? "1" : "0",
            inspector_default_check: secondFormUpdateValues.inspector_default_check ? "1" : "0",
            driver_default_check: secondFormUpdateValues.driver_default_check ? "1" : "0",
            vehicle_default_check: secondFormUpdateValues.vehicle_default_check ? "1" : "0",
            device_default_check: secondFormUpdateValues.device_default_check ? "1" : "0",
          },
          {
            item_type: "1",
            id: secondFormId,
            ...values,
            alcohol_check: values.alcohol_check ? "1" : "0",
            alcohol_default_check: values.alcohol_default_check ? "1" : "0",
            inspector_default_check: values.inspector_default_check ? "1" : "0",
            driver_default_check: values.driver_default_check ? "1" : "0",
            vehicle_default_check: values.vehicle_default_check ? "1" : "0",
            device_default_check: values.device_default_check ? "1" : "0",
          },
        ],
      };
    try {
      // await timesheetApi.updateTimesheetDetail(body, params?.id);
      parsed?.copy ? await timesheetApi.addMultipleConstructionSite(body) : await timesheetApi.updateTimesheetDetail(body, params?.id);
      setIsSuccess(true), setSuccessMessage("正常に更新");
    } catch (error: any) {
      const err = error as AxiosError<any>;
      showErrorFromApi(err);
    }
  };

  const onSubmit = parsed?.copy || params?.id ? handleUpdateTimeSheetDayAndNight : handleCreateTimeSheetDayAndNight;

  useEffect(() => {
    if (employees.length === 0) return;

    const dayAndNightShiftDetails = async () => {
      setEditLoading(true);
      try {
        // const res = await timesheetApi.viewTimesheetDetail(params.id);
        const res = (parsed?.copy || params?.id) && (await timesheetApi.viewTimesheetDetail(parsed?.copy ? parsed?.copy : params.id));

        const {
          alcohol_check,
          check_date,
          device_check,
          driver,
          inspector,
          vehicle,
          TimesheetItemConstruction,
          id,
          date,
          start_time_check_alcohol,
          end_time_check_alcohol,
          start_capacity_alcohol,
          end_capacity_alcohol,
          alcohol_default_check,
          inspector_default_check,
          driver_default_check,
          vehicle_default_check,
          device_default_check,
        } = res.data.items[0];

        const dayAndNightValuesFirstForm: TimeSheetDayAndNightFormSchemaType = {
          alcohol_check: alcohol_check === 0 ? false : true,
          // check_date,
          check_date: parsed?.date ? parsed?.date : check_date,
          device_check,
          driver_id: driver ? employees.find((employee) => employee.value === driver?.id)?.value : "",
          inspector_id: inspector ? employees.find((employee) => employee.value === inspector?.id)?.value : "",
          vehicle_id: vehicle?.id ? vehicle?.id : "",
          list_constructions: TimesheetItemConstruction.map((construction) => ({
            break_time: construction.break_time,
            memo: construction.memo || "",
            construction_id: construction.construction_id,
            listConstruction: construction.construction,
            start_at: construction.start_at,
            stop_at: construction.stop_at,
            weather_id: construction.weather_id.toString(),
            contractors: construction.contractor_timesheet_item.map((contractorItem) => ({
              contractor_id: contractorItem.contractor_id,
              number_of_people: Number(contractorItem.number_of_people),
              work_type_1: contractorItem.work_types.filter((workType) => workType.type === 1).map((workType) => String(workType.id)),
              work_type_2: contractorItem.work_types.filter((workType) => workType.type === 2).map((workType) => String(workType.id)),
              work_type_3: contractorItem.work_types.filter((workType) => workType.type === 3).map((workType) => String(workType.id)),
            })),
            livelet_employee: construction?.employee_timesheet_item?.map((liveletEmployee) => ({
              employee_id: liveletEmployee.user_id,
              employee_name: liveletEmployee.user_id === 0 ? liveletEmployee.user_name : `${liveletEmployee.user.last_name} ${liveletEmployee.user.first_name}`,
              employee_memo: liveletEmployee.memo,
            })),
          })),
          start_time_check_alcohol: start_time_check_alcohol,
          end_time_check_alcohol: end_time_check_alcohol,
          start_capacity_alcohol: start_capacity_alcohol.toString(),
          end_capacity_alcohol: end_capacity_alcohol.toString(),
          alcohol_default_check: alcohol_default_check === 0 ? false : true,
          inspector_default_check: inspector_default_check === 0 ? false : true,
          driver_default_check: driver_default_check === 0 ? false : true,
          vehicle_default_check: vehicle_default_check === 0 ? false : true,
          device_default_check: device_default_check === 0 ? false : true,
        };

        const secondDefaultValuesForm = res.data.items[1];

        const dayAndNightValuesSecondForm: TimeSheetDayAndNightFormSchemaType = {
          alcohol_check: secondDefaultValuesForm.alcohol_check === 0 ? false : true,
          // check_date: secondDefaultValuesForm.check_date,
          check_date: parsed?.date ? parsed?.date : secondDefaultValuesForm.check_date,
          device_check: secondDefaultValuesForm.device_check,
          driver_id: secondDefaultValuesForm.driver ? employees.find((employee) => employee.value === secondDefaultValuesForm.driver.id)?.value : "",
          inspector_id: secondDefaultValuesForm.inspector ? employees.find((employee) => employee.value === secondDefaultValuesForm.inspector.id)?.value : "",
          vehicle_id: secondDefaultValuesForm.vehicle.id ? secondDefaultValuesForm.vehicle.id : "",
          list_constructions: secondDefaultValuesForm.TimesheetItemConstruction.map((construction) => ({
            break_time: construction.break_time,
            memo: construction.memo || "",
            construction_id: construction.construction_id,
            listConstruction: construction.construction,
            start_at: construction.start_at,
            stop_at: construction.stop_at,
            weather_id: construction.weather_id.toString(),
            contractors: construction.contractor_timesheet_item.map((contractorItem) => ({
              contractor_id: contractorItem.contractor_id,
              number_of_people: Number(contractorItem.number_of_people),
              work_type_1: contractorItem.work_types.filter((workType) => workType.type === 1).map((workType) => String(workType.id)),
              work_type_2: contractorItem.work_types.filter((workType) => workType.type === 2).map((workType) => String(workType.id)),
              work_type_3: contractorItem.work_types.filter((workType) => workType.type === 3).map((workType) => String(workType.id)),
            })),
            livelet_employee: construction?.employee_timesheet_item?.map((liveletEmployee) => ({
              employee_id: liveletEmployee.user_id,
              employee_name: liveletEmployee.user_id === 0 ? liveletEmployee.user_name : `${liveletEmployee.user.last_name} ${liveletEmployee.user.first_name}`,
              employee_memo: liveletEmployee.memo,
            })),
          })),
          start_time_check_alcohol: secondDefaultValuesForm.start_time_check_alcohol,
          end_time_check_alcohol: secondDefaultValuesForm.end_time_check_alcohol,
          start_capacity_alcohol: secondDefaultValuesForm.start_capacity_alcohol.toString(),
          end_capacity_alcohol: secondDefaultValuesForm.end_capacity_alcohol.toString(),
          alcohol_default_check: secondDefaultValuesForm.alcohol_default_check === 0 ? false : true,
          inspector_default_check: secondDefaultValuesForm.inspector_default_check === 0 ? false : true,
          driver_default_check: secondDefaultValuesForm.driver_default_check === 0 ? false : true,
          vehicle_default_check: secondDefaultValuesForm.vehicle_default_check === 0 ? false : true,
          device_default_check: secondDefaultValuesForm.device_default_check === 0 ? false : true,
        };
        setFirstFormId(id);
        setFirstFormDate(date);
        setSecondFormId(secondDefaultValuesForm.id);
        firstFormReset(dayAndNightValuesFirstForm);
        secondFormReset(dayAndNightValuesSecondForm);
      } catch (error) {
        console.log(error);
      } finally {
        setEditLoading(false);
      }
    };
    dayAndNightShiftDetails();
  }, [employees, firstFormReset, params.id, secondFormReset, parsed?.copy]);

  const handleCloseModal = () => {
    setIsSuccess(false);
    history.push(appRouteEnums.PATH_TIMESHEET);
  };

  return (
    <StyledNewTimeSheetForm>
      {/* <AppTopBar /> */}
      <CircularLoader loading={loading || editLoading || isSubmittingFormCreate || isSubmittingFormUpdate} />
      <AppModal open={isSuccess} handleClose={handleCloseModal}>
        <AppAlert icon={<img src={hot_yellow_frame} alt="icon" />} text={successMessage} handleClose={handleCloseModal} />
      </AppModal>
      <div className="detail">
        <div className="detail__top">
          {/* <p className="detail__top-icon" onClick={() => history.goBack()}>
            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
          </p> */}
          <p className="detail__top-title">日報</p>
        </div>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
        <div className="detail__content">
          <div className="detail__contentTop">
            <TimeSheetUserInfo
              last_name={userInfo?.last_name}
              first_name={userInfo?.first_name}
              type={"2"}
            />
            <p className="detail__name">{userInfo?.last_name} {userInfo?.first_name}</p>
          </div>


          <div className="boxWrapper">
            <div className="detail__group">
              <p className="label">グループ</p>

              <p className="main">{nameGroup && nameGroup}</p>
            </div>
            {/* <TimeSheetUserInfo
              last_name={userInfo?.last_name}
              first_name={userInfo?.first_name}
              type={"2"}
            date={new Date()}
            handleChange={handleChangeDate}
            created_at={data?.created_at}
            /> */}
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            > */}
            {/* <p className="label">グループ</p>

            <p className="main">{nameGroup && nameGroup}</p> */}
            {/* </Box> */}
          </div>
          <CustomFormProvider form={firstForm} onSubmit={() => undefined}>
            <FirstFormSheetDayAndNight />
          </CustomFormProvider>
          <CustomFormProvider form={secondForm} onSubmit={onSubmit}>
            <FormSheetDayAndNight triggerOtherForm={trigger} />
          </CustomFormProvider>
        </div>
        {/* </Box> */}
      </div>
    </StyledNewTimeSheetForm>
  );
}
