import { WorkTypeNameEnums } from "@enums";
import { Box } from "@mui/system";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { AppCustomeSelectCreateNew, AppInput } from "src/Components/Common";
import { TimeSheetDayAndNightFormSchemaType } from "../../validation";
import { WorkTypeResponse } from "./site-constructions-form.widget";
import { useTimeSheetContext } from "../../context";
import { StyledContractorItem } from "../form-sheet-night-shift";

export interface ContractorItemFormWidgetProps {
  nestIndex: number;
  workType: WorkTypeResponse;
}

export function ContractorItemFormWidget({ nestIndex, workType }: ContractorItemFormWidgetProps) {
  const form = useFormContext<TimeSheetDayAndNightFormSchemaType>();
  const { optionContractors } = useTimeSheetContext();

  const { control } = form;

  const { fields } = useFieldArray({
    control,
    name: `list_constructions.${nestIndex}.contractors`,
  });

  const watchContractors = useWatch({ name: `list_constructions.${nestIndex}.contractors` });

  return (
    <StyledContractorItem>
      {fields.map((field, k) => {
        const contractorsSelect = watchContractors[k].contractor_id;

        const constructorMatch = optionContractors.find(
          (contractor) => contractor.value === contractorsSelect
        );
        return (
          <div className="contractorDetail" key={field.id}>
            {/* <Box sx={{ mb: "20px" }} key={field.id}> */}
            {/* <Box sx={{ width: "50%", my: "15px" }}> */}
            <p className="contractorDetail__name">
              請負業者 : {constructorMatch.label}
            </p>
            <div className="contractorDetail__contentWithBorder">
              <div className="contractorDetail__contentChangeSize">
                <p className="name">人数</p>
                <Controller
                  name={`list_constructions.${nestIndex}.contractors.${k}.number_of_people`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <AppInput
                        isNumber
                        handleChange={(value) => onChange(Number(value))}
                        value={String(value)}
                      />
                      {error && (
                        <p
                          style={{
                            color: "#FF597B",
                            fontSize: "13px",
                            margin: "8px",
                          }}
                        >
                          {error?.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            {/* </Box> */}
            {/* <Box sx={{ my: "15px" }}> */}
            <div className="contractorDetail__contentWithBorder">
              <div className="contractorDetail__contentChangeSize">
                <p className="name">{WorkTypeNameEnums.TYPE_1}</p>
                {workType.type_1 && (
                  <Controller
                    name={`list_constructions.${nestIndex}.contractors.${k}.work_type_1`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      const valueSelect = workType && workType?.type_1.filter(type => value.some(id => Number(type.id) === Number(id))).map(type => ({
                        label: type.name,
                        value: String(type.id)
                      }));
                      return (
                        <>
                          <AppCustomeSelectCreateNew
                            placeholder="工種"
                            isMulti
                            onChange={(values) => {
                              // const valuesId = values.map(
                              //     (val) => val.value
                              // );
                              onChange(values);
                            }}
                            value={valueSelect}
                            options={workType.type_1.map((type) => ({
                              label: type.name,
                              value: type.id.toString(),
                            }))}
                          />
                          {error && (
                            <p
                              style={{
                                color: "#FF597B",
                                fontSize: "13px",
                                margin: "8px",
                              }}
                            >
                              {error?.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {/* </Box> */}
            {/* <Box sx={{ my: "15px" }}>
                            <p className="name">{WorkTypeNameEnums.TYPE_2}</p>
                            {workType.type_2 && (
                                <Controller
                                    name={`list_constructions.${nestIndex}.contractors.${k}.work_type_2`}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => {
                                        console.log(value);
                                        // const valueSelect = workType && workType?.type_2.filter(type => value.some(id => Number(type.id) === Number(id))).map(type => ({
                                        //     label: type.name,
                                        //     value: String(type.id)
                                        // }));
                                        return (
                                            <>
                                                <AppCustomeSelectCreateNew
                                                    isMulti
                                                    placeholder="種別"
                                                    onChange={(values) => {
                                                        // const valuesId = values.map(
                                                        //     (val) => val.value
                                                        // );
                                                        onChange(values.toString());
                                                    }}
                                                    value
                                                    options={workType.type_2.map((type) => ({
                                                        label: type.name,
                                                        value: type.id.toString(),
                                                    }))}
                                                />
                                                {error && (
                                                    <p
                                                        style={{
                                                            color: "#FF597B",
                                                            fontSize: "13px",
                                                            margin: "8px",
                                                        }}
                                                    >
                                                        {error?.message}
                                                    </p>
                                                )}
                                            </>
                                        );
                                    }}
                                />
                            )}
                        </Box> */}
            {/* <Box sx={{ my: "15px" }}> */}
            <div className="contractorDetail__contentWithBorder">
              <div className="contractorDetail__contentChangeSize">
                <p className="name">{WorkTypeNameEnums.TYPE_2}</p>
                {workType.type_2 && (
                  <Controller
                    name={`list_constructions.${nestIndex}.contractors.${k}.work_type_2`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      const valueSelect = workType && workType?.type_2.filter(type => value.some(id => Number(type.id) === Number(id))).map(type => ({
                        label: type.name,
                        value: String(type.id)
                      }));
                      return (
                        <>
                          <AppCustomeSelectCreateNew
                            isMulti
                            placeholder="種別"
                            onChange={(values) => {
                              // const valuesId = values.map(
                              //     (val) => val.value
                              // );
                              onChange(values);
                            }}
                            value={valueSelect}
                            options={workType.type_2.map((type) => ({
                              label: type.name,
                              value: type.id.toString(),
                            }))}
                          />
                          {error && (
                            <p
                              style={{
                                color: "#FF597B",
                                fontSize: "13px",
                                margin: "8px",
                              }}
                            >
                              {error?.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {/* </Box> */}
            {/* <Box sx={{ my: "15px" }}> */}
            <div className="contractorDetail__content">
              <div className="contractorDetail__contentChangeSize">
                <p className="name">{WorkTypeNameEnums.TYPE_3}</p>
                {workType.type_3 && (
                  <Controller
                    name={`list_constructions.${nestIndex}.contractors.${k}.work_type_3`}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      const valueSelect = workType && workType?.type_3.filter(type => value.some(id => Number(type.id) === Number(id))).map(type => ({
                        label: type.name,
                        value: String(type.id)
                      }));
                      return (
                        <>
                          <AppCustomeSelectCreateNew
                            isMulti
                            placeholder="タイプ"
                            onChange={(values) => {
                              // const valuesId = values.map(
                              //     (val) => val.value
                              // );
                              onChange(values);
                            }}
                            value={valueSelect}
                            options={workType.type_3.map((type) => ({
                              label: type.name,
                              value: type.id.toString(),
                            }))}
                          />
                          {error && (
                            <p
                              style={{
                                color: "#FF597B",
                                fontSize: "13px",
                                margin: "8px",
                              }}
                            >
                              {error?.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                )}
                {/* </Box> */}
                {/* </Box> */}
              </div>
            </div>
          </div>
        );
      })}
    </StyledContractorItem>
  );
}
