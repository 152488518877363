import React from "react";
interface IProps {
  data?: any;
}
const OTDetailVacation = (props: IProps) => {
  const { data } = props;
  return (
    <div>
      {data?.overtimeData?.reason?.name && (
        <div className="user__contents">
          <p className="user__title">理由</p>
          <p className="user__title">{data?.overtimeData?.reason?.name}</p>
        </div>
      )}
      {data?.overtimeData?.note && (
        <div className="user__contents">
          <p className="user__title">理由</p>
          <p className="user__title">{data?.overtimeData?.note}</p>
        </div>
      )}
      {data?.overtimeData?.phone_number && (
        <div className="user__contents">
          <p className="">連絡先</p>
          <p className="user__title">{data?.overtimeData?.phone_number}</p>
        </div>
      )}
      {data?.overtimeData?.start_at && data?.overtimeData?.stop_at && (
        <div>
          <div className="user__contents">
            <p className="title">開始時間</p>
            <p className="content">{data?.overtimeData?.start_at}</p>
          </div>
          <div className="user__contents">
            <p className="title">終了時間</p>
            <p className="content">{data?.overtimeData?.stop_at}</p>
          </div>
        </div>
      )}
      {data?.overtimeData?.memo && (
        <div className="user__contents-last">
          <p className="">備考</p>
          <p className="user__title">{data?.overtimeData?.memo}</p>
        </div>
      )}
    </div>
  );
};

export default OTDetailVacation;
