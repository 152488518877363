import React from "react";

import { StyledAlertConfirm } from "./styles";
// import bg_popup_confirm from "@assets/Images/bg_popup_confirm.png";
import { TITLE_RETURN_SCHEDULE } from "@configs";
// import alert_line from "@assets/Images/alert_line.svg";
import layer from "@assets/Icons/layer.svg";
import { LogoutButton, PassChangeButton } from "src/Components/Profile";

interface IProps {
  title?: string;
  name?: string;
  code?: string;
  from?: string;
  to?: string;
  inventory?: any;
  handleAccept?: (params: any) => void;
  handleReject?: () => void;
  icon?: string;
  text?: string;
}

export const AppAlertConfirm = (props: IProps) => {
  const {
    name,
    from,
    to,
    icon,
    title = TITLE_RETURN_SCHEDULE,
    handleAccept,
    handleReject,
    inventory,
    text = "確認してください",
  } = props;

  return (
    <StyledAlertConfirm>
      <div className="alertConfirm__wrapper">
        <div className="alert__confirm">

          <div className="alert__info">

            <div className="info__content">
              <img src={icon} className="info__icon" alt="confirm image" />

              {inventory?.quantity ? (
                <div>
                  <div className="info__text-date">
                    <p className="info__text-main">{name}</p>
                    <div className="info__text-sub-quantity">
                      <span className="layerIcon">
                        <img src={layer} alt="icon" />
                      </span>
                      {inventory?.selectType === "return" && (
                        <span className="takenAmount" style={{ color: "#81e1ad", fontSize: "1.3rem" }}>+{inventory?.quantity}</span>
                      )}

                      {inventory?.selectType === "borrow" && (
                        <span className="addedAmount" style={{ color: "#ff2e00", fontSize: "1.3rem" }}>-{inventory?.quantity}</span>
                      )}
                    </div>
                  </div>

                  <div className="alert__confirm-title">{title}</div>

                </div>
                // <div className="info__text">
                //   <p className="info__text-product">{name}</p>
                //   <p className="info__text-amount">
                //     <span className="layerIcon">
                //       <img src={layer} alt="icon" />
                //     </span>
                //     {inventory?.selectType === "return" && (
                //       <span className="takenAmount">+{inventory?.quantity}</span>
                //     )}

                //     {inventory?.selectType === "borrow" && (
                //       <span className="addedAmount">-{inventory?.quantity}</span>
                //     )}
                //   </p>
                // </div>
              ) : (
                <div>
                  <div className="info__text-date">
                    <p className="info__text-main">{title}</p>
                    <p className="info__text-sub">
                      {from} ～ {to}
                    </p>
                  </div>

                  <div className="alert__confirm-title">{text}</div>

                </div>
              )}


            </div>

            {/* <div className="alert__bg">
            <img src={bg_popup_confirm} alt="bg" />
          </div> */}

            {inventory?.quantity ? (
              <div className="alert__confirm-title-medium">{title}</div>
            ) : (
              <div className="alert__confirm-title-medium">{text}</div>
            )}

          </div>

          {/* <div className="alert__comfirm-line"> */}
          {/* <img src={alert_line} alt="lines" /> */}
          {/* </div> */}
          <div className="alert__confirm-btn">
            <div className="button">
              <LogoutButton onClick={handleAccept}>
                はい
              </LogoutButton>
            </div>
            <PassChangeButton onClick={handleReject}>
              いいえ
            </PassChangeButton>
            {/* <span className="button" onClick={handleReject}>
              いいえ
            </span> */}

            {/* <span className="button" onClick={handleAccept}>
              はい
            </span> */}
          </div>
        </div>
      </div>
    </StyledAlertConfirm>
  );
};
