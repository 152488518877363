import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { fetchVehiclesCategories, useAppDispatch, useAppSelector } from "@redux";
import { setVehiclesCategory, updateSnackBar } from "@redux";
import { fetchVehiclesData, selectVehicle } from "@redux";

import { AppTopBar, TitleMenuComponent, AppInput, AppIconButton } from "..";
import { StyledVehicleView, VehiclesListItemComponent, VehiclesCatetoryComponent } from ".";
import { appRouteEnums, menuTitleEnums } from "@enums";
import { SearchIcon } from "@assets";
import { categoriesApi /*, vehicleApi*/ } from "@api";
import { useWindowDimensions } from "@utils";
import { API_CATEGORY_TYPE_VEHICLE } from "@configs";
import { useHistory, useLocation } from "react-router-dom";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";

export const VehiclesView = () => {
  // redux state
  // component state
  const [inputValue, setInputValue] = React.useState<string>("");
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [category, setCategory] = React.useState<string>("");
  //const [categories, setCategories] = React.useState<Array<any>>();
  //const [loading, setLoading] = React.useState<boolean>(false);
  //const [listProducts, setListProducts] = React.useState<any>();
  const messagesEndRef = React.useRef<any>(null);
  const [categoryViewMobile, setCategoryViewMobile] = React.useState<boolean>(true);
  // hooks
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const categoryId = query.get("categoryId");
  //const subCategoryId = query.get("subCategoryId");
  const dispatch = useAppDispatch();
  const width = useWindowDimensions().width;
  const history = useHistory();

  const vehicleState = useAppSelector(selectVehicle);
  const dataResult = useAppSelector((state) => state.vehicle.dataResult);
  //  const vehicleCategoryId = dataResult?.categoryId;
  const vehicleSubCategoryId = dataResult?.subCategoryId;

  useEffect(() => {
    if (!vehicleState) {
      dispatch(fetchVehiclesData() as any);
      dispatch(fetchVehiclesCategories() as any);
    }
  }, [dispatch, vehicleState]);

  const fullVehicleArray = vehicleState?.categories?.data || [];
  const vehicleArray = vehicleSubCategoryId ? fullVehicleArray.filter(vehicle => vehicle.categoryId === vehicleSubCategoryId) : fullVehicleArray;
  const vehicleCategoryArray = vehicleState?.vehicleCategories || [];

  // events
  //what: events
  const handleOnChange = (value: string) => {
    setInputValue(value);
  };
  //What: scroll to bottom to view data results when device is mobile
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //  //what: call api get list products
  //  async function fetchListProducts(params: any) {
  //    setLoading(true);
  //    try {
  //      /*const response = */await vehicleApi.getVehiclesListProducts({
  //        category: subCategoryId?.toString() || "",
  //        name: params.name || "",
  //      });
  //      setLoading(false);
  //      //setListProducts(response.data.data);
  //    } catch (err: any) {
  //      //setListProducts(undefined);
  //      setLoading(false);
  //    }
  //  };

  //what: call api in search form
  const handleOnSearchSubmit = () => {
    setCategoryViewMobile(false);
    //    fetchListProducts({
    //      name: inputValue,
    //    });
    setSelectedIndex(-1);
    if (width < 768) {
      scrollToBottom();
    }
    dispatch(setVehiclesCategory({ category: "" }));
  };

  //what: call api after re-render
  //how: when sub category id is updated, this page is re-render
  //React.useEffect(() => {
  //  fetchListProducts({
  //    category,
  //  });
  //}, [category]);
  // call api get categories
  React.useEffect(() => {
    const fetchCategoriesApi = async () => {
      try {
        /*const response = */await categoriesApi.getCategories({
        type: API_CATEGORY_TYPE_VEHICLE,
      });
        //setCategories(response.data.data);
      } catch (err: any) {
        dispatch(
          updateSnackBar({
            messageType: "error",
            message: err.response.data.message,
          })
        );
      }
    };
    fetchCategoriesApi();
  }, []);
  // React.useEffect(() => {
  //     setSelectedIndex(category === dataResult?.category ? 1 : -1);
  // }, []);
  // React.useEffect(() => {
  //     return () => {
  //         dispatch(setVehiclesCategory({ category: "" }));
  //     };
  // }, []);
  //what: select a subCategory ID
  const handleSelectCategory = (values: any, categoryId: number) => {
    dispatch(
      setVehiclesCategory({
        category: values.title,
        categoryId: categoryId,
        subCategory: values.subTitle,
        subCategoryId: values.id,
      })
    );
    setInputValue("");
    setCategoryViewMobile(false);
    setCategory(values.id);
    history.push(`/vehicles?categoryId=${categoryId}&subCategoryId=${values.id}`);
  };
  //WHAT: active select category
  const handleClick = (index: any) => {
    setSelectedIndex(index);
  };

  const filteredVehicles = category ?
    vehicleArray.filter(vehicle => vehicle.categoryId === category) :
    inputValue ? vehicleArray.filter(vehicle => vehicle.name.toLowerCase().includes(inputValue.toLowerCase())) :
      vehicleArray;

  const inputValueMatchCount = inputValue ? filteredVehicles.length : undefined;

  return (
    <StyledVehicleView>
      {/* <AppTopBar /> */}
      <div className="vehicles">
        <div className="vehicles__top">
          {/* <div
            className="newOT__top-icon"
            onClick={() => history.push(appRouteEnums.PATH_HOME)}
          >
            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
          </div> */}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={8}>
              <div>
                <TitleMenuComponent text={menuTitleEnums.TITLE_VEHICLES} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="vehicles__top-searchForm" style={{ flexDirection: "column" }}>
                <AppInput
                  value={inputValue}
                  iconStart={<SearchIcon />}
                  handleChange={handleOnChange}
                  placeholder="商品名"
                  disabled={false}
                  name="searchValue"
                  onClick={handleOnSearchSubmit}
                />
                {inputValueMatchCount !== undefined ? <span style={{ fontSize: "0.8em", color: "#666", padding: "0.5rem 2rem" }}>一致数: {inputValueMatchCount}</span> : null}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="vehicles__content visible-pc">
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <div className="category">
                {vehicleCategoryArray &&
                  vehicleCategoryArray?.map((category, index) => (
                    <div key={category.id}>
                      <VehiclesCatetoryComponent
                        category={category}
                        checked={
                          String(categoryId) === String(category.id) ||
                          index === selectedIndex
                        }
                        onClick={() => {
                          handleClick(index);
                        }}
                        handleSelectSubCategory={handleSelectCategory}
                      />
                    </div>
                  ))}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <div className="listItem" ref={messagesEndRef}>
                <VehiclesListItemComponent
                  loading={false}
                  products={filteredVehicles}
                  categoryId={category}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="vehicles__content visible-mobile">
          {categoryViewMobile ? (
            <div className="category">
              {vehicleCategoryArray &&
                vehicleCategoryArray?.map((category, index) => (
                  <div key={category.id}>
                    <div key={category.id}>
                      <VehiclesCatetoryComponent
                        category={category}
                        checked={
                          String(categoryId) === String(category.id) ||
                          index === selectedIndex
                        }
                        onClick={() => {
                          handleClick(index);
                        }}
                        handleSelectSubCategory={handleSelectCategory}
                      />
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div>
              <div
                style={{ width: "100%", textAlign: "left", padding: "2rem 0" }}
                onClick={() => setCategoryViewMobile(true)}
              >
                <img src={mobile_arrow_left} alt="icon" />
              </div>
              <VehiclesListItemComponent
                loading={false}
                products={filteredVehicles}
                categoryId={category}
              />
            </div>
          )}
        </div>
      </div>
    </StyledVehicleView>
  );
};
