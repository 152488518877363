import React, { useEffect, useState } from "react";
// import { Grid } from "@mui/material";

import { StyledHomeContent } from "./styles";
import { AppAlert, AppModal } from "..";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
// import { ListItemsComponent } from ".";
import { homeApi } from "@api";
import { useAppSelector, selectAuth, selectNoti } from "@redux";
import { logItemType } from "src/types";
import ListComponent from "./ListComponent";
import {
  linkImage,
} from "@utils";
import default_avatar from "@assets/Images/default_avatar.jpg";


export const HomeContentComponent = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [logItems, setLogItems] = React.useState<logItemType[]>([]);
  const auth = useAppSelector(selectAuth);
  const noti = useAppSelector(selectNoti);
  const { userInfo } = auth;

//  const [values, setValues] = useState({
//    vh: window.innerHeight / 100,
//    dvh: (window.visualViewport?.height || 0) / 100 || window.innerHeight / 100,
//    innerHeight: window.innerHeight,
//    visualViewportHeight: window.visualViewport?.height || 0,
//    offsetTop: window.visualViewport?.offsetTop || 0,
//    clientHeight: document.documentElement.clientHeight,
//    safeTop: getComputedStyle(document.documentElement).getPropertyValue("--safe-top"),
//    safeAreaInsetTop: getComputedStyle(document.documentElement).getPropertyValue("env(safe-area-inset-top)"),
//    heightDiff: window.innerHeight - (window.visualViewport?.height || 0),
//    screenHeight: screen.height,
//    availHeight: screen.availHeight,
//    absoluteTop: -2,
//    fixedTop: -2,
//});
//
//  useEffect(() => {
//    const updateValues = () => {
//      const absoluteTopPixel = document.getElementById("absolute_top_pixel");
//      const fixedTopPixel = document.getElementById("fixed_top_pixel");
//
//      setValues({
//        vh: window.innerHeight / 100,
//        dvh: (window.visualViewport?.height || 0) / 100 || window.innerHeight / 100,
//        innerHeight: window.innerHeight,
//        visualViewportHeight: window.visualViewport?.height || 0,
//        offsetTop: window.visualViewport?.offsetTop || 0,
//        clientHeight: document.documentElement.clientHeight,
//        safeTop: getComputedStyle(document.documentElement).getPropertyValue("--safe-top"),
//        safeAreaInsetTop: getComputedStyle(document.documentElement).getPropertyValue("env(safe-area-inset-top)"),
//        heightDiff: window.innerHeight - (window.visualViewport?.height || 0),
//        screenHeight: screen.height,
//        availHeight: screen.availHeight,
//        absoluteTop: absoluteTopPixel?.getBoundingClientRect().top || -1,
//        fixedTop: fixedTopPixel?.getBoundingClientRect().top || -1,
//      });
//    };
//
//    window.addEventListener("resize", updateValues);
//    window.addEventListener("scroll", updateValues);
//    return () => {
//      window.removeEventListener("resize", updateValues);
//      window.removeEventListener("scroll", updateValues);
//    };
//  }, []);
  // avatar path for debugging
  console.log("Avatar path:", userInfo?.avatar?.path);
  console.log("Resolved URL:", linkImage(userInfo?.avatar?.path));

  const userToken = auth.tokenInfoAuth;
  const handleCloseModal = () => {
    setShowModal(false);
  };
  // fetch api
  const fetchApiHistoryLogs = async () => {
    try {
      const res = await homeApi.getHistoryLog();
      setLogItems(res.data.data);
    } catch (err) { }
  };
  React.useEffect(() => {
    if (userToken) {
      fetchApiHistoryLogs();
    }
    return () => {
      // Do something cleanup
    };
  }, []);
  return (
    <StyledHomeContent>
      <AppModal open={showModal} handleClose={handleCloseModal}>
        <AppAlert
          icon={<img src={hot_yellow_frame} alt="icon" />}
          text="パスワード変更ができました!"
          handleClose={handleCloseModal}
        />
      </AppModal>
      <div className="home__wrapper">
        {/* <AppTopBar screen="home" /> */}
        <div className="home__profile">

          <img
            className="home__profile-image"
            src={
              userInfo?.avatar?.path
                ? linkImage(userInfo?.avatar?.path)
                : default_avatar
            }
            alt="avatar"
          />

          <div style={{ textAlign: "left" }}>
            <h1 className="home__profile-name">{userInfo?.last_name} {userInfo?.first_name}</h1>
            {noti.numberNoti > 0 && <p>通知が <span style={{ color: "red", fontWeight: "bold" }}>{noti.numberNoti} 件</span>あります</p>
            }
          </div>

        </div>
        {/* <Border /> */}

        <div className="home__content-lists">
          <div className="home__title">
            <p>入力履歴</p>
          </div>
          <ListComponent logItems={logItems} />
        </div>
        {/*<span style={{ display: "flex", flexDirection: "column", fontSize: "14px", background: "white", padding: "5px" }}>
          <strong>Viewport Debug:</strong><br />
          vh: {values.vh}px<br />
          dvh: {values.dvh}px<br />
          innerHeight: {values.innerHeight}px<br />
          visualViewportHeight: {values.visualViewportHeight}px<br />
          offsetTop: {values.offsetTop}px<br />
          clientHeight: {values.clientHeight}px<br />
          --safe-top: {values.safeTop}<br />
          safe-area-inset-top: {values.safeAreaInsetTop}<br />
          heightDiff (UI Elements): {values.heightDiff}px<br />
          screenHeight: {values.screenHeight}px<br />
          availHeight: {values.availHeight}px<br />
          absoluteTop (absolute div): {values.absoluteTop}px<br />
          fixedTop (fixed div): {values.fixedTop}px<br />
        </span>*/}
      </div>
    </StyledHomeContent>
  );
};
