import dayjs from "dayjs";
import styled from "styled-components";
import { AppInput, AppSelect, AppTextArea, AppTimePicker } from "../Common";
import { formatDate } from "@utils";

interface IProps {
  values: any;
  handleChange: any;
  touched: any;
  errors: any;
  listReasons: any;
  handleBlur: any;
  setFieldValue: any;
}

const OTNewVacation = (props: IProps) => {
  const { values, handleChange, touched, errors, listReasons, handleBlur, setFieldValue } = props;

  return (
    <div className="newOT__time">
      {/* <Grid container spacing={4}>
        <Grid item xs={12} md={7} container> */}
      <div className="user__title">
        <WrapTime>
          <div className="start-time">
            <label className="label">開始時間</label>
            <AppTimePicker
              value={dayjs(values.start_time, "HH:mm:ss").toDate()}
              handleChange={(newValue) => {
                const formatValue = formatDate({
                  date: newValue,
                  format: "HH:mm:ss",
                });
                setFieldValue("start_time", formatValue);
              }}
              errorMessage={
                touched.start_time && errors.start_time
                  ? errors.start_time
                  : undefined
              }
            />
          </div>
          <div className="end-time">
            <label className="label">終了時間</label>
            <AppTimePicker
              value={dayjs(values.stop_time, "HH:mm:ss").toDate()}
              handleChange={(newValue) => {
                const formatValue = formatDate({
                  date: newValue,
                  format: "HH:mm:ss",
                });
                setFieldValue("stop_time", formatValue);
              }}
              errorMessage={
                touched.stop_time && errors.stop_time
                  ? errors.stop_time
                  : undefined
              }
            />
          </div>
        </WrapTime>
      </div>
      {/* </Grid>

        <Grid item xs={12} md={12}> */}
      <div className="user__title">
        <label className="label">理由</label>
        <AppSelect
          options={
            listReasons?.length &&
            listReasons?.map((item) => {
              return {
                label: item.name,
                value: item.id?.toString(),
              };
            })
          }
          name="reasonType"
          value={values.reasonType}
          handleChange={handleChange("reasonType")}
          placeholder="理由を選択してください"
          error={touched.reasonType ? errors.reasonType : undefined}
        />
      </div>
      {values.reasonType === "0" && (
        <div className="input">
          <AppInput
            value={values.reasonContent}
            name="reasonContent"
            error={errors.reasonContent}
            touched={touched.reasonContent}
            handleChange={handleChange("reasonContent")}
          />
        </div>
      )}
      {/* </Grid>
        <Grid item xs={12} md={12}> */}
      <div className="user__title">
        <div className="input">
          <label className="label">連絡先</label>
          <AppTextArea
            name="phoneNumber"
            handleChange={handleChange("phoneNumber")}
            value={values.phoneNumber}
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
            handleBlur={handleBlur("phoneNumber")}
          // isNumber
          />
        </div>
      </div>
      {/* </Grid>
        <Grid item xs={12} md={12}> */}
      <div className="input" style={{ marginTop: "1.5rem" }}>
        <AppInput
          label="備考"
          value={values.memo}
          name="memo"
          error={touched.memo ? errors.memo : undefined}
          touched={touched.memo}
          handleChange={handleChange("memo")}
        />
      </div>
      {/* </Grid>
      </Grid> */}
    </div>
  );
};

export default OTNewVacation;

const WrapTime = styled.div`
    display: flex;
    .end-time {
        margin-left: 20px;
    }
`;
