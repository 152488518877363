import { FieldArray, Formik } from "formik";
import React, { useState } from "react";
import { Box, Button, Stack } from "@mui/material";

import { PlusIcon } from "@assets";
import { AppButton, AppCustomeSelect, StyledNewFormWorkSchedule } from "..";
import { workScheduleApi } from "@api";
import { workScheduleClassificationForm } from "@utils";
import { IStoreWorkTypeRequest } from "@interfaces";
import DeleteTypeConfirmationModal from "./DeleteTypeConfirmationModal";

interface IProps {
    handleCloseNew?: (resetForm: any) => void;
    handleOnCreateSchedule?: (values: IStoreWorkTypeRequest, resetForm: any) => void;
    handleDeleteWorkType?: (values: any) => void;
    data?: any;
    listContructions?: any;
}

export const UpdateOverallTypeForm = (props: IProps) => {
    const { handleCloseNew, handleOnCreateSchedule, handleDeleteWorkType, data } = props;
    const formattedDate = new Date().toISOString().slice(0, 10);

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    //component states
    const [detailData, setDetailData] = React.useState<any>(null);
    const [optionWorkTypes, setOptionWorkTypes] = React.useState<any>();
    const initialValuesPackage = {
        contractions: "",
        constructors: [],
        listConstractors: [],
        workType: [
            {
                type: 2,
                work_type_id: [],
                work_type_date: [
                    {
                        start_date: formattedDate,
                        end_date: formattedDate,
                    },
                ],
            },
        ],
    };

    const listContractors = detailData?.work_schedules?.find((schedule) => schedule?.id === data?.work_schedule_id)?.work_schedule_work_type?.find((workType) => workType?.id === data?.id)?.contractors;

    React.useEffect(() => {
        fetchWorkTypeByType();
        return () => {
            // clean up
        };
    }, []);

    React.useEffect(() => {
        fetchGetWorkDetailApi(data?.work_schedule_id);
        return () => {
            // clean up
        };
    }, [data?.work_schedule_id]);

    const fetchWorkTypeByType = async () => {
        try {
            const result = await workScheduleApi.getWorkTypeByType();
            setOptionWorkTypes(result?.data?.data);
        } catch (err) {}
    };

    const fetchGetWorkDetailApi = async (workScheduleId) => {
        try {
            const response = await workScheduleApi.getWorkDetail({
                workScheduleId,
            });
            setDetailData(response?.data?.data);
        } catch (err: any) {
            setDetailData(null);
        }
    };

    return (
        <>
            <StyledNewFormWorkSchedule style={{ overflowX: "unset" }}>
                <Formik
                    initialValues={initialValuesPackage}
                    enableReinitialize
                    onSubmit={(values, { resetForm }) => {
                        handleOnCreateSchedule?.(
                            {
                                work_type: [values.workType[0]?.work_type_id[0]],
                                work_schedule_id: data?.work_schedule_id,
                                type: 2,
                                parent_id: data?.id,
                            },
                            resetForm
                        );
                    }}
                    validationSchema={workScheduleClassificationForm}
                >
                    {({ handleSubmit, touched, errors, setFieldValue, resetForm }) => {
                        return (
                            <div className="workSchedule__newForm">
                                <div className="workScheduleNewForm__select">
                                    <p style={{ marginBottom: "8px" }} className="label">
                                        {!data?.work_type?.name ? `工事名` : `工事名: ${data?.work_type?.name}`}
                                    </p>

                                    {Array.isArray(listContractors) && listContractors?.length > 0 ? (
                                        <Stack mt={1} gap={0.25}>
                                            <p className="label">作業会社</p>

                                            <Stack direction="row" gap={0.5}>
                                                {listContractors.map((contractor) => {
                                                    return <Box style={{ width: "fit-content", backgroundColor: "#e6e6e6", borderRadius: 6, margin: 2, padding: 6, fontSize: 14 }}>{contractor?.name}</Box>;
                                                })}
                                            </Stack>
                                        </Stack>
                                    ) : null}

                                    {/* Type 2 */}
                                    <FieldArray
                                        name="workType"
                                        render={() => (
                                            <>
                                                <div>
                                                    <label className="label">種別を追加</label>
                                                    <AppCustomeSelect
                                                        options={
                                                            !!optionWorkTypes?.type_2?.length &&
                                                            optionWorkTypes?.type_2?.map((item) => {
                                                                return {
                                                                    label: item.name,
                                                                    value: item.id.toString(),
                                                                };
                                                            })
                                                        }
                                                        className={""}
                                                        groupOptions={null}
                                                        name={`workType.work_type_id.${0}`}
                                                        placeholder="種別を追加"
                                                        onChange={(value) => {
                                                            setFieldValue(`workType.${0}`, {
                                                                type: 2,
                                                                work_type_id: [value.value],
                                                                work_type_date: [
                                                                    {
                                                                        start_date: formattedDate,
                                                                        end_date: formattedDate,
                                                                        work_type_id: value.value,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    />

                                                    {errors?.workType && touched?.workType ? <div className="error"> {(errors.workType[0] as any)?.work_type_id}</div> : null}
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>

                                <div className="workScheduleNewForm__btn">
                                    <AppButton text="追加" onClick={handleSubmit} startIcon={<PlusIcon />} />
                                </div>
                                <div className="workScheduleNewForm__btn delete">
                                    <Button
                                        onClick={() => {
                                            setIsOpenDeleteModal(true);
                                        }}
                                    >
                                        工種削除
                                    </Button>
                                </div>
                                <div className="workScheduleNewForm__btn cancel">
                                    <Button
                                        onClick={() => {
                                            handleCloseNew?.(resetForm);
                                        }}
                                    >
                                        キャンセル
                                    </Button>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </StyledNewFormWorkSchedule>

            {isOpenDeleteModal ? (
                <DeleteTypeConfirmationModal
                    open
                    handleClose={() => {
                        setIsOpenDeleteModal(false);
                    }}
                    message="種別、細別が設定されている場合はそれらもすべて削除されます。よろしいですか？"
                    action={
                        <Stack direction="row" gap={1} mt={2}>
                            <Button
                                sx={{
                                    fontSize: 16,
                                    width: "100%",
                                    padding: "10px !important",
                                    backgroundColor: "#fff !important",
                                    borderRadius: "8px !important",
                                    border: "1px solid #fa5c5c !important",
                                    color: "#fa5c5c !important",
                                    "&:hover": {
                                        backgroundColor: "#ffe5e5 !important",
                                    },
                                }}
                                variant="outlined"
                                onClick={() => {
                                    handleDeleteWorkType?.(data?.id);
                                }}
                            >
                                削除
                            </Button>
                            <Button
                                sx={{
                                    fontSize: 16,
                                    width: "100%",
                                    padding: "10px !important",
                                    backgroundColor: "#dedede !important",
                                    borderRadius: "8px !important",
                                    border: "none",
                                    color: "#656565 !important",
                                }}
                                onClick={() => {
                                    setIsOpenDeleteModal(false);
                                }}
                            >
                                キャンセル
                            </Button>
                        </Stack>
                    }
                />
            ) : null}
        </>
    );
};
